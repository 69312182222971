import React from "react";
import i18next from "i18next";
import CookiesPolicyEn from "./CookiesPolicyEn";
import CookiesPolicyIt from "./CookiesPolicyIt";

const CookiesPolicy = props => {
    switch(i18next.language) {
        case 'en':
            return <CookiesPolicyEn/>;
        case 'it':
            return <CookiesPolicyIt/>;
        default:
            return <CookiesPolicyIt/>;
    }
}

export default CookiesPolicy;