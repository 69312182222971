import {
    ENABLE_DISCOUNT,
    SET_PAYMENT_CREDIT_CARD,
    SET_RESTAURANT_ID,
    SUBMIT_ORDER,
    SUBMIT_ERROR,
    SET_CARD_ERROR,
    SET_DISCOUNT
} from '../actions/ActionTypes';

/**
 * Initial state.
 * @type {{discountEnabled: boolean}}
 */
const initialState = {
    discountEnabled: false,
    isPaymentCreditCard: true,
    restaurantId: null,
    orderId: null,
    clientSecret: null,
    submitError: false,
    orderDetails: null,
    cardError: false,
    cardErrorMessage: "",
    discountValue: 0,
    discountCode: '',
};

/**
 * Restaurant closed modal reducer.
 * @param state state.
 * @param action action.
 * @returns {{discountEnabled: *}|{discountEnabled: boolean}|{restaurantId: long}}
 */
export default (state = initialState, action) => {
    if (action.type === ENABLE_DISCOUNT) {
        return {
            ...state,
            discountEnabled: action.payload,
        };
    } else if (action.type === SET_PAYMENT_CREDIT_CARD) {
        return {
            ...state,
            isPaymentCreditCard: action.payload,
        };
    } else if (action.type === SET_RESTAURANT_ID) {
        return {
            ...state,
            restaurantId: action.payload,
        };
    } else if (action.type === SUBMIT_ORDER) {
        return {
            ...state,
            orderId: action.payload.id,
            clientSecret: action.payload.clientSecret,
            submitError: false,
            orderDetails: action.payload
        };
    } else if (action.type === SUBMIT_ERROR) {
        return {
            ...state,
            submitError: true
        };
    } else if (action.type === SET_CARD_ERROR) {
        return {
            ...state,
            cardError: true,
            cardErrorMessage: action.payload
        };
    } else if (action.type === SET_DISCOUNT) {
        return {
            ...state,
            discountValue: action.payload.value,
            discountCode: action.payload.code,
        };
    }
    else {
        return state;
    }
}
