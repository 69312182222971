import React from 'react';
import '../../Style.css'
import i18next from "i18next";
import PrivacyIt from "./PrivacyIt";
import PrivacyEn from "./PrivacyEn";

function Privacy(props) {
    switch(i18next.language) {
        case 'en':
            return <PrivacyEn/>;
        case 'it':
            return <PrivacyIt/>;
        default:
            return <PrivacyIt/>;
    }
}

export default Privacy;