import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import VvFoodApi from '../api/VvFoodApi'
import Alert from 'react-bootstrap/Alert'
import {Translation} from "react-i18next";
import { Link } from 'react-router-dom';


/**
 * Reset password component
 */
export default (props) => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    /**
    * Validate email method
    * @param {*} email email to validate
    */
    const validateEmail = email => {
        return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
    };

    /**
     * Reset password function
     */
    const resetPassword = async () => {
        if (validateEmail(email)) {
            var response = await VvFoodApi.post(
                `/users/reset_password/${email}`
            ).catch(err => {
                return "error";
            });

            if (response !== "error") {
                props.history.push("/reset_password_success")
            }
            else {
                setError("We can't find a user with that e-mail address.")
            }
        }

        else {
            setError("The email must be a valid email address.")
        }
    };

    return (
        <div>
            <Container fluid style={{paddingTop: 20}}>
                <Row>
                    <Col sm={12}>
                        <Row className="justify-content-center" style={{ textAlign: 'center', paddingTop: 30, paddingBottom: 15 }}>
                            <Translation ns="password.reset">
                                {t => (
                                    <h4 style={{ textAlign: 'center', color: '#516E33', fontFamily: 'MontserratMedium' }}>
                                        {t('title')}
                                    </h4>
                                )}
                            </Translation>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <div style={{backgroundColor: '#6A833E', paddingTop: 20, paddingBottom: 30}}>
                <Container>
                    <Row className="justify-content-center" style={{ color: '#B2D37B' }}>
                        <Col md={10} lg={10} sm={12} style={{paddingTop: 20, paddingBottom: 20}}>
                            <div id="innerDiv" style={{ backgroundColor: '#fff', paddingLeft: 10, paddingRight: 10}}>
                                <Form>
                                    <Form.Row style={{ paddingTop: 5 }}>
                                        <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail" style={{marginBottom: 5}}>
                                            <Form.Label style={{ color: 'white', marginBottom: 0}}>
                                                <Translation ns="password.reset">
                                                    {t => (
                                                        <h6 className="boldFirstLetter" style={{ fontFamily: 'MontserratLight', marginBottom: 0, color: '#565751' }}>
                                                            {t('email.label')}
                                                        </h6>
                                                    )}
                                                </Translation>
                                            </Form.Label>
                                            <Form.Control type="text" style={{fontFamily: 'MontserratLight', padding: 0, borderRadius: 0, borderWidth: 0, boxShadow: 'none'}} onChange={(e) => setEmail(e.target.value)} />
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    {error.length ?
                        <Row className="justify-content-center" style={{ textAlign: 'center', marginTop: '30px' }}>
                            <Col md={10} lg={10} sm={12}>
                                <Alert variant='danger' style={{borderRadius: 0}}>
                                    <ul>
                                        <li style={{ textAlign: 'left', fontFamily: 'MontserratLight' }}>{error}</li>
                                    </ul>
                                </Alert>
                            </Col>
                        </Row>
                        : null
                    }
                    <Row className="justify-content-center">
                        <Col md={10} lg={10} sm={12} style={{paddingTop: 20, paddingBottom: 20}}>
                            <Translation ns="password.reset">
                                {t => (
                                    <Button variant="primary" type="submit"
                                            style={{
                                                borderRadius: 0,
                                                borderWidth: 1,
                                                borderColor: '#fff',
                                                borderStyle:'solid',
                                                backgroundColor: '#91C04B',
                                                marginTop: 20,
                                                paddingTop: 15,
                                                paddingBottom: 15,
                                                fontSize: 22,
                                                fontFamily: 'MontserratMedium',
                                                width: '100%' }} onClick={resetPassword}>
                                        {t('send.button.label')}
                                    </Button>
                                )}
                            </Translation>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={8} sm={12}>
                        <Translation ns="terms.and.conditions">
                            {t => (
                                <p style={{fontFamily: 'MontserratRegular', fontSize: 16, paddingTop: 35, paddingBottom: 50}}>
                                    {t('by.logging.you.accept') + ' '}
                                    <Link to={"/terms-and-conditions"} style={{color: '#6A8321', textDecoration: 'underline'}}>
                                        {t('terms.and.conditions')}
                                    </Link>
                                    {' ' + t('read.our') + ' '}
                                    <Link to={"/privacy"} style={{color: '#6A8321', textDecoration: 'underline'}}>
                                        {t('privacy.policy') + ' '}
                                    </Link>
                                    {t('and') + ' '}
                                    <Link to={"/cookies-policy"} style={{color: '#6A8321', textDecoration: 'underline'}}>
                                        {t('cookie') + '.'}
                                    </Link>
                                </p>
                            )}
                        </Translation>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
