import React from 'react';
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from 'redux';
import thunk from "redux-thunk";
import reducers from "./reducers";
import LoginComponent from './component/LoginComponent';
import Registration from './component/Registration';
import Home from './component/Home';
import { Route, Router, BrowserRouter } from 'react-router-dom';
import ResetPassword from './component/ResetPassword';
import ResetPasswordSuccess from './component/ResetPasswordSuccess';
import NewPassword from './component/NewPassword';
import NewPasswordSuccess from './component/NewPasswordSuccess';
import Restaurant from './component/Restaurant';
import Header from './component/Header';
import Footer from './component/Footer';
import RestaurantList from './component/RestaurantList';
import UserProfile from './component/UserProfile';
import HowDoesItWork from './component/HowDoesItWork/HowDoesItWork'
import Contact from './component/Contact/Contact'
import CustomerService from './component/CustomerService/CustomerService'
import OrderSubmit from './component/OrderSubmit';
import PaymentArea from './component/PaymentArea';
import Privacy from './component/Privacy/Privacy';
import { persistStore } from 'redux-persist';
import PrivateRoutes from './component/PrivateRoute';
import AuthenticatedRoute from './component/AuthenticatedRoute';
import i18next from "i18next";
import TermsAndConditions from "./component/TermsAndConditions";
import CookiesPolicy from "./component/CookiesPolicy/CookiesPolicy";
import BecomePartners from "./component/BecomePartners/BecomePartners";
import ScrollToTop from "./component/ScrollToTop";

/**
* Creates store from given reducers.
* @type {Store<CombinedState<unknown> & S & {}, AnyAction> & Store<S & {}, A> & {dispatch: Dispatch<A>}}
*/
const store = createStore(reducers, applyMiddleware(thunk));

/**
 * Store persistence in session storage.
 */
const persistor = persistStore(store);

function App() {
    const websiteLang = localStorage.getItem('websiteLang');
    if (websiteLang) {
        i18next.changeLanguage(websiteLang);
    }
    return (
        <Provider store={store} >
            <BrowserRouter>
                <ScrollToTop />
                <Header/>
                <AuthenticatedRoute exact path="/login" exact component={props => <LoginComponent {...props}/>} />
                <AuthenticatedRoute exact path="/register" exact component={props => <Registration {...props}/>} />
                <Route path="/" exact component={Home}/>
                <Route path="/password_email" exact component={ResetPassword} />
                <Route path="/reset_password_success" exact component={ResetPasswordSuccess} />
                <Route path="/reset_password/:token" exact component={NewPassword} />
                <Route path="/new_password_success" exact component={NewPasswordSuccess} />
                <Route path="/restaurant/:id" exact component={Restaurant} />
                <Route path="/restaurant" exact component={RestaurantList} />
                <PrivateRoutes exact path="/profile" exact component={props => <UserProfile {...props}/>} />
                <Route path="/how-does-it-work" exact component={HowDoesItWork} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/customer-service" exact component={CustomerService} />
                <Route path="/checkout" exact component={PaymentArea} />
                <Route path="/order-submit" exact component={OrderSubmit} />
                <Route path="/privacy" exact component={Privacy} />
                <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
                <Route path="/cookies-policy" exact component={CookiesPolicy} />
                <Route path="/become-partners" exact component={BecomePartners} />
                <Footer/>
            </BrowserRouter>
        </Provider>
    );
}
export { persistor, store };
export default App;

