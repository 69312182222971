import {
    FETCH_RESTAURANTS,
    FETCH_RESTAURANTS_BY_ADDRESS,
    SET_ADDRESS,
    SET_NAME,
    SHOW_NO_RESTAURANTS_NEARBY,
    SHOW_RESTAURANTS_FOUND,
    FETCH_NEAREST_RESTAURANT
} from '../actions/ActionTypes';

/**
 * Initial state.
 * @type {{restaurants: array, restaurantsByAddress: array, address: string, name: string, nearestRestaurant: object, noRestaurantsNearbyIsDisplayed: boolean, restaurantFoundIsOpen: boolean}}
 */
const initialState = {
    restaurants: [],
    restaurantsByAddress: [],
    address: "",
    name: null,
    nearestRestaurant: null,
    noRestaurantsNearbyIsDisplayed: false,
    restaurantsFoundIsDisplayed: false,
    freeDelivery: 0,
    homeDelivery: 0,
    openedNow: 0,
    localPickup: 0
};

/**
 * Restaurants reducer.
 * @param state state of the app.
 * @param action action.
 */
export default (state = initialState, action) => {

    switch (action.type) {
        case FETCH_RESTAURANTS:
            return {
                ...state,
                restaurants: action.payload,
                restaurantsByAddress: state.restaurantsByAddress,
                address: state.address,
                name: state.name,
                nearestRestaurant: state.nearestRestaurant,
                noRestaurantsNearbyIsDisplayed: state.noRestaurantsNearbyIsDisplayed,
                restaurantsFoundIsDisplayed: state.restaurantsFoundIsDisplayed
            }
        case FETCH_RESTAURANTS_BY_ADDRESS:
            return {
                restaurants: state.restaurants,
                restaurantsByAddress: action.payload.restaurantList,
                address: state.address,
                name: state.name,
                nearestRestaurant: state.nearestRestaurant,
                noRestaurantsNearbyIsDisplayed: state.noRestaurantsNearbyIsDisplayed,
                restaurantsFoundIsDisplayed: state.restaurantsFoundIsDisplayed,
                freeDelivery: action.payload.freeDeliveryPreview,
                homeDelivery:  action.payload.homeDeliveryPreview,
                openedNow: action.payload.openedNowPreview,
                localPickup: action.payload.localPickupPreview
            }
        case SET_ADDRESS:
            return {
                ...state,
                restaurants: state.restaurants,
                restaurantsByAddress: state.restaurantsByAddress,
                address: action.payload,
                name: state.name,
                nearestRestaurant: state.nearestRestaurant,
                noRestaurantsNearbyIsDisplayed: state.noRestaurantsNearbyIsDisplayed,
                restaurantsFoundIsDisplayed: state.restaurantsFoundIsDisplayed
            }
        case SET_NAME:
            return {
                ...state,
                restaurants: state.restaurants,
                restaurantsByAddress: state.restaurantsByAddress,
                address: state.address,
                name: action.payload,
                nearestRestaurant: state.nearestRestaurant,
                noRestaurantsNearbyIsDisplayed: state.noRestaurantsNearbyIsDisplayed,
                restaurantsFoundIsDisplayed: state.restaurantsFoundIsDisplayed
            }
        case SHOW_NO_RESTAURANTS_NEARBY:
            return {
                ...state,
                restaurants: state.restaurants,
                restaurantsByAddress: state.restaurantsByAddress,
                address: state.address,
                name: state.name,
                nearestRestaurant: state.nearestRestaurant,
                noRestaurantsNearbyIsDisplayed: action.payload,
                restaurantsFoundIsDisplayed: state.restaurantsFoundIsDisplayed
            }
        case SHOW_RESTAURANTS_FOUND:
            return {
                ...state,
                restaurants: state.restaurants,
                restaurantsByAddress: state.restaurantsByAddress,
                address: state.address,
                name: state.name,
                nearestRestaurant: state.nearestRestaurant,
                noRestaurantsNearbyIsDisplayed: state.noRestaurantsNearbyIsDisplayed,
                restaurantsFoundIsDisplayed: action.payload
            }
        case FETCH_NEAREST_RESTAURANT:
            return {
                ...state,
                restaurants: state.restaurants,
                restaurantsByAddress: state.restaurantsByAddress,
                address: state.address,
                name: state.name,
                nearestRestaurant: action.payload,
                noRestaurantsNearbyIsDisplayed: state.noRestaurantsNearbyIsDisplayed,
                restaurantsFoundIsDisplayed: state.restaurantsFoundIsDisplayed
            }
        default:
            return state;
    }
}