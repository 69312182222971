import React from "react";
import {Container} from "react-bootstrap";

const ContactEn = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold', textTransform: 'uppercase' }}>OUR CONTACTS</p>
                    <div className="privacySection" >
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>CUSTOMER SERVICE ENQUIRIES</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            If something is wrong with your order please contact the restaurant, Vvfood is not responsible for orders delay or wrong items.
                        </p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            If you have some problems in using the platform please email&nbsp;
                            <a className="link" href="mailto:info@vvfood.it">info@vvfood.it</a>.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>MEDIA ENQUIRIES</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            For quotes, interviews or other media requests please email&nbsp;
                            <a className="link" href="mailto:marketing@vvfood.it">marketing@vvfood.it</a>
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>JOIN VVFOOD!</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            If you have an healthy restaurant join us! Leave your contact details in the section&nbsp;
                            <a className="link" href="/become-partners">Partner with Vvfood</a>
                        </p>
                    </div>
                    <div style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>MARKETING</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            For any suggestions on promotional activities please email&nbsp;
                            <a className="link" href="mailto:marketing@vvfood.it">marketing@vvfood.it</a>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ContactEn;