import Axios from 'axios'
import { configData } from '../configData/configData'
import AuthService from './../services/AuthService'

/**
 * Axios placeholder for user registration
 */
const API = Axios.create({
    baseURL:configData.API_BASE,
    headers: {
        'authorization': AuthService.getCurrentUserId() != null ? 'Bearer ' + localStorage.getItem('userToken').replaceAll('"', '') : '',
        'Accept-Language': localStorage.getItem('websiteLang') ? localStorage.getItem('websiteLang') : 'it',
    }
});

API.interceptors.response.use( (response) => {
    return response;
}, (error) => {
    if (error.response.status === 401) {
        AuthService.logout();
        window.location.href = '/'
    } else {
        return Promise.reject(error)
    }
});

export default API;