import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { fetchUserData } from '../actions'
import AuthService from '../services/AuthService'
import { SubmissionError } from 'redux-form'
import VvFoodApi from '../api/VvFoodApi'
import {Translation} from "react-i18next";
import Utility from "../utility";

/**
 * Function for input rendering in the form.
 * @param {*} param0
 */
const renderField = ({isNotValid, input, label, type }) => (
    <div>
        <Form.Control {...input} placeholder={label} type={type} className={isNotValid ? 'user-profile-input error' : 'user-profile-input'} />
    </div>
);

/**
 * User profile component.
 */
class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userDataValidationFailed: [],
            passwordValidationFailed: [],
        };
    }

    async componentDidMount() {
        await this.props.fetchUserData(AuthService.getCurrentUserId());
    }

    /**
     * Validates user data for save.
     * @param values the user data values.
     * @returns {[]}
     */
    validateUserData = (values) => {
        var failedFieldKeys = [];

        if (!values.name || values.name.trim() === '') {
            failedFieldKeys.push('name');
        }
        if (!values.lastName || values.lastName.trim() === '') {
            failedFieldKeys.push('lastName');
        }
        if (!values.email || !Utility.validateEmail(values.email)) {
            failedFieldKeys.push('email');
        }
        if (!values.city || values.city.trim() === '') {
            failedFieldKeys.push('city');
        }
        if (!values.street || values.street.trim() === '') {
            failedFieldKeys.push('street');
        }
        if (!values.houseNumber || values.houseNumber.trim() === '') {
            failedFieldKeys.push('houseNumber');
        }
        if (!values.postalCode || values.postalCode.trim() === '' || !Utility.validatePostalCode(values.postalCode)) {
            failedFieldKeys.push('postalCode');
        }
        if (!values.phoneNumber || values.phoneNumber.trim() === '' || !Utility.validatePhoneNumber(values.phoneNumber)) {
            failedFieldKeys.push('phoneNumber');
        }
        return failedFieldKeys;
    };

    /**
     * Function for manage user profile submit.
     * @param {*} values
     */
    updateUserData = async (values) => {
        var validFailed = this.validateUserData(values);
        if (validFailed.length > 0) {
            this.setState({userDataValidationFailed: validFailed});
            return;
        }

        let address = values.city + "," + values.street + "," + values.houseNumber + "," + values.postalCode;

        let data = {
            name: values.name,
            lastName: values.lastName,
            email: values.email,
            address: address,
            phoneNumber: values.phoneNumber
        };

        await VvFoodApi.put('/users/' + AuthService.getCurrentUserId(), data)
            .then(() => {
                window.location.reload();
            })
            .catch(function (error) {
                if (error.response) {
                    throw new SubmissionError({
                        _error: error.response.data
                    })
                }
            });
    };

    /**
     * Validates password change.
     * @param values the password input values.
     */
    validatePasswordChange = (values) =>{
        var failedFieldKeys = [];

        if (!values.currentPassword || values.currentPassword.trim() === '') {
            failedFieldKeys.push('currentPassword');
        }
        if (!values.newPassword || values.newPassword.trim() === '') {
            failedFieldKeys.push('newPassword');
        }
        if (!values.confirmPassword || values.confirmPassword.trim() === '' || values.newPassword !== values.confirmPassword) {
            failedFieldKeys.push('confirmPassword');
        }

        return failedFieldKeys;
    };

    /**
     * Function for manage user password submit.
     * @param {*} values
     */
    updatePassword = async (values) => {
        var validFailed = this.validatePasswordChange(values);
        if (validFailed.length > 0) {
            this.setState({passwordValidationFailed: validFailed});
            return;
        }

        let data = {
            currentPassword: values.currentPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
        };

        await VvFoodApi.put('/users/' + AuthService.getCurrentUserId(), data)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                validFailed = this.state.passwordValidationFailed;
                validFailed.push('currentPassword');
                this.setState({passwordValidationFailed: validFailed});
            });
    };

    render() {
        const { error, handleSubmit, pristine, reset, submitting } = this.props;
        return (
            <Translation ns="profile">
                {t => (
                    <Container fluid style={{paddingBottom: 86}}>
                        <Row>
                            <Col sm={12}>
                                <Row className="justify-content-center" style={{ textAlign: 'center' }}>
                                    <p style={{
                                        textAlign: 'center',
                                        color: '#565751',
                                        fontFamily: "MontserratMedium",
                                        fontSize: 36,
                                        marginBottom: 0,
                                        paddingTop: 80,
                                        paddingBottom: 50
                                    }}>
                                        {t('profileTitle')}
                                    </p>
                                </Row>
                            </Col>
                        </Row>
                        <form onSubmit={handleSubmit(this.updateUserData)}>
                            <Row className="justify-content-center">
                                <Col md={12} lg={8} sm={12} style={{ paddingTop: 20, paddingBottom: 20, height: '100%' }}>
                                    <div id="innerDiv" style={{ height: '100%', width: '100%' }}>
                                        <Form.Row className={'user-profile-input-container'}>
                                            <Form.Group as={Col} sm={12} md={12} controlId="formGridName">
                                                <Form.Label>
                                                    <p className="user-profile-input-label">{t('name')}</p>
                                                </Form.Label>
                                                <Field
                                                    name="name"
                                                    type="text"
                                                    component={renderField}
                                                    isNotValid={this.state.userDataValidationFailed.includes('name')}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className={'user-profile-input-container'}>
                                            <Form.Group as={Col} sm={12} md={12} controlId="formGridSurname">
                                                <Form.Label>
                                                    <p className="user-profile-input-label">{t('surname')}</p>
                                                </Form.Label>
                                                <Field
                                                    name="lastName"
                                                    type="text"
                                                    component={renderField}
                                                    isNotValid={this.state.userDataValidationFailed.includes('lastName')}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className={'user-profile-input-container'}>
                                            <Form.Group as={Col} sm={12} md={12} controlId="formGridPhone">
                                                <Form.Label>
                                                    <p className="user-profile-input-label">{t('phone')}</p>
                                                </Form.Label>
                                                <Field
                                                    name="phoneNumber"
                                                    type="text"
                                                    component={renderField}
                                                    isNotValid={this.state.userDataValidationFailed.includes('phoneNumber')}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className={'user-profile-input-container'}>
                                            <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail">
                                                <Form.Label>
                                                    <p className="user-profile-input-label">{t('email')}</p>
                                                </Form.Label>
                                                <Field
                                                    name="email"
                                                    type="text"
                                                    component={renderField}
                                                    isNotValid={this.state.userDataValidationFailed.includes('email')}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className={'user-profile-input-container'}>
                                            <Form.Group as={Col} sm={12} md={12} controlId="formGridCity">
                                                <Form.Label>
                                                    <p className="user-profile-input-label">{t('city')}</p>
                                                </Form.Label>
                                                <Field
                                                    name="city"
                                                    type="text"
                                                    component={renderField}
                                                    isNotValid={this.state.userDataValidationFailed.includes('city')}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className={'user-profile-input-container'}>
                                            <Form.Group as={Col} sm={12} md={12} controlId="formGridStreet">
                                                <Form.Label>
                                                    <p className="user-profile-input-label">{t('street')}</p>
                                                </Form.Label>
                                                <Field
                                                    name="street"
                                                    type="text"
                                                    component={renderField}
                                                    isNotValid={this.state.userDataValidationFailed.includes('street')}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col md={12} sm={12}>
                                                <Form.Row>
                                                    <Form.Group as={Col} sm={12} md={6} controlId="formGridHouseNum" className={'pr-md-4'}>
                                                        <Form.Label>
                                                            <p className="user-profile-input-label">{t('houseNumber')}</p>
                                                        </Form.Label>
                                                        <Field
                                                            name="houseNumber"
                                                            type="text"
                                                            component={renderField}
                                                            isNotValid={this.state.userDataValidationFailed.includes('houseNumber')}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group as={Col} sm={12} md={6} controlId="formGridPostalCode" className={'pl-md-4 pt-5 pt-md-0'}>
                                                        <Form.Label>
                                                            <p className="user-profile-input-label">{t('postalCode')}</p>
                                                        </Form.Label>
                                                        <Field
                                                            name="postalCode"
                                                            type="text"
                                                            component={renderField}
                                                            isNotValid={this.state.userDataValidationFailed.includes('postalCode')}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                            </Col>
                                        </Form.Row>
                                    </div>
                                </Col>
                                {this.state.userDataValidationFailed.length ? (
                                    <Col md={12} lg={8} sm={12}>
                                        <div style={{backgroundColor: '#F8D7DA', padding: 15}}>
                                            {this.state.userDataValidationFailed.map((errMsgKey, i) => (
                                                <div key={i}>
                                                    <Translation ns="profile">
                                                        {(t) => (
                                                            <p style={{
                                                                fontFamily: 'MontserratMedium',
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                color: 'red',
                                                                marginBottom: 0
                                                            }}>
                                                                {t(errMsgKey + '.invalid')}
                                                            </p>
                                                        )}
                                                    </Translation>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                ) : null}
                                <Col md={12} lg={8} sm={12}>
                                    <Button type="submit" disabled={submitting} className={'user-profile-submit-btn'}>
                                        {t('update')}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                        <Row>
                            <Col sm={12}>
                                <Row className="user-profile-reset-pass-bg justify-content-center" style={{ textAlign: 'center' }}>
                                    <p style={{
                                        textAlign: 'center',
                                        color: '#565751',
                                        fontFamily: "MontserratMedium",
                                        fontSize: 36,
                                        marginBottom: 0,
                                        paddingTop: 60,
                                        paddingBottom: 40
                                    }}>
                                        {t('changePasswordTitle')}
                                    </p>
                                </Row>
                            </Col>
                        </Row>
                        <form onSubmit={handleSubmit(this.updatePassword)}>
                            <Row className="user-profile-reset-pass-bg justify-content-center">
                                <Col md={12} lg={8} sm={12} style={{ paddingTop: 20, paddingBottom: 20, height: '100%' }}>
                                    <div id="innerDiv" style={{ height: '100%', width: '100%' }}>
                                        <Form.Row className={'user-profile-input-container'}>
                                            <Form.Group as={Col} sm={12} md={12} controlId="formGridCurrentPass">
                                                <Form.Label>
                                                    <p className="user-profile-input-label">{t('currentPassword')}</p>
                                                </Form.Label>
                                                <Field
                                                    name="currentPassword"
                                                    type="password"
                                                    component={renderField}
                                                    isNotValid={this.state.passwordValidationFailed.includes('currentPassword')}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row className={'user-profile-input-container'}>
                                            <Form.Group as={Col} sm={12} md={12} controlId="formGridNewPass">
                                                <Form.Label>
                                                    <p className="user-profile-input-label">{t('newPassword')}</p>
                                                </Form.Label>
                                                <Field
                                                    name="newPassword"
                                                    type="password"
                                                    component={renderField}
                                                    isNotValid={this.state.passwordValidationFailed.includes('newPassword')}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={12} md={12} controlId="formGridConfirmCurrentPass">
                                                <Form.Label>
                                                    <p className="user-profile-input-label">{t('confirmNewPassword')}</p>
                                                </Form.Label>
                                                <Field
                                                    name="confirmPassword"
                                                    type="password"
                                                    component={renderField}
                                                    isNotValid={this.state.passwordValidationFailed.includes('confirmPassword')}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                    </div>
                                </Col>
                                {this.state.passwordValidationFailed.length ? (
                                    <Col md={12} lg={8} sm={12}>
                                        <div style={{backgroundColor: '#F8D7DA', padding: 15}}>
                                            {this.state.passwordValidationFailed.map((errMsgKey, i) => (
                                                <div key={i}>
                                                    <Translation ns="profile">
                                                        {(t) => (
                                                            <p style={{
                                                                fontFamily: 'MontserratMedium',
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                color: 'red',
                                                                marginBottom: 0
                                                            }}>
                                                                {t(errMsgKey + '.invalid')}
                                                            </p>
                                                        )}
                                                    </Translation>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                ) : null}
                                <Col md={12} lg={8} sm={12} style={{ textAlign: 'center' }}>
                                    <Button type="submit" disabled={submitting} className={'user-profile-submit-btn'}>
                                        {t('changePasswordBtn')}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Container>
                )}
            </Translation>
        )
    }
}

const mapStateToProps = (state) => {
    return { initialValues: state.userData }
};

export default connect(mapStateToProps, { fetchUserData })(reduxForm({
    form: 'userProfile',
    enableReinitialize: true
})(UserProfile))
