import React from "react";
import {Container} from "react-bootstrap";

const ContactIt = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold', textTransform: 'uppercase' }}>I NOSTRI CONTATTI</p>
                    <div className="privacySection" >
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>HAI BISOGNO DI ASSISTENZA PER LAMENTELE O ALTRE INFO?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Vi ricordiamo che per eventuali ritardi sugli ordini o per problemi legati all’ordine, dovete contattare direttamente il ristorante di riferimento. Vvfood non è responsabile direttamente degli ordini ricevuti.
                        </p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Per segnalarci un problema avuto con un ordine puoi scriverci a&nbsp;
                            <a className="link" href="mailto:info@vvfood.it">info@vvfood.it</a>.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>SEI UN GIORNALISTA E VUOI CONOSCERE MEGLIO LA NS FILOSOFIA:</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Scrivici all’indirizzo mail&nbsp;
                            <a className="link" href="mailto:marketing@vvfood.it">marketing@vvfood.it</a>
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>RISTORANTI</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Hai un ristorante salutare e sei interessato ad entrare nella rete Vvfood, vai alla sezione&nbsp;
                            <a className="link" href="/become-partners">diventa partner</a>
                        </p>
                    </div>
                    <div style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>MARKETING</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Vuoi proporci un’iniziativa promozionale, scrivici a&nbsp;
                            <a className="link" href="mailto:marketing@vvfood.it">marketing@vvfood.it</a>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default ContactIt;