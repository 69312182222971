import React from "react";
import i18next from "i18next";
import HowDoesItWorkEn from "./HowDoesItWorkEn";
import HowDoesItWorkIt from "./HowDoesItWorkIt";

const HowDoesItWork = props => {
    switch(i18next.language) {
        case 'en':
            return <HowDoesItWorkEn/>;
        case 'it':
            return <HowDoesItWorkIt/>;
        default:
            return <HowDoesItWorkIt/>;
    }
}

export default HowDoesItWork;