import React from 'react';
import { connect } from 'react-redux';
import { setClosedModalVisibility } from '../actions';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Translation } from "react-i18next";


/**
 * Restaurant closed modal class.
 */
class RestaurantClosedModal extends React.Component {
    render() {
        return (
            <Container fluid>
                <Col sm={12}>
                    <Modal
                        size='lg'
                        show={this.props.restaurantClosedModal.isVisible}
                        onHide={() => this.props.setClosedModalVisibility(false)}
                        dialogClassName={'cart-modal'}>
                        <Modal.Body>
                            <Container style={{ border: '4px solid #F1BA03', position: 'relative' }}>
                                <div style={{
                                    position: 'absolute',
                                    top: 5,
                                    right: 15,
                                    fontSize: 20,
                                    fontFamily: 'MontserratMedium',
                                    zIndex: 1,
                                    cursor: 'pointer'
                                }}
                                    onClick={() => this.props.setClosedModalVisibility(false)}> X </div>
                                <Row>
                                    <Col md={12}>
                                        <Translation ns="modals">
                                            {t =>
                                                <p style={{ margin: 30, lineHeight: 2, fontFamily: 'MontserratMedium', color: 'rgb(86, 119, 58)' }}>
                                                    {t("restaurant.closed1")}
                                                    <br />
                                                    {t("restaurant.closed2")}
                                               </p>
                                            }
                                        </Translation>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                    </Modal>
                </Col>
            </Container>
        );
    }
}

/**,
 * Maps state to props.
 * @param state the state.
 * @returns {{restaurantClosedModal: *}}
 */
const mapStateToProps = (state) => {
    return {
        restaurantClosedModal: state.restaurantClosedModal,
    };
};

export default connect(mapStateToProps, { setClosedModalVisibility })(
    RestaurantClosedModal,
);
