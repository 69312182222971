import React from "react";
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import Checkout from './Checkout'
import { loadStripe } from '@stripe/stripe-js';
import {connect} from "react-redux";

/**
 * Component used for pass Stripe props.
 */
class PaymentArea extends React.Component {


    constructor() {
        super();
        this.state = { stripePromise: null }
    }

    componentDidMount() {
            setTimeout(() => {
                /**
                 * Load Stripe data.
                 */
                this.setState({stripePromise: loadStripe(process.env.REACT_APP_STRIPE_PK,
                    { stripeAccount: this.props.selectedRestaurant.stripeAccountId })})
              }, 0);
    }


    render() {
        return (
            <Elements fonts={[
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat'
                }
            ]} stripe={this.state.stripePromise}>
                <ElementsConsumer>
                    {({ stripe, elements }) => (
                        <Checkout stripe={stripe} elements={elements} />
                    )}
                </ElementsConsumer>
            </Elements>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        selectedRestaurant: state.selectedRestaurant
    }
};

export default connect(mapStateToProps)(PaymentArea);
