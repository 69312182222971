import {
    FETCH_TAGS, SELECT_TAG
} from '../actions/ActionTypes';


/**
 * Initial state.
 */
const initialState = {
    tagsList: [],
    selectedTags: []
};

/**
 * Tags reducer.
 * @param state
 * @param action action.
 */
export default (state = initialState, action) => {
    if (action.type === FETCH_TAGS) {
        return {
            ...state,
            tagsList: action.payload,
        };
    } if (action.type === SELECT_TAG) {
        return {
            ...state,
            selectedTags: action.payload
        }
    }
    else {
        return state;
    }
};