import VvFoodApi from "../api/VvFoodApi";
import Config from './../utility/Config'
import {
    SET_EMAIL,
    SET_REQUIRED_EMAIL,
    SET_PASSWORD,
    SET_REQUIRED_PASSWORD,
    SET_CONFIRM_PASSWORD,
    SET_REQUIRED_CONFIRM_PASSWORD,
    SET_ERROR_LIST,
    SEND_RESET_PASSWORD_REQUEST,
    FETCH_RESTAURANT,
    FETCH_NEAREST_RESTAURANT,
    FETCH_TAGS,
    FETCH_RESTAURANTS,
    FETCH_RESTAURANTS_BY_ADDRESS,
    SET_ADDRESS,
    SET_NAME,
    SHOW_NO_RESTAURANTS_NEARBY,
    SHOW_RESTAURANTS_FOUND,
    SELECT_RESTAURANT,
    SELECT_DISH,
    FETCH_MENUS,
    SHOW_MODAL,
    HIDE_MODAL,
    SHOW_MODAL_CART,
    HIDE_MODAL_CART,
    FETCH_DISH,
    FETCH_PICK_UP_TIMES,
    SET_PICK_UP_HOUR,
    SET_PICK_UP_MINUTE,
    CHANGE_QUANTITY,
    CHANGE_CHECK,
    CALCULATE_TOTAL_PRICE,
    SET_CART,
    SET_CART_INDEX,
    USER_LOAD,
    SET_CLOSED_MODAL_VISIBILITY,
    SELECT_TAG,
    SET_MIN_ORDER_MODAL_VISIBILITY,
    SELECT_FILTER,
    FETCH_DELIVERY_TIMES,
    SELECT_ORDER_TYPE,
    SET_DELIVERY_MINUTE,
    SET_DELIVERY_HOUR,
    SET_DELIVERY_OPEN,
    SET_GO_TO_CHECKOUT_MODAL_VISIBILITY,
    ENABLE_DISCOUNT,
    SET_PAYMENT_CREDIT_CARD,
    SELECT_CART_RESTAURANT,
    SET_TO_CHECKOUT,
    SET_NOTE,
    SET_DELIVERY_PRICE,
    SUBMIT_ORDER,
    SET_RESTAURANT_ID,
    SUBMIT_ERROR, SET_CARD_ERROR,
    SET_DELIVERY_ADDRESS,
    SET_DELIVERY_AVAILABLE,
    SET_DISCOUNT,
    SELECT_MENU_TAG,
    SHOW_USER_MODAL
} from './ActionTypes';

/**
 * Action for set email.
 * @returns {{payload: string, type: *}}
 */
export const setEmail = (val) => ({
    type: SET_EMAIL,
    payload: val
});

/**
 * Action for set required email.
 * @returns {{payload: boolean, type: *}}
 */
export const setRequiredEmail = (val) => ({
    type: SET_REQUIRED_EMAIL,
    payload: val
});

/**
 * Action for set password.
 * @returns {{payload: string, type: *}}
 */
export const setPassword = (val) => ({
    type: SET_PASSWORD,
    payload: val
});

/**
 * Action for set required password.
 * @returns {{payload: boolean, type: *}}
 */
export const setRequiredPassword = (val) => ({
    type: SET_REQUIRED_PASSWORD,
    payload: val
});

/**
 * Action for set confirm password.
 * @returns {{payload: string, type: *}}
 */
export const setConfirmPassword = (val) => ({
    type: SET_CONFIRM_PASSWORD,
    payload: val
});

/**
 * Action for set required confirm password.
 * @returns {{payload: boolean, type: *}}
 */
export const setRequiredConfirmPassword = (val) => ({
    type: SET_REQUIRED_CONFIRM_PASSWORD,
    payload: val
});

/**
 * Action for set error list.
 * @returns {{payload: array, type: *}}
 */
export const setErrorList = (val) => ({
    type: SET_ERROR_LIST,
    payload: val
});

/**
 * Action for send password reset request.
 * @returns {payload: array, type: *}
 */
export const sendResetPasswordRequest = (baseJson) => async dispatch => {
    try {
        const response = await VvFoodApi.post(
            '/users/change_password',
            baseJson
        )
        dispatch({ type: SEND_RESET_PASSWORD_REQUEST, payload: 1 });
    } catch (err) {
        dispatch({ type: SEND_RESET_PASSWORD_REQUEST, payload: -1 });

    }
};

/**
 * Fetches all restaurants.
 * @returns {Function}
 */
export const fetchRestaurants = () => async dispatch => {
    try {
        const response = await VvFoodApi.get('/restaurants');
        dispatch({ type: FETCH_RESTAURANTS, payload: response.data });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Fetches searched restaurants.
 * @returns {payload: array, type: *}
 * @param {*} address address selected by user.
 * @param {*} name name selected by user.
 */
export const fetchRestaurantsByAddress = (address, name, tags = [], freeDelivery = false, homeDelivery = false, localPickup = false, openedNow = false) => async dispatch => {
    try {
        const response = await VvFoodApi.post(
            '/restaurants/search',
            { address: address, name: name, tags: tags, freeDelivery: freeDelivery, homeDelivery: homeDelivery, localPickup: localPickup, openedNow: openedNow, }
        );
        dispatch({ type: FETCH_RESTAURANTS_BY_ADDRESS, payload: response.data });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Action to set searched address.
 * @returns {payload: *, type: string}
 */
export const setAddress = (val) => ({
    type: SET_ADDRESS,
    payload: val
});

/**
 * Action to set searched name.
 * @returns {payload: *, type: string}
 */
export const setName = (val) => ({
    type: SET_NAME,
    payload: val
});

/**
 * Action for showing no restaurants nearby.
 * @returns {{payload: boolean, type: *}}
 */
export const displayNoRestaurantsNearby = () => ({
    type: SHOW_NO_RESTAURANTS_NEARBY,
    payload: true
});

/**
 * Action for showing restaurants found.
 * @returns {{payload: boolean, type: *}}
 */
export const displayRestaurantsFound = () => ({
    type: SHOW_RESTAURANTS_FOUND,
    payload: true
});

/**
 * Action for hiding restaurants found.
 * @returns {{payload: boolean, type: *}}
 */
export const hideRestaurantsFound = () => ({
    type: SHOW_RESTAURANTS_FOUND,
    payload: false
});

/**
 * Action for hiding no restaurants nearby.
 * @returns {{payload: boolean, type: *}}
 */
export const hideNoRestaurantsNearby = () => ({
    type: SHOW_NO_RESTAURANTS_NEARBY,
    payload: false
});

/**
 * Fetches all the restaurant menus.
 * @param {*} restaurantId id of the restaurant.
 */
export const fetchMenus = (restaurantId) => async dispatch => {
    try {
        const response = await VvFoodApi.get('/menu/' + restaurantId);
        dispatch({ type: FETCH_MENUS, payload: response.data });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Action to set tags for menus filtering.
 * @returns {payload: *, type: string}
 */
export const selectMenuTag = (tagId, selectedTags, restaurantId) => async dispatch => {
    try {
    let t = selectedTags;
    let index = t.indexOf(tagId);
    if (index > -1) {
        t.splice(index, 1);
    }
    else {
        t.push(tagId)
    }
    if (tagId == Config.ALL_TAGS_ID) {
        t = []
    } else {
        if (t.includes(Config.ALL_TAGS_ID)) {
            t.splice(t.indexOf(Config.ALL_TAGS_ID), 1);
        }
    }
    let tagsToSend = "?";
    if(t.length > 0) {
        for(let i = 0; i < t.length; i++) {
            let tag = "tags=" + t[i] + "&";
            tagsToSend += tag;
        }
    }
    const response = await VvFoodApi.get('/menu/' + restaurantId + "/" + tagsToSend);
    let obj = {menus: response.data, tags: t}
    dispatch({ type: SELECT_MENU_TAG, payload: obj });
    } catch(err) {
        console.log(err)
    }
};

/**
 * Fetches single restaurant by id.
 * @param id id of the restaurant.
 * @returns {Function}
 */
export const fetchRestaurant = (id) => async dispatch => {
    try {
        const response = await VvFoodApi.get(`/restaurants/${id}`);
        dispatch({ type: FETCH_RESTAURANT, payload: response.data });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Fetches nearest restaurant to provided address.
 * @param address address selected by user.
 * @returns {Function}
 */
export const fetchNearestRestaurant = (address) => async dispatch => {
    try {
        const response = await VvFoodApi.post(
            '/restaurants/nearest',
            { address: address }
        );
        dispatch({ type: FETCH_NEAREST_RESTAURANT, payload: response.data });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Fetches all tags.
 * @returns {Function}
 */
export const fetchTags = () => async dispatch => {
    try {
        const response = await VvFoodApi.get('/tags');

        dispatch({ type: FETCH_TAGS, payload: response.data });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Fetches single restaurant by id.
 * @param id id of the restaurant.
 * @returns {Function}
 */
export const fetchDish = (id) => async dispatch => {
    try {
        const response = await VvFoodApi.get(`/dish/${id}`);
        dispatch({ type: FETCH_DISH, payload: response.data });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Fetches pick up times by restaurant id.
 * @param id id of the restaurant.
 * @returns {Function}
 */
export const fetchPickUpTimes = (id) => async dispatch => {
    try {
        const response = await VvFoodApi.get(`/restaurants/${id}/pickUpTimes`);
        var mappedResponse = new Map();
        response.data.forEach(element => {
            var hour = element.split('.')[0];
            var minute = element.split('.')[1];
            if (!mappedResponse.has(hour)) {
                mappedResponse.set(hour, new Array())
            }
            mappedResponse.get(hour).push(minute)
        });
        dispatch({ type: FETCH_PICK_UP_TIMES, payload: mappedResponse });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Fetches delivery times by restaurant id.
 * @param id id of the restaurant.
 * @returns {Function}
 */
export const fetchDeliveryTimes = (id) => async dispatch => {
    try {
        const response = await VvFoodApi.get(`/restaurants/${id}/deliveryTimes`);
        var mappedResponse = new Map();
        response.data.forEach(element => {
            var hour = element.split('.')[0];
            var minute = element.split('.')[1];
            if (!mappedResponse.has(hour)) {
                mappedResponse.set(hour, new Array())
            }
            mappedResponse.get(hour).push(minute)
        });
        dispatch({ type: FETCH_DELIVERY_TIMES, payload: mappedResponse });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Action to set pick up hour.
 * @returns {{payload: string, type: *}}
 */
export const setPickUpHour = (val) => ({
    type: SET_PICK_UP_HOUR,
    payload: val
});

/**
 * Action to set pick up minute.
 * @returns {{payload: string, type: *}}
 */
export const setPickUpMinute = (val) => ({
    type: SET_PICK_UP_MINUTE,
    payload: val
});

/**
 * Action to set delivery hour.
 * @returns {{payload: string, type: *}}
 */
export const setDeliveryHour = (val) => ({
    type: SET_DELIVERY_HOUR,
    payload: val
});

/**
 * Action to set delivery minute.
 * @returns {{payload: string, type: *}}
 */
export const setDeliveryMinute = (val) => ({
    type: SET_DELIVERY_MINUTE,
    payload: val
});

/**
 * Action for setting selected restaurant id to the state.
 * @param id
 * @returns {{payload: *, type: string}}
 */
export const selectRestaurant = (id) => {
    return {
        type: SELECT_RESTAURANT,
        payload: id
    }
};

/**
 * Action for setting selected dish id to the state.
 * @param id
 * @returns {{payload: *, type: string}}
 */
export const selectDish = (id) => {
    return {
        type: SELECT_DISH,
        payload: id
    }
};

/**
 * Action for showing modal.
 * @returns {{payload: boolean, type: *}}
 */
export const displayModal = () => ({
    type: SHOW_MODAL,
    payload: true
});

/**
 * Action for hiding modal.
 * @returns {{payload: boolean, type: *}}
 */
export const hideModal = () => ({
    type: HIDE_MODAL,
    payload: false
});

/**
 * Action for showing cart modal.
 * @returns {{payload: boolean, type: *}}
 */
export const displayModalCart = () => ({
    type: SHOW_MODAL_CART,
    payload: true
});

/**
 * Action for hiding cart modal.
 * @returns {{payload: boolean, type: *}}
 */
export const hideModalCart = () => ({
    type: HIDE_MODAL_CART,
    payload: false
});

/**
 * Action for changing quantity.
 * @returns {{payload: *, type: integer}}
 */
export const setQuantity = (val) => ({
    type: CHANGE_QUANTITY,
    payload: val
});

/**
 * Action for calculating total price.
 * @returns {{payload: *, type: integer}}
 */
export const setTotalPrice = (val) => ({
    type: CALCULATE_TOTAL_PRICE,
    payload: val
});

/**
 * Action for changing checked checkboxes.
 * @returns {{payload: boolean, type: *}}
 */
export const setChecked = (val) => ({
    type: CHANGE_CHECK,
    payload: val
});

/**
 * Action to set the content of the cart.
 * @returns {payload: *, type: array}
 */
export const setCart = (val) => ({
    type: SET_CART,
    payload: val
});

/**
 * Action to set cart index.
 * @returns {payload: *, type: integer}
 */
export const setCartIndex = (val) => ({
    type: SET_CART_INDEX,
    payload: val
});

/**
 * Fetches user data.
 * @returns {Function}
 */
export const fetchUserData = (id) => async dispatch => {
    try {
        const response = await VvFoodApi.get('/users/' + id);
        var splittedAddress = response.data.address.split(",")
        response.data.city = splittedAddress[0]
        response.data.street = splittedAddress[1]
        response.data.houseNumber = splittedAddress[2]
        response.data.postalCode = splittedAddress[3]
        dispatch({ type: USER_LOAD, payload: response.data });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Setting restaurant is closed modal visibility.
 * @param flag the flag.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setClosedModalVisibility = (flag) => ({
    type: SET_CLOSED_MODAL_VISIBILITY,
    payload: flag,
});

/**
 * Action for setting selected tag id to the state.
 * @param id
 * @returns {{payload: *, type: string}}
 */
export const selectTag = (id, currentSelectedTags) => {
    let temp = currentSelectedTags;

    if (temp.includes(id)) {
        for (let i = 0; i < temp.length; i++) {
            if (temp[i] === id) {
                temp.splice(i, 1);
            }
        }
    } else {
        temp.push(id)
    }

    if (id == Config.ALL_TAGS_ID) {
        temp = []
    } else {
        if (temp.includes(Config.ALL_TAGS_ID)) {
            temp.splice(temp.indexOf(Config.ALL_TAGS_ID), 1);
        }
    }

    return {
        type: SELECT_TAG,
        payload: temp,
    }
};

/**
 * Validates minimum order of a restaurant.
 * @param baseJson the data to validate.
 * @returns {Function}
 */
export const validateMinOrder = (baseJson) => async dispatch => {
    try {
        const response = await VvFoodApi.post(
            '/order/validateMinOrder',
            baseJson
        );
        dispatch({ type: SET_MIN_ORDER_MODAL_VISIBILITY, payload: response.data });
    } catch (err) {
        console.log(err);
    }
};

/**
 * Sets minimum order error modal visibility
 * @param flag
 * @returns {{payload: *, type: *}}
 */
export const setMinOrderErrModalVisibility = (flag) => ({
    type: SET_MIN_ORDER_MODAL_VISIBILITY,
    payload: !flag,
});

/**
 * Select filter action.
 * @param {*} freeDelivery
 * @param {*} homeDelivery
 * @param {*} localPickup
 * @param {*} openedNow
 */
export const selectFilter = (freeDelivery, homeDelivery, localPickup, openedNow) => ({
    type: SELECT_FILTER,
    payload: { freeDelivery: freeDelivery, homeDelivery: homeDelivery, localPickup: localPickup, openedNow: openedNow }
});

/**
 * Select order type action.
 * @param {*} orderType
 */
export const selectOrderType = (orderType) => ({
    type: SELECT_ORDER_TYPE,
    payload: orderType
})

/**
 * Set if delivery is opened or no action.
 * @param {*} isOpened
 */
export const setDeliveryOpened = (isOpened) => ({
    type: SET_DELIVERY_OPEN,
    payload: isOpened
})

/**
 * Setting go to checkout modal visibility.
 * @param flag the flag.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setGoToCheckoutModalVisibility = (flag) => ({
    type: SET_GO_TO_CHECKOUT_MODAL_VISIBILITY,
    payload: flag,
});

/**
 * Setting go to checkout discount field visibility.
 * @param flag the flag.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setDiscountField = (flag) => ({
    type: ENABLE_DISCOUNT,
    payload: flag,
});

/**
 * set the checkout payment type.
 * @param flag the flag.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setCreditCardPayment = (flag) => ({
    type: SET_PAYMENT_CREDIT_CARD,
    payload: flag,
});

/**
 * Set the restaurant of the cart.
 * @param slug the flag.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setCartRestaurantSlug = (slug) => ({
    type: SELECT_CART_RESTAURANT,
    payload: slug,
});

/**
 * Set the toCheckout value for login and registration.
 * @param flag the flag.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setGoToCheckout = (flag) => ({
    type: SET_TO_CHECKOUT,
    payload: flag,
});

/**
 * Set the toCheckout value for login and registration.
 * @param content the flag.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setCartNote = (content) => ({
    type: SET_NOTE,
    payload: content,
});

/**
 * Set the delivery price in cart.
 * @param value the flag.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setDeliveryPrice = (value) => ({
    type: SET_DELIVERY_PRICE,
    payload: value,
});

/**
 * Order submit.
 * @param baseJson order details.
 * @returns {Function}
 */
export const submitOrder = (baseJson) => async dispatch => {
    try {
        const response = await VvFoodApi.post(
            '/order',
            baseJson
        );
        dispatch({ type: SUBMIT_ORDER, payload: response.data });
    } catch (err) {
        dispatch({ type: SUBMIT_ERROR, payload: true });
    }
};

/**
 * Set the restaurant id in checkout.
 * @param value the id.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setRestaurantId = (value) => ({
    type: SET_RESTAURANT_ID,
    payload: value,
});

/**
 * Set the error .
 * @param message message error.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setCardError = (message) => ({
    type: SET_CARD_ERROR,
    payload: message,
});

/**
 * Set the delivery address .
 * @param address chosen.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setDeliveryAddress = (address) => ({
    type: SET_DELIVERY_ADDRESS,
    payload: address,
});

/**
 * Set the delivery available .
 * @param flag result.
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setDeliveryAvailable = (address, restaurantId) => async dispatch => {
        const response = await VvFoodApi.post(
            '/restaurants/' + restaurantId + '/isServingToAddress',
            {address: address}
        );
        dispatch({ type: SET_DELIVERY_AVAILABLE, payload: response.data });
};

/**
 * Set the discount value .
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setDiscount = (discountCode, subTotal, restaurantId) => async dispatch => {
    let payload = {
      code: discountCode,
    };
    await VvFoodApi.post(
        '/order/' + restaurantId + '/discount/code',
        {code: discountCode, subTotal: subTotal }
    ).then((response) => {
        dispatch({ type: SET_DISCOUNT, payload: {...payload, value: subTotal - response.data} });
    }).catch(() => {
        dispatch({ type: SET_DISCOUNT, payload: {code: '', value: 0} });
    });
};

/**
 * Set user modal visibility .
 * @param val .
 * @returns {{payload: *, type: *}} type and payload.
 */
export const setUserModalVisibility = (val) => ({
    type: SHOW_USER_MODAL,
    payload: val,
});
