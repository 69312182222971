import {
    SET_EMAIL,
    SET_REQUIRED_EMAIL,
    SET_PASSWORD,
    SET_REQUIRED_PASSWORD,
    SET_CONFIRM_PASSWORD,
    SET_REQUIRED_CONFIRM_PASSWORD,
    SET_ERROR_LIST,
    SEND_RESET_PASSWORD_REQUEST,
} from '../actions/ActionTypes';

/**
 * Initial state.
 * @type {{email: string, requiredEmail: boolean, password: string, requiredPassword: boolean, confirmPassword: string, requiredConfirmPassword: boolean, errorList: array, requestStatus: integer }}
 */
const initialState = {
    email: "",
    requiredEmail: false,
    password: "",
    requiredPassword: false,
    confirmPassword: undefined,
    requiredConfirmPassword: false,
    errorList: [],
    requestStatus: 0,
};

/**
 * Reset password reducer.
 * @param state state.
 * @param action action.
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_EMAIL:
            return {
                email: action.payload,
                requiredEmail: state.requiredEmail,
                password: state.password,
                requiredPassword: state.requiredPassword,
                confirmPassword: state.confirmPassword,
                requiredConfirmPassword: state.requiredConfirmPassword,
                errorList: state.errorList,
                requestStatus: state.requestStatus
            };
        case SET_REQUIRED_EMAIL:
            return {
                email: state.email,
                requiredEmail: action.payload,
                password: state.password,
                requiredPassword: state.requiredPassword,
                confirmPassword: state.confirmPassword,
                requiredConfirmPassword: state.requiredConfirmPassword,
                errorList: state.errorList,
                requestStatus: state.requestStatus
            };
        case SET_PASSWORD:
            return {
                email: state.email,
                requiredEmail: state.requiredEmail,
                password: action.payload,
                requiredPassword: state.requiredPassword,
                confirmPassword: state.confirmPassword,
                requiredConfirmPassword: state.requiredConfirmPassword,
                errorList: state.errorList,
                requestStatus: state.requestStatus
            };
        case SET_REQUIRED_PASSWORD:
            return {
                email: state.email,
                requiredEmail: state.requiredEmail,
                password: state.password,
                requiredPassword: action.payload,
                confirmPassword: state.confirmPassword,
                requiredConfirmPassword: state.requiredConfirmPassword,
                errorList: state.errorList,
                requestStatus: state.requestStatus
            };
        case SET_CONFIRM_PASSWORD:
            return {
                email: state.email,
                requiredEmail: state.requiredEmail,
                password: state.password,
                requiredPassword: state.requiredPassword,
                confirmPassword: action.payload,
                requiredConfirmPassword: state.requiredConfirmPassword,
                errorList: state.errorList,
                requestStatus: state.requestStatus
            };
        case SET_REQUIRED_CONFIRM_PASSWORD:
            return {
                email: state.email,
                requiredEmail: state.requiredEmail,
                password: state.password,
                requiredPassword: state.requiredPassword,
                confirmPassword: state.confirmPassword,
                requiredConfirmPassword: action.payload,
                errorList: state.errorList,
                requestStatus: state.requestStatus
            };
        case SET_ERROR_LIST:
            return {
                email: state.email,
                requiredEmail: state.requiredEmail,
                password: state.password,
                requiredPassword: state.requiredPassword,
                confirmPassword: state.confirmPassword,
                requiredConfirmPassword: state.requiredConfirmPassword,
                errorList: action.payload,
                requestStatus: state.requestStatus
            };
            case SEND_RESET_PASSWORD_REQUEST:
                return {
                    email: state.email,
                    requiredEmail: state.requiredEmail,
                    password: state.password,
                    requiredPassword: state.requiredPassword,
                    confirmPassword: state.confirmPassword,
                    requiredConfirmPassword: state.requiredConfirmPassword,
                    errorList: state.errorList,
                    requestStatus: action.payload
                };
        default:
            return state
    }
}