import {
    SET_GO_TO_CHECKOUT_MODAL_VISIBILITY,
    SELECT_CART_RESTAURANT,
    SET_TO_CHECKOUT

} from '../actions/ActionTypes';

/**
 * Initial state.
 * @type {{isVisible: boolean}}
 */
const initialState = {
    isVisible: false,
    selectedRestaurant: null,
    toCheckout: false,
};

/**
 * Restaurant closed modal reducer.
 * @param state state.
 * @param action action.
 * @returns {{isVisible: *}|{isVisible: boolean}}
 */
export default (state = initialState, action) => {
    if (action.type === SET_GO_TO_CHECKOUT_MODAL_VISIBILITY) {
        return {
            ...state,
            isVisible: action.payload,
        };
    } else if (action.type === SELECT_CART_RESTAURANT) {
        return {
            ...state,
            selectedRestaurant: action.payload,
        };
    } else if (action.type === SET_TO_CHECKOUT) {
        return {
            ...state,
            toCheckout: action.payload,
        };
    } else {
        return state;
    }
}
