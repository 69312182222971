import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style.css'
import './assets/locales/i18n';

const Index = () => {
  return (
    <div>
      <App />
    </div>
  )
}
ReactDOM.render(
  <Index />,
  document.getElementById('root')
);
