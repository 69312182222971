import {
  SET_CART,
  FETCH_PICK_UP_TIMES,
  SET_PICK_UP_HOUR,
  SET_PICK_UP_MINUTE,
  SET_MIN_ORDER_MODAL_VISIBILITY,
  FETCH_DELIVERY_TIMES,
  SELECT_ORDER_TYPE,
  SET_DELIVERY_HOUR,
  SET_DELIVERY_MINUTE,
  SET_DELIVERY_OPEN,
  SET_NOTE,
  SET_DELIVERY_PRICE,
  SET_DELIVERY_ADDRESS,
  SET_DELIVERY_AVAILABLE
} from "../actions/ActionTypes";

/**
 * Initial state.
 * @type {{cartContent: array, pickUpTimes: map, selHour: string, selMinute: string, selDeliveryHour: string, selDeliveryMinute: string, orderType:string,deliveryOpen:boolean}}
 */
const initialState = {
  cartContent: [],
  pickUpTimes: new Map(),
  selHour: "-",
  selMinute: "-",
  selDeliveryHour: "-",
  selDeliveryMinute: "-",
  isMinOrderErrVisible: false,
  deliveryTimes: new Map(),
  orderType: "delivery",
  deliveryOpen: true,
  deliveryAddress: '',
  deliveryAvailable: false,
  selectedRestaurant: null,
  note: "",
  deliveryPrice: 0,
};

/**
 * Cart reducer.
 * @param state state.
 * @param action action.
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CART:
      return {
        ...state,
        cartContent: action.payload,
        pickUpTimes: state.pickUpTimes,
        selHour: state.selHour,
        selMinute: state.selMinute,
        isMinOrderErrVisible: state.isMinOrderErrVisible,
      };
    case FETCH_PICK_UP_TIMES:
      return {
        ...state,
        cartContent: state.cartContent,
        pickUpTimes: action.payload,
        selHour: state.selHour,
        selMinute: state.selMinute,
        isMinOrderErrVisible: state.isMinOrderErrVisible,
      };
    case SET_PICK_UP_HOUR:
      return {
        ...state,
        cartContent: state.cartContent,
        pickUpTimes: state.pickUpTimes,
        selHour: action.payload,
        selMinute: state.selMinute,
        isMinOrderErrVisible: state.isMinOrderErrVisible,
      };
    case SET_PICK_UP_MINUTE:
      return {
        ...state,
        cartContent: state.cartContent,
        pickUpTimes: state.pickUpTimes,
        selHour: state.selHour,
        selMinute: action.payload,
        isMinOrderErrVisible: state.isMinOrderErrVisible,
      };
    case SET_DELIVERY_HOUR:
      return {
        ...state,
        selDeliveryHour: action.payload,
      };
    case SET_DELIVERY_MINUTE:
      return {
        ...state,
        selDeliveryMinute: action.payload,
      };
    case SET_MIN_ORDER_MODAL_VISIBILITY:
      return {
        ...state,
        isMinOrderErrVisible: !action.payload,
      };
    case FETCH_DELIVERY_TIMES:
      return {
        ...state,
        deliveryTimes: action.payload,
      };
    case SELECT_ORDER_TYPE:
      return {
        ...state,
        orderType: action.payload,
      };
    case SET_DELIVERY_OPEN:
      return {
        ...state,
        deliveryOpen: action.payload,
      };
    case SET_NOTE:
      return {
        ...state,
        note: action.payload,
      };
    case SET_DELIVERY_PRICE:
      return {
        ...state,
        deliveryPrice: action.payload,
      };
    case SET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.payload,
      };
    case SET_DELIVERY_AVAILABLE:
      return {
        ...state,
        deliveryAvailable: action.payload,
      };
    default:
      return state;
  }
};
