import React from "react";
import {Container} from "react-bootstrap";

const HowDoesItWorkIt = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold', textTransform: 'uppercase' }}>COME FUNZIONA VVFOOD</p>
                    <div className="privacySection" >
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COS’È VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Vvfood è un nuovo concetto di food-delivery basato sulle nuove tendenze dell’offerta ristorativa, etica perché ha l’obiettivo di mettere in condizione i ristoratori di offrire un servizio a basso costo, diretto e di qualita’. Scegli tu se riceverlo a casa o ritirarlo presso il punto vendita.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>MISSION DI VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Aiutare i nostri clienti ad entrare in contatto diretto con i ristoratori ed il loro modelli produttivi, conoscere il dettaglio delle offerte per garantire un servizio rapido, diretto e senza intermediazione.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>ORDINARE SU VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Con Vvfood puoi ordinare online e ricevere a domicilio o in ufficio i tuoi piatti. Ordinare su Vvfood è semplicissimo: inserisci il tuo indirizzo, scegli il tuo ristorante preferito e riempi il tuo carrello con i prodotti che desideri ricevere. Inserisci l’orario di consegna, i tuoi dettagli di pagamento: il ristorante ha ricevuto il tuo ordine provvederà a consegnartelo nei tempi richiesti.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>DOVE È ATTIVA VVFOOD?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                        La copertura del servizio è in continua evoluzione. Verifica nella Home page, inserendo il tuo indirizzo nella finestra di ricerca per verificare se la tua zona è coperta dal nostro servizio.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COME POSSO CREARE IL MIO ACCOUNT SU VVFOOD?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Per creare il tuo account vai alla sezione <a className="link" href="/register">Registrazione</a>
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>QUALI SONO GLI ORARI DI VVFOOD?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Ogni ristorante sceglie in modo autonomo i propri orari. Basta consultare nello spazio dedicato ai singoli ristoranti gli orari dei propri servizi
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>SU VVFOOD ESISTE UN ORDINE MINIMO?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Dipende dal ristorante in base alla propria offerta.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COME VARIA IL COSTO DI CONSEGNA?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            A discrezione del ristoratore, il quale potrà decidere anche di non applicare alcun costo. I costi di consegna sono evidenziati nell’area ristorante.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>IN QUANTO TEMPO MI SARÀ CONSEGNATO L'ORDINE?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Scegli tu l’orario di consegna in base alle fasce orarie stabilite dal ristoratore.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>ESISTE UN’APP DI VVFOOD?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Ancora no, ma stiamo lavorando per la realizzazione.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COME POSSO MODIFICARE IL MIO ORDINE SE HO INSERITO QUALCOSA DI SBAGLIATO?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Una volta completato un ordine, non è possibile modificarlo. Ti invitiamo perciò a prestare attenzione nel momento in cui inserisci i prodotti nel tuo carrello o fornisci i tuoi dettagli di consegna. Se dovessi accorgerti di aver commesso un errore chiama subito il ristorante di riferimento, troverai tutti i dati sulla sezione “info ristorante” in alto a sinistra nella pagina ristorante.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COSA POSSO FARE SE IL MIO ORDINE È IN RITARDO RISPETTO ALL’ORARIO PREVISTO?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Tutti ristoranti si riservano un margine di ritardo massimo di 15 minuti; qualora la soglia venisse superata, o comunque per qualsiasi problema relativo all’ordine, puoi contattare direttamente il ristorante utilizzando i contatti che trovi nell’area “info ristorante”.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COME POSSO DIVENTARE FOOD TRASPORTER E CONSEGNARE PER VVFOOD?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Per diventare Food Trasporter candidati online! Inviaci la tua candidatura nella sezione apposita che trovi in home page.
                        </p>
                    </div>
                    <div style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>VORREI CHE IL MIO RISTORANTE CONSEGNASSE A DOMICILIO CON VVFOOD?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Inserisci la tua candidatura e ti richiameremo il prima possibile. Vai alla pagina&nbsp;
                            <a className="link" href="/become-partners">Diventa partner</a>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default HowDoesItWorkIt;