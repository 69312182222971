import React from "react";
import {Container} from "react-bootstrap";

const HowDoesItWorkEn = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold', textTransform: 'uppercase' }}>HOW DOES IT WORK</p>
                    <div className="privacySection" >
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>ABOUT US</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Vvfood is a new concept of food-delivery based on the new trends of the restaurant offer, it’s ethical because it aims to enable restaurateurs to offer a low cost, direct and quality service. You can choose whether to receive your order at home or pick it up at the point of sale.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>OUR MISSION</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Our mission is to provide customers with informations about restaurants food-philosophy, and offer restaurant owners an easy tool to manage their delivery and pickup system without intermediatories.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>ORDERING ON VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            We designed the platform to make your ordering experience very easy: simply enter your address and location, select your favourite restaurant, plan a delivery/pickup time and choose what you want to drink and eat! Depending on the payments terms set by the restaurants you may have to use the same payment terms either by credit card when ordering your menu or pay on delivery/pickup.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>WHERE DOES VVFOOD OPERATES</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Currently Vvfood operates in Rome, but we aim to extend our mission.
                            If you want to use the platform in a different city please write us at&nbsp;
                            <a className="link" href="mailto:info@vvfood.it">info@vvfood.it</a>
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>HOW TO REGISTER ON VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Create your new account using the section <a className="link" href="/register">Sign up</a>
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>APP VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            We are implementing the app that will be ready soon.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>WHAT IF SOMETHING GOES WRONG WITH MY ORDER</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            For any change on your order, for orders, delays or wrong items please contact the restaurants. All restaurants contact details are on the restaurant page.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>HOW TO BECOME A RIDER</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            If you are interested in working with us as a rider please use the form in home page.
                        </p>
                    </div>
                    <div style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>HOW TO PARTNER WITH VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            If you want to join us as restaurant please apply in the section&nbsp;
                            <a className="link" href="/become-partners">Partner with Vvfood</a>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default HowDoesItWorkEn;