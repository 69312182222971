import {
    USER_LOAD,
} from '../actions/ActionTypes';

/**
 * Modal reducer.
 * @param state state.
 * @param action action.
 */
export default (state = {}, action) => {
    if (action.type === USER_LOAD) {
        return action.payload;
    } else {
        return state;
    }
  };