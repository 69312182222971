import React from "react";
import {Container} from "react-bootstrap";

const TermsAndConditions = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold' }}>Termini e Condizioni di utilizzo del sito web VvFood.it</p>
                    <div className="privacySection" >
                        <p style={{ fontFamily:'Montserrat-SemiBold' }}>IMPORTANTI INFORMAZIONI LEGALI</p>
                        <p  style={{lineHeight:'20px'}}>
                            I presenti termini e condizioni (le "Condizioni del Sito") individuano le regole sulla base delle quali la società VvFood S.r.l. (di seguito "noi" o "VvFood") fornisce accesso al sito web https://www.vvfood.it e a qualsiasi applicazione mobile VvFood che consenta agli utenti di ordinare prodotti (congiuntamente "Sito"). Si prega di leggere attentamente le presenti Condizioni del Sito prima di ordinare eventuali prodotti dal Sito. L'inoltro di ordini tramite il Sito (ora o in futuro) comporta l'accettazione da parte degli utenti delle presenti Condizioni del Sito che disciplinano l'utilizzo del Sito stesso.
                            VvFood si riserva il diritto di modificare nel tempo le presenti Condizioni del Sito mediante modifica delle previsioni riportate su questa pagina. Nel caso in cui l'utente non accetti le nuove Condizioni del Sito, è tenuto a non utilizzare i servizi offerti dal Sito e a non ordinare prodotti tramite il Sito. Si raccomanda di stampare una copia delle presenti Condizioni del Sito per futura consultazione.
                            L'uso di dati personali trasmessi tramite il Sito è disciplinato dalla Informativa VvFood in materia di Privacy.
                            Per chiarezza, si specifica che qualsiasi riferimento al "Sito" contenuto nelle presenti Condizioni del Sito comprende tutte le versioni attuali o future del sito web https://www.vvfood.it nonché qualsiasi applicazione mobile VvFood tramite la quale si abbia accesso al, e si utilizzi il, Sito di VvFood e ciò indipendentemente dal fatto che, sia nell'uno che nell'altro caso, l'accesso avvenga tramite una piattaforma o un dispositivo attualmente esistenti oppure tramite una piattaforma o un dispositivo futuri (ivi inclusi, a titolo esemplificativo e non esaustivo, qualsiasi sito mobile, applicazione mobile, sito affiliato o correlato per l'accesso al, e l'utilizzo del, Sito VvFood eventualmente sviluppato nel corso del tempo).
                            La visita del Sito, anche solo di alcune sue parti, costituisce accettazione da parte degli utenti delle presenti Condizioni del Sito. Gli utenti del Sito che non accettano le presenti Condizioni del Sito sono tenuti a non utilizzare i servizi offerti dal Sito e a non ordinare prodotti tramite il Sito stesso.
                        </p>
                    </div>
                    <div style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold' }}>I. CONDIZIONI GENERALI DI UTILIZZO E DI VENDITA</p>
                        <p  style={{lineHeight:'20px'}}>
                            1. NORME INTRODUTTIVE E RUOLO DI VvFood
                            <br/>1.1. VvFood S.r.l. è una società di diritto italiano, con sede a Trento, in via Gocciadoro, 4 - c.a.p. 38122, C.F. e numero di iscrizione al Registro delle Imprese di Trento n. 02596530226, R.E.A. n. TN-236186.
                            <br/>1.2. Il numero di partita IVA di VvFood è il seguente: 02596530226.
                            <br/>1.3. Ordini dei Prodotti: VvFood fornisce agli utenti un sistema per la comunicazione dei propri ordini alle Ditte venditrici che compaiono sul Sito. Il contratto per la fornitura e l'acquisto di Prodotti è concluso tra l'utente e la Ditta venditrice al quale l'utente inoltra l'Ordine e VvFood concluderà la vendita dei Prodotti per conto delle Ditte venditrici in tutti i casi. In ogni caso, VvFood non ha alcun obbligo di promuovere la conclusione di contratti tra le Ditte venditrici e l'utente.
                            <br/>2. ACCESSO AL SITO E CONDIZIONI DEL SITO
                            <br/>2.1. Accesso al Sito: E' possibile avere accesso ad alcune aree del Sito anche senza effettuare un Ordine o senza procedere alla registrazione dei propri dati. La maggior parte delle aree del Sito è accessibile a chiunque.
                            <br/>2.2. Accettazione delle Condizioni del Sito: La navigazione in qualunque area del Sito comporta l'accettazione delle Condizioni del Sito. Gli utenti del Sito che non accettano le Condizioni del Sito sono tenuti a non utilizzare i servizi offerti dal Sito e a non ordinare prodotti tramite il Sito stesso.
                            <br/>2.3. Modifiche alle Condizioni del Sito: VvFood ha facoltà di modificare le presenti Condizioni del Sito in qualsiasi momento. L'utente è pertanto tenuto a controllare periodicamente le Condizioni del Sito in vigore al momento dell'effettuazione dell'Ordine dei prodotti poiché la versione pubblicata sul Sito a tale data troverà applicazione per gli utenti del Sito. Gli utenti del Sito sono tenuti al rispetto delle regole in vigore al momento in cui effettuano un Ordine tramite VvFood.
                            <br/>2.4. Responsabilità: Spetta ai visitatori e utenti del Sito predisporre quanto necessario per avere accesso al Sito stesso. Inoltre, i visitatori e utenti del Sito sono tenuti a garantire che tutti coloro che hanno accesso al Sito tramite il loro account e la loro connessione ad Internet siano a conoscenza delle presenti Condizioni del Sito e le rispettino.
                            <br/>3. STATUS DELL'UTENTE DEL SITO
                            <br/>3.1. Capacità giuridica ed età: Effettuando un Ordine tramite il Sito, l'utente garantisce:
                            <br/>3.1.1. di aver la capacità di agire e concludere contratti giuridicamente vincolanti con le Ditte venditrici; e
                            <br/>3.1.2. di avere compiuto 18 anni.
                            <br/>3.2. L’utente conferma e si impegna, qualora abbia una specifica allergia o intolleranza alimentare, a contattare direttamente la Ditta venditrice per verificare che il cibo sia indicato alle proprie esigenze, prima di effettuare l'ordine direttamente con la Ditta venditrice.
                            <br/>3.3 Bevande alcoliche, sigarette e altri prodotti relativi al fumo:
                            <br/>3.3.1 L'utente accetta e dichiara che costituisce reato per qualsiasi soggetto di età minore di 18 anni acquistare, o tentare di acquistare, bevande alcoliche o per qualsiasi soggetto acquistare, o tentare di acquistare, bevande alcoliche, tabacco o altri prodotti relativi al fumo per conto di un soggetto minore di 18 anni.
                            <br/>3.3.2 Qualora l'Ordine dell'utente includa qualsiasi bevanda alcolica, sigarette o altro prodotto relativo al fumo, all'utente sarà richiesto di fornire prova della propria età al ricevimento o alla consegna del suo Ordine. Se l'utente non è in grado di fornire la prova di avere 18 anni o più a discrezione della Ditta venditrice prescelta, o laddove la Ditta venditrice ritenga ragionevolmente che le bevande alcoliche, le sigarette o gli altri prodotti relativi al fumo ordinati dall'utente siano stati acquistati dall'utente per conto di una persona minore di 18 anni, la Ditta venditrice si riserva il diritto di non completare la consegna all'utente delle bevande alcoliche, delle sigarette o degli altri prodotti relativi al fumo.

                            <br/>4. MODALITÀ DI INVIO E DI ELABORAZIONE DELL'ORDINE
                            <br/>4.1. Compilazione dell'Ordine: Dopo aver selezionato i prodotti che intende ordinare dal menu della Ditta prescelta ed aver fornito le informazioni richieste, l'utente ha facoltà di effettuare l'Ordine cliccando sul, o selezionando il, pulsante "paga ora" o "procedi al pagamento" o altro simile pulsante. Si ricorda che è importante controllare tutte le informazioni immesse e correggere eventuali errori prima di cliccare sul pulsante o di selezionarlo poiché, dopo averlo fatto, l'utente concluderà un contratto di vendita con la Ditta venditrice e non sarà più possibile correggere eventuali errori o recedere dall'Ordine (fatto salvo quanto previsto nel paragrafo 4.2 di seguito).
                            <br/>4.2. Modifica o recesso dall'Ordine: Ai sensi dell'art. 59 del Codice del Consumo, il diritto di recesso è escluso laddove si acquistano prodotti lavorati su ordinazione e soggetti a rapido deterioramento. In tal caso, una volta che l'Ordine è stato inoltrato l'utente conclude un contratto di vendita di prodotti con la Ditta venditrice e, pertanto, non può modificare o recedere dall'Ordine e non ha diritto ad alcun rimborso. Tuttavia, ove desideri comunque tentare di modificare o recedere dall'Ordine, l'utente può rivolgersi al Servizio Clienti, con le modalità descritte all'articolo 6.3, il quale potrà tentare di contattare la Ditta venditrice al fine di comunicargli la richiesta dell'utente. L'utente riconosce espressamente che non vi è alcun obbligo né assicurazione, tuttavia, riguardo al fatto che il Servizio Clienti sarà in grado di contattare la Ditta venditrice o che la Ditta venditrice accetterà le richieste di modifica o recesso dell'utente.
                            <br/>4.3. Autorizzazione al pagamento: In caso di mancata autorizzazione relativa ad un qualsiasi pagamento, l'Ordine non viene elaborato o non viene inviato alla Ditta venditrice interessata.
                            <br/>4.4. Elaborazione dell'Ordine e rifiuto di esecuzione degli Ordini da parte della Ditta venditrice: Alla ricezione dell'Ordine, VvFood trasmette lo stesso alla Ditta venditrice interessata e notifica all'utente, tramite e-mail, che l'Ordine è stato ricevuto ed è in corso di elaborazione. Si specifica che qualsiasi pagina di conferma che l'utente possa visualizzare sul Sito e qualsiasi conferma di Ordine che l'utente stesso riceva tramite e-mail confermano ulteriormente la avvenuta conclusione del contratto di vendita di prodotti con una Ditta venditrice, ma ciò non implica necessariamente che i prodotti ordinati saranno consegnati dalla Ditta venditrice. VvFood incoraggia fermamente tutte le Ditte venditrici, per conto delle quali opera come mandatario, a eseguire tutti gli Ordini e a comunicare tempestivamente eventuali rifiuti di esecuzione degli Ordini e comunica all'utente (generalmente tramite e-mail), non appena sia ragionevolmente possibile, ogni eventuale rifiuto di esecuzione dell'Ordine da parte della Ditta venditrice. In ogni caso, VvFood non può escludere che le Ditte venditrici rifiutino di eseguire gli Ordini in qualsiasi momento per il fatto di essere eccessivamente impegnati, a causa delle condizioni atmosferiche o per qualsiasi altra ragione. In tali casi, qualsiasi pagamento già effettuato in relazione all'Ordine sarà restituito all'utente ai sensi del successivo articolo 5.7.
                            <br/>4.5. Consegna dell'Ordine: I tempi stimati per le consegne e i ritiri sono forniti dale Ditte venditrici e sono solo indicativi. VvFood e le Ditte venditrici non garantiscono che gli Ordini verranno consegnati o saranno disponibili per il ritiro entro i tempi stimati.
                            <br/>5. PREZZO E PAGAMENTO
                            <br/>5.1. IVA e costi di consegna: I prezzi dei prodotti sono quelli indicati sul Sito. I prezzi sono comprensivi di IVA ma potrebbero non comprendere i costi di consegna (qualora si scelga la "consegna" in luogo del "ritiro"). Tali costi vengono aggiunti all'importo totale dovuto, se applicabili.
                            <br/>5.2. Errata indicazione del prezzo: Il presente Sito contiene un vasto numero di menu ed è sempre possibile che alcuni di essi possano recare una indicazione inesatta del prezzo. Qualora il prezzo esatto di un Ordine sia più elevato del prezzo indicato sul Sito, VvFood eventualmente potrà contattare l'utente prima della spedizione dell'Ordine. In tal caso, né VvFood né la Ditta venditrice interessato hanno alcun obbligo di assicurare che l'Ordine venga fornito all'utente al prezzo inferiore erroneamente indicato né di rimborsare all'utente la differenza rispetto al prezzo errato. Nel caso in cui il prezzo sia eccessivamente elevato rispetto al prezzo originariamente convenuto e l'utente sia un consumatore, ai sensi del Codice del Consumo l'utente avrà facoltà di recedere dall'Ordine.
                            <br/>5.3. Modalità di pagamento: Il pagamento degli Ordini può essere effettuato mediante una carta di credito/debito o altro strumento di pagamento elettronico tramite il Sito oppure in contanti alla Ditta venditrice al momento della consegna all'utente o del ritiro da parte dell'utente.
                            <br/>5.4. Pagamenti con carta di credito: Se l'utente paga con carta di credito o di debito, può essergli chiesto di mostrare la carta alla Ditta venditrice al momento della consegna o del ritiro come prova dell'identità consentendo al tempo stesso di controllare che la carta corrisponda ai dati della ricevuta relativa all'Ordine. Di tanto in tanto possono verificarsi ritardi nell'elaborazione dei pagamenti e delle transazioni mediante carta; questo può avere come risultato che taluni pagamenti possono impiegare sino a sessanta (60) giorni per essere addebitati sul conto corrente o sulla carta di credito o debito dell'utente.
                            <br/>5.5 Nel caso di pagamento tramite carta di credito o debito o altro strumento di pagamento elettronico, la Ditta venditrice effettuerà un accordo diretto con un gestore di pagamenti on line il quale provvederà a ricevere i pagamenti da parte dell'utente per conto della Ditta venditrice e si obbligherà a ritrasferirli direttamente alla stessa Ditta.
                            <br/>6. SERVIZIO CLIENTI
                            <br/>6.1. Disposizioni Generali: VvFood considera il Servizio Clienti estremamente importante. Nel rispetto dell'articolo 6.5 e dell'articolo 11, il Servizio Clienti cerca dunque di prestare assistenza all'utente, per conto della Ditta venditrice, quando possibile, in caso di problemi con l'Ordine.
                            <br/>6.2. Domande relative all'Ordine: in caso di qualsiasi altro problema con l'Ordine, l'utente può rivolgersi direttamente alla Ditta venditrice oppure al Servizio Clienti e VvFood tenterà di contattare la Ditta venditrice per dare corso alla richiesta dell'utente.
                            <br/>6.3. Modifica o recesso dall'Ordine: Come indicato nel precedente articolo 4.2, ove l'utente desideri modificare o recedere dal proprio Ordine dopo l'invio, l'utente deve contattare direttamente la Ditta venditrice e, in caso di difficoltà, può segnalare l’accaduto al Servizio Clienti VvFood che potrà tentare di entrare in contatto con la Ditta venditrice al fine di comunicare le richieste dell'utente. L'utente riconosce espressamente che non vi è alcun obbligo né assicurazione, tuttavia, riguardo al fatto che il Servizio Clienti VvFood sarà in grado di contattare la Ditta venditrice o che la Ditta venditrice accetterà le richieste di modifica o recesso dell'utente.
                            <br/>6.4. Reclami e feedback da parte degli utenti: Nell'eventualità che l'utente non sia soddisfatto della qualità di un qualsiasi Prodotto o del servizio fornito da una Ditta venditrice, VvFood invita l'utente a far conoscere la propria opinione tramite il Sito, in forma di valutazioni, commenti e recensioni (congiuntamente indicati come le "Recensioni") che riflettano la propria esperienza. Le Recensioni sono una parte importante del processo di controllo qualità di VvFood.
                            <br/>6.5. Indennizzo: Nell'eventualità che l'utente non sia soddisfatto della qualità di un qualsiasi Prodotto o del servizio fornito da una Ditta venditrice e desideri richiedere un rimborso, una riduzione proporzionale del prezzo o qualsiasi altra forma di indennizzo, è tenuto a contattare direttamente la Ditta venditrice al fine di presentare il proprio reclamo e, se del caso, ad osservare le procedure di reclamo previste dalla Ditta venditrice stessa. Qualora l'utente non sia in grado di contattare la Ditta venditrice, o qualora la Ditta venditrice rifiuti di occuparsi del reclamo dell'utente, quest'ultimo può rivolgersi al Servizio Clienti VvFood con le modalità sopra descritte entro 48 ore dall'inoltro dell'Ordine e uno dei Consulenti del Servizio Clienti di VvFood, agendo in qualità di mandatario dell’utente, tenterà di contattare la Ditta venditrice al fine di richiedere un indennizzo per conto dell'utente. Si fa presente, tuttavia, che il contratto per la fornitura e l'acquisto di Prodotti è tra l'utente e la Ditta venditrice alla quale l'utente inoltra l'Ordine. VvFood non ha alcun controllo sule Ditte venditrici né sulla qualità dei Prodotti o sul servizio fornito dalle Ditte venditrici stesse e non è in grado di fornire, né assume alcuna responsabilità o impegno di fornire, alcun indennizzo o risarcimento in favore dell'utente per conto di qualsiasi Ditta venditrice.
                            <br/>7. LICENZA D'USO
                            <br/>7.1. Uso consentito: E' consentito all'utente utilizzare il Sito nonché stampare e scaricare dal Sito estratti del Sito stesso per uso personale non avente finalità commerciali alle seguenti condizioni:
                            <br/>7.1.1. l'utente non deve utilizzare il Sito in modo improprio, ivi incluso mediante tecniche di pirateria informatica (hacking) o di estrazione informatizzata di dati (scraping).
                            <br/>7.1.2. Salvo che sia diversamente previsto, il diritto d'autore e gli altri diritti di proprietà intellettuale relativi al Sito ed al materiale in esso pubblicato (ivi incluso, a titolo esemplificativo e non esaustivo, fotografie ed immagini grafiche) sono di proprietà di VvFood o dei soggetti da cui VvFood ha ottenuto la relativa licenza. Le opere suddette sono protette a livello mondiale in base alle leggi ed ai trattati in materia di diritto d'autore e tutti i diritti sono riservati. Ai fini del presente Sito, è vietato qualsiasi eventuale uso di estratti del presente Sito con modalità diverse da quanto previsto dal presente articolo 7.1.
                            <br/>7.1.3. Non è consentito modificare le copie digitali o cartacee di eventuale materiale stampato in conformità a quanto previsto nell'articolo 7.1 né utilizzare alcun disegno, fotografia o altra immagine grafica, sequenza video o audio separatamente dal testo che l'accompagna.
                            <br/>7.1.4. E' necessario accertarsi che a VvFood venga sempre riconosciuto la qualità di autore del materiale presente sul Sito.
                            <br/>7.1.5. Non è consentito l'utilizzo di materiale presente sul Sito o del Sito stesso per finalità commerciali senza aver ottenuto da VvFood una licenza in tal senso.
                            <br/>7.1.6. Restrizioni d'uso: Salvo per quanto previsto all'articolo 7.1, il presente Sito non può essere utilizzato e nessuna sua parte può essere riprodotta o archiviata in alcun altro sito web né può essere inserito in alcun sistema o servizio, pubblico o privato, per il recupero elettronico delle informazioni senza la preventiva autorizzazione scritta di VvFood.
                            <br/>7.1.7. Riserva di Diritti: I diritti non esplicitamente concessi nel presente Sito sono riservati.
                            <br/>8. ACCESSO AL SITO
                            <br/>8.1. Disponibilità del Sito: Sebbene VvFood si adoperi per far sì che l'accesso al Sito possa di norma avvenire ventiquattro ore su ventiquattro (24h/24), VvFood non assume alcun obbligo a riguardo e non è responsabile nei confronti dell'utente nel caso in cui il Sito, in qualsiasi momento o per periodi di qualsiasi durata, non sia disponibile.
                            <br/>8.2. Sospensione dell'accesso e recesso: L'accesso al presente Sito può essere temporaneamente sospeso in qualsiasi momento, anche senza preavviso, in caso di manutenzione del Sito e/o per motivi che sono fuori dal controllo di VvFood. VvFood e l'utente possono recedere dalle presenti Condizioni del Sito con un preavviso di due settimane (da comunicarsi via email). In caso di recesso, VvFood provvederà a cancellare l'account dell'utente, fatti salvi gli obblighi di legge.
                            <br/>8.3. Sicurezza informatica: Purtroppo, la trasmissione di informazioni via internet non è del tutto sicura. Sebbene VvFood adotti le misure prescritte per legge per la tutela delle informazioni immesse, essa non è in grado di garantire la sicurezza dei dati trasmessi al Sito; la trasmissione viene pertanto effettuata a rischio dell'utente.
                            <br/>9. MATERIALE DEGLI UTENTI E RECENSIONI
                            <br/>9.1. Disposizioni Generali:
                            <br/>9.1.1. Eventuale materiale diverso dalle informazioni di identificazione personale, le quali sono disciplinate dalla Informativa VvFood in materia di Privacy, che venga trasmesso o pubblicato (post) o caricato (upload) dall'utente sul presente Sito (compreso, a titolo esemplificativo e non esaustivo, eventuali Recensioni) si considera non riservato (di seguito il "Materiale degli Utenti"). Con la pubblicazione, l'upload o la trasmissione di Materiale degli Utenti l'utente dichiara e garantisce di avere la titolarità o altrimenti il controllo di tutti i diritti relativi al suddetto Materiale degli Utenti e di essere l'unico soggetto responsabile di tali contenuti. L'utente accetta e conviene che VvFood non ha alcun obbligo (a mero titolo esemplificativo, di controllo o revisione prima o dopo la pubblicazione da parte dell'utente) in relazione al Materiale degli Utenti e concede gratuitamente e in maniera perpetua alla stessa VvFood, o a qualsiasi persona da essa eventualmente incaricata, la licenza di poter liberamente copiare, comunicare, distribuire, incorporare o utilizzare in qualsiasi altro modo il Materiale degli Utenti nonché qualsiasi dato, immagine, suono, testo e quant'altro sia in esso incorporato per qualsivoglia finalità, sia commerciale che non commerciale.
                            <br/>9.1.2. L'utente dichiara e garantisce che il Materiale degli Utenti che l'utente stesso pubblica (post), carica sul Sito (upload) o comunque trasmette al Sito non viola, e non violerà in futuro, alcuna delle limitazioni previste nei successivi articoli 9.2 e 9.3.
                            <br/>9.2. Policy relativa al Materiale degli Utenti: E' vietato pubblicare (post), caricare (upload) o trasmettere al, o dal, Sito Materiale degli Utenti (ivi comprese le Recensioni) che:
                            <br/>9.2.1. sia in violazione di eventuali leggi applicabili a livello locale, nazionale o internazionale;
                            <br/>9.2.2. sia illecito o ingannevole;
                            <br/>9.2.3. equivalga a pubblicità non autorizzata; o
                            <br/>9.2.4. contenga virus o qualsiasi altro programma dannoso.
                            <br/>9.3. Policy relative alle Recensioni degli Utenti: In particolare, a titolo esemplificativo e non esaustivo, è vietato immettere sul Sito Recensioni che:
                            <br/>9.3.1. contengano materiale diffamatorio, osceno o offensivo;
                            <br/>9.3.2. promuovano violenza o discriminazione;
                            <br/>9.3.3. violino diritti di proprietà intellettuale di terzi;
                            <br/>9.3.4. siano in violazione di un eventuale obbligo giuridico nei confronti di soggetti terzi (quale, esemplificativamente, un obbligo di fiducia);
                            <br/>9.3.5. promuovano attività illecite o siano in violazione dei diritti di riservatezza di soggetti terzi;
                            <br/>9.3.6. diano l'impressione di provenire da VvFood; e/o
                            <br/>9.3.7. vengano utilizzati per impersonare altri o travisare la realtà riguardo ad un rapporto di parentela stretta (maternità/paternità) con un'altra persona.
                            <br/>9.4. Rimozione delle Recensioni: L'elenco degli atti vietati di cui ai precedenti articoli 9.2 e 9.3 è esclusivamente esemplificativo e non è esaustivo. VvFood si riserva il diritto (senza assumere a riguardo alcuna obbligazione, se non limitatamente a quanto sia necessario per legge) di procedere su base assolutamente discrezionale alla rimozione o correzione in qualsiasi momento di qualsiasi Recensione o altro Materiale degli Utenti pubblicato (post), caricato (upload) o trasmesso sul Sito che VvFood ritenga violare uno dei divieti di cui ai precedenti articoli 9.2 o 9.3, che sia altrimenti discutibile o possa esporre VvFood o qualsiasi altro soggetto terzo ad un pregiudizio o responsabilità di qualsiasi tipo o per qualsiasi altra ragione.
                            <br/>9.5. Uso delle Recensioni: Le Recensioni e il Materiale degli Utenti contenuti nel Sito hanno finalità esclusivamente informativa e non rappresentano un suggerimento da parte di VvFood. Le Recensioni e il Materiale degli Utenti riflettono le opinioni dei clienti che hanno ordinato tramite il Sito o altri soggetti terzi e qualsiasi dichiarazione, consiglio o opinione fornita da tali soggetti pertiene esclusivamente ad essi. Di conseguenza, nella misura massima consentita dalla legge, VvFood non assume alcuna responsabilità né impegno di risarcimento nei confronti di alcun soggetto in relazione ad alcuna Recensione o altro Materiale degli Utenti, ivi incluso, a titolo esemplificativo e non esaustivo, in relazione a errori, diffamazione, oscenità omissioni o falsità che possano essere riscontrati in tali contenuti.
                            <br/>9.6. Responsabilità dell'utente: L'utente si impegna a risarcire VvFood per qualsiasi perdita, danno o reclamo (e per tutti i costi ad essi correlati) sostenuti dalla stessa VvFood o fatti valere nei suoi confronti da una Ditta venditrice o altro soggetto terzo e che siano derivanti da, o in connessione con, una qualsiasi Recensione o altro Materiale degli Utenti forniti dall'utente in violazione delle dichiarazioni, garanzie, impegni o restrizioni previsti nel presente articolo 9.
                            <br/>9.7. Comunicazioni all'autorità amministrativa o giudiziaria: VvFood fornisce piena collaborazione ad ogni autorità competente che richieda o imponga di rivelare l'identità o la posizione di chiunque pubblichi o trasmetta Recensioni o Materiale degli Utenti in violazione degli articoli 9.2 e 9.3.
                            <br/>10. LINK VERSO E DA ALTRI SITI
                            <br/>10.1. Siti di terzi: Eventuali link verso siti di soggetti terzi presenti sul Sito vengono forniti esclusivamente per comodità dei visitatori e degli utenti. L'utilizzo di tali link comporta l'abbandono del Sito. VvFood non ha esaminato i suddetti siti di soggetti terzi e non ha alcun controllo su di essi o sul relativo contenuto o accessibilità (e non è responsabile degli stessi). VvFood non approva né rilascia garanzia alcuna in relazione ai suddetti siti, al materiale in essi presente o ai risultati derivanti dall'utilizzo dei medesimi. Chiunque decida di accedere ad un sito di soggetti terzi di cui sia presente il link sul Sito lo fa esclusivamente a proprio rischio.
                            <br/>11. ESONERO DA RESPONSABILITÀ
                            <br/>11.1. Informazioni fornite sul Sito: Sebbene VvFood si sforzi di garantire che l'informazione fornita sul Sito sia corretta, essa non promette che tale informazione sia accurata o completa. VvFood può apportare qualsivoglia modifica al materiale presente sul Sito o al funzionamento dello stesso ed ai prezzi in esso descritti, in qualsiasi momento e senza preavviso, comunicando tali modifiche agli utenti nelle medesime modalità con le quali le erano stati comunicati i servizi e i prezzi. Il materiale presente sul Sito potrebbe non essere aggiornato e VvFood non assume alcun impegno di aggiornarlo.
                            <br/>11.2. Informazioni relative alle allergie, informazioni di carattere dietetico e altre informazioni sul menu:
                            <br/>Quando una Ditta venditrice si registra con noi, deve fornirci informazioni aggiornate sul menu. Dopodiché noi includiamo queste informazioni nella pagina a loro dedicata sul Sito Web. Se queste informazioni includono informazioni relative alle allergie e informazioni di carattere dietetico, faremo del nostro meglio per ripubblicare queste informazioni sul Sito Web o sull’app esattamente come appaiono nel menu della Ditta venditrice. Se tu, o qualcuno per il quale stai ordinando, avete dei timori relativamente ad allergie alimentari, intolleranze o altre preferenze alimentari, dovresti sempre contattare direttamente la Ditta venditrice prima di effettuare il tuo ordine. Ti chiediamo, per favore di contattare direttamente la Ditta venditrice. È importante che tu parli direttamente con la Ditta venditrice che preparerà il cibo per assicurarti che il tuo ordine sia indicato alle tue esigenze.
                            <br/>11.3. Azioni ed omissioni della Ditta venditrice: Il contratto per la fornitura e l'acquisto di Prodotti intercorre tra l'utente ed la Ditta venditrice cui l'utente inoltra l'Ordine. VvFood non ha alcun controllo sulle azioni o omissioni di alcuna delle Ditte venditrici. Senza che ciò costituisca un limite alla validità generale di quanto sopra, e fatto salvo il caso di dolo e colpa grave di VvFood, con l'utilizzo del Sito l'utente accetta quanto segue:
                            <br/>11.3.1. VvFood non assume alcun impegno di garantire che la qualità dei Prodotti ordinati da una delle Ditte venditrici risulti soddisfacente o che i Prodotti siano idonei per i fini dell'utente ed espressamente esclude ogni garanzia di questo tipo.
                            <br/>11.3.2. I tempi stimati di consegna e ritiro sono forniti dalle Ditte venditrici e sono esclusivamente indicativi. Non sussiste alcuna garanzia, né da parte di VvFood né delle Ditte venditrici, riguardo al fatto che gli Ordini verranno consegnati o messi a disposizione per essere ritirati entro i tempi stimati.
                            <br/>11.3.3. VvFood incoraggia fermamente tutte le Ditte venditrici, per conto delle quali agisce come mandatario, a eseguire tutti gli Ordini e a comunicare tempestivamente eventuali rifiuti, e notifica all'utente (generalmente tramite e-mail) non appena sia ragionevolmente possibile, se la Ditta venditrice abbia rifiutato di eseguire un Ordine. In ogni caso, VvFood non garantisce che le Ditte venditrici eseguano tutti gli Ordini e non può escludere che le Ditte venditrici rifiutino gli Ordini in qualsiasi momento per il fatto di essere eccessivamente impegnate, se l'utente non fornisce prova dell'età per l'acquisto di bevande alcoliche, sigarette o altri prodotti relativi al fumo quando richiesto, a causa delle condizioni atmosferiche o per qualsiasi altra ragione.
                            <br/>11.3.4. Le precedenti clausole di esonero da responsabilità lasciano impregiudicati i diritti degli utenti previsti dalla legge e/o dal contratto di vendita con le Ditte venditrici nonché la normativa del Codice del Consumo a tutela dei consumatori.
                            <br/>11.4. Esclusione di altre previsioni: VvFood fornisce all'utente accesso al Sito e ne consente l'utilizzo sul presupposto che, nella misura massima consentita dalla legge, resti esclusa ogni e qualsiasi garanzia, dichiarazione, condizione, impegno ed ogni altra clausola in relazione al Sito e all'utilizzo dello stesso da parte dell'utente (ivi inclusa qualsiasi dichiarazione, garanzia, condizione, impegno ed altra previsione che possa altrimenti applicarsi al Sito o all'utilizzo dello stesso da parte dell'utente, o che sia implicitamente resa applicabile o inclusa nelle presenti Condizioni del Sito ai sensi della legge applicabile o altrimenti).
                            <br/>12. RESPONSABILITÀ
                            <br/>12.1. Disposizioni generali: Nulla nel presente Sito esclude o limita la responsabilità di VvFood in caso di morte o lesioni personali derivanti da colpa di VvFood, né la responsabilità di VvFood in caso di dolo o colpa grave, né alcuna ulteriore responsabilità che non possa essere esclusa o limitata ai sensi della normativa vigente. Nulla nelle presenti Condizioni del Sito influisce sui diritti inderogabili che la legge concede agli utenti del Sito quali, a titolo esemplificativo e non esaustivo, i diritti garantiti ai consumatori ai sensi del Codice del Consumo.
                            <br/>12.2. Esclusione di responsabilità: Fatto salvo quanto previsto al precedente articolo 12.1, VvFood non risponde nei confronti degli utenti in nessun caso, né a titolo contrattuale né extracontrattuale (ivi incluso per negligenza), né per violazione di obblighi imposti dalla legge né ad alcun altro titolo, anche se si tratti di circostanza prevedibile, derivante da, o in connessione con il Sito (ivi incluso l'uso, l'impossibilità di utilizzare o gli effetti dell'uso del Sito) in relazione a:
                            <br/>12.2.1. eventuale perdita di profitti, vendite, affari o reddito;
                            <br/>12.2.2. perdita o distruzione di dati, informazioni o software;
                            <br/>12.2.3. perdita di opportunità commerciali;
                            <br/>12.2.4. perdita di risparmi previsti;
                            <br/>12.2.5. perdita di avviamento; o
                            <br/>12.2.6. qualsiasi perdita indiretta o conseguente.
                            <br/>12.3. Limitazione di responsabilità: Ferme restando le previsioni di cui all'articolo 11, all'articolo 12.1 e all'articolo 12.2 e fatta sempre salva la normativa applicabile a tutela dei consumatori, la responsabilità totale di VvFood nei confronti degli utenti in relazione a qualsiasi altra perdita derivante da, o in connessione con il Sito o l'utilizzo dello stesso da parte dell'utente, sia essa a titolo contrattuale, extracontrattuale, per violazione di obblighi previsti dalla legge o a qualsiasi altro titolo, non può superare la somma inferiore tra il doppio del valore dell'Ordine e un importo pari a Euro 100.
                            <br/>12.4. Costi supplementari: L'utente risponde integralmente e in via esclusiva per qualsiasi costo supplementare o correlato in cui possa incorrere durante l'uso del Sito o in conseguenza di esso, ivi incluso, a titolo esemplificativo e non esaustivo, i costi relativi all'assistenza, riparazione o adattamento di eventuali dispositivi, software o dati di proprietà dell'utente o di cui l'utente abbia la proprietà o il possesso per leasing o licenza o che siano ad altro titolo utilizzati dall'utente.
                            <br/>13. RISOLUZIONE
                            <br/>13.1. Cause di risoluzione: ai sensi dell'art. 1456 del Codice Civile, VvFood ha facoltà di risolvere il rapporto contrattuale con l'utente e sospendere il diritto dell'utente di avvalersi del Sito, immediatamente, mediante comunicazione scritta (ivi compreso la comunicazione via e-mail), nel caso in cui VvFood ritenga, a suo esclusivo giudizio:
                            <br/>13.1.1. che l'utente abbia utilizzato il Sito in violazione dell'articolo 7.1 (Licenza d'uso);
                            <br/>13.1.2. che l'utente abbia pubblicato sul Sito Recensioni o altro Materiale degli Utenti che viola le previsioni di cui agli articoli 9.2 o 9.3 (Materiale dei Visitatori e Recensioni);
                            <br/>13.1.3. che l'utente abbia violato l'articolo 10.2 (Link Verso e da Altri Siti); o
                            <br/>13.1.4. che l'utente abbia omesso di effettuare il pagamento di un Ordine i cui prodotti siano stati consegnati dalla Ditta venditrice o abbia violato qualsiasi altra previsione essenziale delle presente Condizioni del Sito.
                            <br/>13.2. Obblighi dell'utente in caso di risoluzione: All'atto della risoluzione o sospensione l'utente è tenuto a distruggere immediatamente qualsiasi estratto del Sito che sia stato eventualmente scaricato o stampato.
                            <br/>14. COMUNICAZIONI SCRITTE
                            <br/>14.1. La normativa vigente può richiedere che alcune informazioni ed avvisi, tra quelli che VvFood fornisce all'utente, debbano essere comunicati per iscritto. Con l'utilizzo del Sito o l'effettuazione di Ordine di Prodotti tramite il Sito, l'utente accetta che la comunicazione con VvFood avvenga principalmente per via elettronica. In particolare, l'utente accetta di poter essere contattato tramite e-mail o che gli vengono fornite informazioni tramite pubblicazione di post di avviso sul Sito. Per le finalità contrattuali, l'utente accetta tali modalità elettroniche di comunicazione. La presente clausola non inficia i diritti che la legge prevede in favore degli utenti del Sito.
                            <br/>15. EVENTI NON CONTROLLABILI DA VvFood
                            <br/>15.1. VvFood non risponde di, e non è tenuta a risarcire, alcun eventuale inadempimento o adempimento tardivo di proprie obbligazioni contrattuali previste dalle presenti Condizioni del Sito che sia provocato da eventi al di fuori della ragionevole possibilità di controllo da parte di VvFood ("Eventi di Forza Maggiore").
                            <br/>15.2. Un Evento di Forza Maggiore include qualsiasi atto, evento, mancato avveramento, omissione o incidente che vada oltre le ragionevoli possibilità di controllo da parte di VvFood ed include in particolare (a titolo esemplificativo e non esaustivo) quanto segue:
                            <br/>15.2.1. scioperi, serrate o altre azioni nell'ambito di rapporti tra dipendenti e aziende;
                            <br/>15.2.2. tumulti civili, sommosse, invasioni, attacchi terroristici o minacce di attacco terroristico, guerre (anche in assenza di apposita dichiarazione) o minacce di guerra nonché preparativi per la guerra;
                            <br/>15.2.3. incendi, esplosioni, tempeste, inondazioni, terremoti, cedimenti del terreno (inclusa la subsidenza), epidemie, pandemie o altri disastri naturali;
                            <br/>15.2.4. impossibilità di servirsi della rete ferroviaria, navale, aerea, del trasporto su gomma o di altri mezzi di trasporto pubblico o privato;
                            <br/>15.2.5. impossibilità di servirsi delle reti di comunicazione pubblica o privata; e
                            <br/>15.2.6. leggi, decreti, normativa, regolamentazione o disciplina restrittiva di un qualsiasi governo.
                            <br/>15.3. L'esecuzione da parte di VvFood delle proprie obbligazioni contrattuali previste dalle presenti Condizioni del Sito si considera sospesa per tutta la durata dell'Evento di Forza Maggiore e VvFood usufruisce di una proroga della durata prevista per l'adempimento pari alla durata del suddetto periodo. VvFood si adopera con ogni sforzo ragionevole per giungere al termine dell'Evento di Forza Maggiore o trovare una soluzione per cui le obbligazioni contrattuali di VvFood possano essere adempiute nonostante l'Evento di Forza Maggiore.
                            <br/>16. CLAUSOLE VARIE ED ULTERIORI
                            <br/>16.1. Informativa in materia di Privacy: VvFood si obbliga a proteggere la riservatezza e sicurezza degli utenti. Tutti i dati personali degli utenti raccolti da VvFood vengono elaborati in conformità alla Informativa di VvFood in materia di Privacy. L'utente del Sito è tenuto ad esaminare la Informativa in materia di Privacy, la quale costituisce, in forza del presente richiamo, parte integrante delle Condizioni to del Sito ed è disponibile al seguente <a className="link" href="/privacy">link</a>.                               .
                            <br/>16.2. Disciplina di aspetti ulteriori: Inoltre, si raccomanda agli utenti e visitatori del Sito di esaminare tutta la documentazione legale presente sul Sito.
                            <br/>16.3. Invalidità parziale: Qualora uno o più termini o condizioni delle presenti Condizioni del Sito vengano dichiarati invalidi, nulli o inefficaci, in tutto o per una parte qualsiasi, l'invalidità, la nullità o l'inefficacia riguarderà solo tali termini, condizioni o previsioni ed i restanti termini resteranno validi ed efficaci nella misura massima consentita dalla legge.
                            <br/>16.4. Unicità dell'accordo: le presenti Condizioni del Sito ed ogni altro documento in esse esplicitamente richiamato costituisce l'intero accordo tra VvFood e l'utente e sostituisce tutte le precedenti discussioni, la corrispondenza, le trattative, qualsiasi accordo precedente, intesa o contratto tra le parti in relazione all'oggetto del contratto.
                            <br/>16.5. Rinunce: L'eventuale mancata o tardiva attuazione (in tutto o in parte) di una qualsiasi previsione delle presenti Condizioni del Sito non può essere interpretata come rinuncia dell'una o dell'altra parte a far valere i propri diritti o rimedi.
                            <br/>16.6. Cessione: L'utente del Sito non può cedere alcun diritto o obbligo previsto dalle presenti Condizioni del Sito senza il preventivo consenso scritto di VvFood, neanche in caso di cessione di azienda o di ramo d'azienda. VvFood può cedere qualsiasi diritto o obbligo previsto dalle presenti Condizioni del Sito ad una società sua affiliata o ad una qualsiasi impresa con cui essa si associ per svolgere l'attività imprenditoriale o che sia semplicemente cessionaria di tutti o parte dei diritti di VvFood, che essa acquisisca o cui essa sia venduta, anche in caso di cessione di azienda o di ramo d'azienda e purché a seguito della cessione non risulti diminuita la tutela dei diritti dei consumatori.
                            <br/>16.7. Rubriche: Le rubriche degli articoli del presente Regolamento del Sito sono previste esclusivamente per comodità e non influiscono in alcun modo sulla loro interpretazione.
                            <br/>17. LEGGE APPLICABILE E FORO COMPETENTE
                            <br/>17.1 Legge Applicabile: tutti i reclami e le controversie che dovessero sorgere in relazione a o ai sensi delle presenti Condizioni del Sito saranno regolati e interpretate ai sensi della legge italiana. Per i consumatori residenti in Italia, troverà applicazione la normativa inderogabile a tutela del consumatore prevista dalla legge italiana (Codice del Consumo).
                            <br/>17.2 Foro competente: qualsiasi controversia derivante dalle presenti Condizioni del Sito riguardante utenti non consumatori, sarà devoluta alla competenza esclusiva del foro di Roma. In caso di controversia derivante dalle presenti Condizioni del Sito riguardante utenti consumatori, i consumatori potranno adire, a seconda del valore della controversia, il Giudice di Pace o il Tribunale Civile di Roma ovvero il Giudice di Pace o il Tribunale del luogo dove hanno la loro residenza o domicilio in Italia. VvFood e le Ditte venditrici potranno instaurare una causa nei confronti di un consumatore residente in Italia solamente presso il Foro di residenza o domicilio del consumatore. In caso di controversia, l'utente è incoraggiato a contattare in primo luogo il Servizio Clienti per trovare una soluzione.
                            <br/>17.3 Risoluzione delle controversie online: Alternativamente alla risoluzione giudiziale delle controversie, il consumatore può inoltrare il suo reclamo presso la Piattaforma di Risoluzione Online per la risoluzione delle Controversie in via stragiudiziale (Piattaforma ODR Europea) (Art. 14, par. 1 del Regolamento UE 524/2013). Per ulteriori informazioni in merito alle Piattaforma ODR Europea o per inoltrare un reclamo e iniziare procedure alternative per controversie relative al presente contratto, è possibile utilizzare il seguente link: https://ec.europa.eu/odr. L'indirizzo email di VvFood da comunicare alla Piattaforma ODR Europea è il seguente: vvfoodsrl@pec.it
                            <br/>* L'utente, ai sensi e per gli effetti degli artt. 1341 e 1342 del Codice Civile, approva espressamente i seguenti articoli: Art. 4.2 (Modifica o recesso dall'Ordine); Art. 4.4 (Elaborazione dell'Ordine e rifiuto di esecuzione degli Ordini da parte della Ditta venditrice); 5.5 (Buoni di accredito e di sconto); 5.6 (Ordini respinti); 6.5 (Indennizzo); 8.2 (Sospensione dell'accesso e recesso); 11 (Esonero da Responsabilità); 12.2 (Esclusione di responsabilità); 12.3 (Limitazione di responsabilità); 16.6 (Cessione); 17 (Legge Applicabile e Foro Competente).
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default TermsAndConditions;