import React from "react";
import {Container} from "react-bootstrap";

const CookiesPolicyEn = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold', textTransform: 'uppercase' }}>Cookies Policy</p>
                    <div className="privacySection" >
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>1. FUNCTIONS OF COOKIES</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            VvFood Italy Srl, Via Gocciadoro, 4, 38122 Trento (di seguito "VvFood") utilizza i cookie per migliorare la qualità del sito VvFood.it (di seguito "Sito") e del relativo servizio, e per cercare di rendere significativa l'esperienza di navigazione dell'utente. Quando l'utente accede al Sito il server web VvFood invia un cookie al suo computer; ciò permette a VvFood di riconoscere il computer, ma non la persona che lo sta usando. Associando i numeri di identificazione contenuti nei cookie ad altre informazioni dei clienti, ad esempio, quando l'utente accede al Sito, VvFood può però sapere che le informazioni dei cookie si riferiscono a quell'utente. Proseguendo oltre questa pagina e navigando sul Sito, ad esempio accedendo ad un'area del Sito o cliccando su un link o immagine del Sito stesso, l'utente acconsente all'utilizzo dei cookie con le modalità definite da VvFood e dichiara di aver compreso la presente Cookie Policy che descrive come l'utente può gestire le proprie scelte e preferenze circa l'utilizzo dei cookie.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COSA SONO I COOKIE?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            I cookie sono stringhe di testo di piccole dimensioni inviate al computer dell'utente quando visita un sito web che memorizzano, e talvolta tengono traccia di, informazioni relative all'utilizzo del Sito da parte dell'utente. Alcuni cookie utilizzati da VvFood hanno una durata limitata alla sola sessione di navigazione e scadono quando si chiude il browser. Altri cookie vengono utilizzati solo se l'utente chiede a VvFood di ricordare la password per la volta successiva in cui ritorna al Sito, o per capire le sue preferenze online al fine di migliorare i servizi VvFood e/o l'esperienza di navigazione dell'utente; tali cookie hanno una durata più lunga.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>PERCHÈ VvFood UTILIZZA I COOKIE?</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            I cookie utilizzati sul Sito sono quelli di seguito illustrati.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COOKIE TECNICI</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Per "cookie tecnici" si intendono, secondo la catalogazione fornita dal Garante per la protezione dei dati personali nel provvedimento generale n. 229 dell'8 maggio 2014 recante "Individuazione delle modalità semplificate per l'informativa e l'acquisizione del consenso per l'uso dei cookie", i cookie di navigazione o di sessione, i cookie analitici (analytics) e i cookie di funzionalità. L'utilizzo di tali cookie non richiede il consenso dell'utente. I cookie tecnici utilizzati da VvFood sono di seguito elencati.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>1. COOKIE DI NAVIGAZIONE O DI SESSIONE</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Questi cookie di sessione sono necessari per consentire all'utente di utilizzare le funzionalità essenziali del Sito, permettendo, ad esempio, di ordinare in modo più semplice la consegna del prodotto. Le funzionalità essenziali del Sito necessitano di cookie strettamente tecnici per ricordare, mentre l'utente ordina altri prodotti sul Sito e/o visita altre pagine del Sito nel corso della stessa sessione di navigazione, il codice di avviamento postale dell'utente e i prodotti inseriti nel carrello o le note fornite dall'utente alla Ditta venditrice. Questi cookie consentono anche a VvFood di capire se l'utente si è autenticato come utente registrato sul Sito e di garantirgli la possibilità di utilizzare le funzionalità appropriate sul Sito. Se sceglie di disattivare questi cookie l'utente potrà soltanto navigare sul Sito ma non potrà ordinare prodotti. VvFood si avvale anche di un cookie per tenere traccia del fatto che l'utente è stato informato da VvFood in merito all'utilizzo dei cookie e che ha acconsentito, eventualmente, a tale utilizzo.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>2. COOKIE ANALITICI</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Questi cookie sono conosciuti anche come cookie per l'analisi delle prestazioni e servono per raccogliere informazioni sulle modalità di utilizzo del Sito da parte degli utenti, affinché VvFood possa migliorare la qualità del Sito e del servizio. I cookie analitici utilizzati da VvFood non raccolgono informazioni che potrebbero identificare l'utente. Tutte le informazioni raccolte da questi cookie sono informazioni aggregate e anonime utilizzate da VvFood per:
                            <ul>
                                <li>fornire statistiche su come viene utilizzato il Sito;</li>
                                <li>conoscere tramite quale sito web l'utente è arrivato al Sito; e</li>
                                <li>aiutare VvFood a migliorare il Sito misurando le informazioni sull'utilizzo del Sito e gli eventuali errori che si verificano quando gli utenti vi navigano.</li>
                            </ul>
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>3. COOKIE DI FUNZIONALITÀ</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            I cookie di funzionalità forniscono una migliore funzionalità del Sito, consentendo l'accesso a servizi aggiuntivi o migliorando le successive visite al Sito. I cookie di funzionalità utilizzati sul Sito consentono all'utente di avvalersi di alcune funzionalità avanzate del Sito durante la navigazione su di esso: ad esempio, se l'utente ha bisogno di aiuto e assistenza per un ordine takeaway, può accedere al servizio chat di help desk online fornito da VvFood tramite operatori. Questi cookie possono condividere alcune informazioni con partner di VvFood per fornire i servizi disponibili sul Sito; le informazioni così condivise saranno utilizzate esclusivamente per la prestazione di quel servizio, prodotto o funzione specifica e non saranno utilizzare per nessuna altra finalità.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COOKIE DI PROFILAZIONE</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            I cookie di profilazione (noti anche come cookie per il targeting e la condivisione) sono cookie che offrono all'utente un'esperienza di navigazione personalizzata, offrendo servizi che si basano sugli interessi manifestati dall'utente nell'ambito della sua navigazione sul Sito ma anche su altri siti web. I cookie che personalizzano l'esperienza di navigazione dell'utente sul Sito - in base alle preferenze, gli ordini, il comportamento online e l'interazione dell'utente con VvFood e altri siti web - raccolgono informazioni associate solo all'indirizzo IP utilizzato dall'utente. Alcune di queste esperienze di navigazione personalizzate possono essere collegate a servizi offerti da soggetti terzi per tenere traccia del fatto che l'utente ha visitato il Sito e mostrare, ad esempio, messaggi personalizzati in linea con gli interessi dell'utente. Queste informazioni vengono utilizzate per informare l'utente circa prodotti e/o servizi che possono essere di suo interesse, ivi inclusi servizi forniti da partner di VvFood. I cookie di profilazione possono anche essere collegati a social network quali Facebook o Twitter così da rendere disponibili ad agenzie di pubblicità informazioni riguardanti la visita dell'utente sul Sito e consentire loro di presentare all'utente annunci in linea con i suoi interessi.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>MODALITÀ DI GESTIONE DA PARTE DELL'UTENTE DELLE SUE PREFERENZE SUI COOKIE</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Alcuni dei cookie utilizzati sul Sito sono erogati da VvFood, mentre altri sono inviati da terze parti che prestano servizi per conto di VvFood. È possibile gestire in qualsiasi momento le proprie preferenze in merito ai cookie di profilazione comunicandoci le proprie scelte. È inoltre possibile gestire le preferenze in merito ai cookie utilizzando il web browser. La maggior parte dei browser accetta automaticamente i cookie, ma, se si preferisce, è possibile modificare il browser per impedire che sia installato il cookie, o per informarvi ogni volta un cookie viene inviato. Si precisa, tuttavia, che bloccando o cancellando i cookie tecnici utilizzati su questo Sito, non sarà possibile sfruttare appieno il Sito. Ulteriori dettagli circa le modalità di utilizzo da parte di VvFood dei dati personali forniti dall'utente sono disponibili all'interno della <a className="link" href="/privacy">Privacy Policy</a> di VvFood, restando tuttavia inteso che non tutte le informazioni raccolte tramite i cookie possono consentire l'identificazione dell'utente. Siamo continuamente impegnati a sviluppare migliori metodi di gestione delle preferenze dei cookie. In considerazione del continuo progredire delle nuove tecnologie e delle soluzioni tecniche disponibili VvFood si riserva la facoltà di modificare la presente Cookie Policy al fine di riflettere tali progressi tecnologici e strumenti di gestione delle preferenze.
                        </p>
                    </div>
                    <div style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COME CONTATTARE VvFood</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Per sapere di più sui cookie, per qualsiasi domanda o per inviare suggerimenti, si prega di
                            contattare VvFood ai seguenti recapiti: VvFood S.r.l., Via Gocciadoro, 4, 38122 Trento
                            (Italia), <a className="link" href="mailto:cookie@vvfood.it">cookie@vvfood.it</a>. Ai fini
                            della presente
                            Cookie Policy VvFood S.r.l. è il soggetto titolare del trattamento. VvFood Italy S.r.l. ha
                            nominato quale Responsabile del Trattamento l’Amministratore unico che potrà essere
                            contattato all’indirizzo <a className="link" href="mailto:cookie@vvfood.it">cookie@vvfood.it</a>.
                            Si prega inoltre di leggere la <a className="link" href="/privacy">Privacy Policy</a> per
                            ulteriori informazioni.
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default CookiesPolicyEn;