import React from "react";
import i18next from "i18next";
import BecomePartnersEn from "./BecomePartnersEn";
import BecomePartnersIt from "./BecomePartnersIt";

const BecomePartners = props => {
    switch(i18next.language) {
        case 'en':
            return <BecomePartnersEn/>;
        case 'it':
            return <BecomePartnersIt/>;
        default:
            return <BecomePartnersIt/>;
    }
}

export default BecomePartners;