import React from "react";
import i18next from "i18next";
import ContactEn from "./ContactEn";
import ContactIt from "./ContactIt";

const Contact = props => {
    switch(i18next.language) {
        case 'en':
            return <ContactEn/>;
        case 'it':
            return <ContactIt/>;
        default:
            return <ContactIt/>;
    }
}

export default Contact;