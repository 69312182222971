import {combineReducers} from 'redux';
import ResetPasswordReducer from './ResetPasswordReducer';
import RestaurantsReducer from "./RestaurantsReducer";
import RestaurantDetailsReducer from "./RestaurantDetailsReducer";
import RestaurantMenusReducer from "./RestaurantMenusReducer";
import SelectRestaurantReducer from "./SelectRestaurantReducer";
import DishReducer from './DishReducer';
import CartModalReducer from './CartModalReducer';
import CartReducer from './CartReducer';
import GoToCheckoutModalReducer from './GoToCheckoutModalReducer';
import { reducer as reduxFormReducer } from 'redux-form';
import UserReducer from './UserReducer';
import UserModalReducer from './UserModalReducer';
import TagsReducer from './TagsReducer';
import RestaurantClosedModalReducer from "./RestaurantClosedModalReducer";
import FilterReducer from "./FilterReducer";
import CheckoutReducer from "./CheckoutReducer";
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'

/**
 * Configuration for cart information that should be saved on session storage.
 */
const cartConfig = {
    key: 'cartInformation',
    storage: storageSession,
    whitelist: ['cartContent', 'selHour','selMinute','selDeliveryHour','selDeliveryMinute','isMinOrderErrVisible', 'orderType','deliveryOpen','deliveryAddress','deliveryAvailable','selectedRestaurant','note','deliveryPrice']
};

/**
 * Configuration for cart modal information that should be saved on session storage.
 */
const cartModalConfig = {
    key: 'cartModalInformation',
    storage: storageSession,
    whitelist: ['quantity','checkedItems','totalPrice','dish','cartIndex']
};

/**
 * Configuration for checkout modal information that should be saved on session storage.
 */
const toCheckoutModalConfig = {
    key: 'toCheckoutModalInformation',
    storage: storageSession,
    whitelist: ['selectedRestaurant']
};

/**
 * Configuration for checkout information that should be saved on session storage.
 */
const checkoutConfig = {
    key: 'checkoutInformation',
    storage: storageSession,
    whitelist: ['restaurantId', 'clientSecret']
};

/**
 * Configuration for selected restaurant information that should be saved on session storage.
 */
const SelectedRestaurantConfig = {
    key: 'selRestaurantInformation',
    storage: storageSession,
    whitelist: ['stripeAccountId']
};

/**
 * Combines reducers.
 */
export default combineReducers({
    passwordReset: ResetPasswordReducer,
    restaurants: RestaurantsReducer,
    selectedRestaurant: persistReducer(SelectedRestaurantConfig, RestaurantDetailsReducer),
    selectedDish: DishReducer,
    selectedRestaurantId: SelectRestaurantReducer,
    restaurantMenus: RestaurantMenusReducer,
    cartModal: persistReducer(cartModalConfig, CartModalReducer),
    cart: persistReducer(cartConfig, CartReducer),
    form: reduxFormReducer,
    userData: UserReducer,
    tags: TagsReducer,
    restaurantClosedModal: RestaurantClosedModalReducer,
    userModal: UserModalReducer,
    filters: FilterReducer,
    goToCheckoutModal: persistReducer(toCheckoutModalConfig, GoToCheckoutModalReducer),
    checkout: persistReducer(checkoutConfig, CheckoutReducer)
});
