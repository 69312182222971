import React from 'react';
import UserIcon from './../assets/image/userIcon.svg'
import UserIconInverted from './../assets/image/userIconInverted.svg'
import AuthService from './../services/AuthService'
import { fetchUserData, setUserModalVisibility } from '../actions'
import { connect } from 'react-redux'
import { Translation } from "react-i18next";
import {Link} from "react-router-dom";

/**
 * user actions popup component
 */
class UserActionsPopup extends React.Component {

    constructor() {
        super()
        this.state = {isModal: false}
    }

    async componentDidMount() {
        let currentUserId = AuthService.getCurrentUserId();
        if (currentUserId) {
            await this.props.fetchUserData(currentUserId);
        }
    }

    /**
     * Function to scroll to restaurants section when cart is empty
     */
    async  scrollToRestaurants() {
        if(this.props.cart.cartContent.length === 0) {
            document.getElementById('drop-down-menu').style.cssText = 'display:none;';
            setTimeout(function(){
                    var elmnt = document.getElementById('order-now');
                    elmnt.scrollIntoView();
            }, 5);
        }
    }

    renderModal = () => {
        return (
            <Translation ns="header">
            { t =>
                <div className={this.props.userModal.isVisible ? 'user-modal' : 'user-modal-hidden'}>
                    <div>
                        <img style={{width: 40, marginBottom: 20}} src={UserIconInverted}/>
                    </div>
                    <div onClick={() => this.props.setUserModalVisibility(false)} style={{fontFamily: 'MontserratMedium', fontSize: 18, position: 'absolute', right: 0, top: 0, marginRight: 25, marginTop: 10, cursor: 'pointer'}}>
                        X
                    </div>
                    <div style={{fontWeight: 'bold'}}>{this.props.userData.name} {this.props.userData.lastName}</div>
                    <div className='popup-item'>
                        <Link onClick={() => this.props.setUserModalVisibility(false)} style={{color: '#000000'}} to="/profile">-{t('popup.info')}</Link>
                    </div>
                    <Link onClick={() => {this.props.setUserModalVisibility(false); this.scrollToRestaurants();}} to = {
                            this.props.cart.cartContent.length > 0 ?
                            "/restaurant/" + this.props.restaurant :
                            '/'
                        } style={{color:'black'}}>
                        <div className='popup-item'>-{t('popup.cart')}{this.props.cart.cartContent.length > 0 ? " (" + this.props.cart.cartContent.length + ")" : null}</div>
                    </Link>
                    <div className='popup-item' onClick={() => {AuthService.logout(); window.location.href = '/'}}>-{t('popup.exit')}</div>
                </div>
            }
            </Translation>
        )
    }

    render() {
      return (
          <div className="itemLoginRegister" style={{cursor: 'default'}}>
              { this.props.isModal ? null : <img src={UserIcon} style={{cursor: 'pointer', fontFamily: 'MontserratMedium', width: 40, marginRight: 5}} onClick={() => this.props.setUserModalVisibility(!this.props.userModal.isVisible) }/>}
              { this.props.isModal ? null : <span style={{cursor: 'pointer'} } onClick={() => this.props.setUserModalVisibility(!this.props.userModal.isVisible) }>{this.props.userData.name}</span>}
              { this.props.isModal ? this.renderModal() : null}
          </div>
      );
    }
}

/**,
 * Maps state to props.
 * @param state the state.
 */
const mapStateToProps = (state) => {
    return {
        userData: state.userData,
        cart: state.cart,
        restaurant: state.goToCheckoutModal.selectedRestaurant,
        userModal: state.userModal
    };
};

export default connect(mapStateToProps, { fetchUserData, setUserModalVisibility })(UserActionsPopup);
