import axios from "axios";
import { configData } from '../configData/configData'
import jwt_decode from "jwt-decode";

/**
 * Class for manage user login.
 */
class AuthService {
   
   /**
    * Function for user login.
    * @param {*} email 
    * @param {*} password 
    */
   login(email, password) {
      return axios
         .post(configData.API_BASE+'/users/authenticate', {
            email: email,
            password: password
         })
         .then((response) => {
            if (response.data) {
               localStorage.setItem("userToken", JSON.stringify(response.data));
            }
            return response.data;
         });
   }

   logout() {
      localStorage.clear();
      sessionStorage.clear()
   }

   /**
    * Function for check if there is the id of autenticated user.
    */
   getCurrentUserId() {
      let userToken = localStorage.getItem('userToken');
      return userToken ? (jwt_decode(userToken).userId) : null;
   }

   /**
    * Function for retrieve information from the token.
    * @param {*} token 
    */
   decodeJWT(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
   }
}

export default new AuthService();