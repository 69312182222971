import React from "react";
import {Container} from "react-bootstrap";

const BecomePartnersEn = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold', textTransform: 'uppercase' }}>A NEW STYLE OF RESTAURANTS</p>
                    <div className="privacySection" >
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>WHAT IS VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Vvfood is an innovative food-delivery platform launched to provide restaurants with an easy tool to manage delivery and pickup services. The platform gives restaurants the chance to process food orders and pickup reservations using their own delivery system, offering a better service and saving costs.
                        </p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            If you are a restaurant owner interested in joining us please email&nbsp;
                            <a className="link" href="mailto:info@vvfood.it">info@vvfood.it</a>.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>WHY CHOOSING VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            <ol>
                                <li>Connect with your customers by personalizing your offer</li>
                                <li>Save delivery costs</li>
                                <li>No entry or subscription fee</li>
                                <li>Share your mission with customers, using interactive instruments on your restaurant page (photos, videos, tutorials, etc.)</li>
                                <li>All in one solution to help grow your business with food ordering and pickup</li>
                            </ol>
                        </p>
                    </div>
                    <div style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>LOW COST HIGH PROFIT</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Our mission is to create a virtual place where restaurants can talk about themselves and share their food-philosophy, using their own delivery system, in order to improve the service they offer without sacrificing their profit margin.
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default BecomePartnersEn;