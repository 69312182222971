/**
 * Utility class.
 */
class Utility {
    /**
     * //TODO change this when obtain an image url from s3 bucket.
     * Gets whole url of the image by adding prefix.
     * @param url url of the image.
     * @returns {{uri: string}} full url.
     */
    static getImage(url) {
        return { uri: "https://www.vvfood.it/" + url };
    }

    /**
     * Gets address without city.
     * @param address full address.
     * @returns {string} address without city.
     */
    static getStreet(address) {
        let addressArray = address.split(',');
        addressArray.pop();
        return addressArray.join();
    }

    /**
     * Gets city from address.
     * @param address full address.
     * @returns {string} city.
     */
    static getCity(address) {
        const addressArray = address.split(',');
        return addressArray[addressArray.length - 1].trim();
    }

    /**
     * Gets number of the week day.
     * @returns {number} the number.
     */
    static getDayNumOfWeek() {
        let dayNumOfWeek = new Date().getDay(); // zero is sunday.
        if (dayNumOfWeek === 0) {
            return 6;
        } else {
            return dayNumOfWeek - 1;
        }
    }

    /**
     * Gets day name.
     * @returns {name} the name.
     */
    static getDayName(index) {
        var days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];


        return days[index];

    }

    /**
     * Validates the email.
     * @param value the email value.
     * @returns {*}
     */
    static validateEmail = (value) =>
        value &&
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
            value,
        );

    /**
     * Validates the postal code.
     * @param value the postal code.
     * @returns {*}
     */
    static validatePostalCode = (value) => value && /\d{5}/.test(value);

    /**
     * Validates the company code.
     * @param value the company code.
     * @returns {*}
     */
    static validateCompanyCode = (value) => value && /\d{11}/.test(value);

    /**
     * Validates the phone number.
     * @param value the phone number.
     * @returns {*}
     */
    static validatePhoneNumber = (value) => value && /^\d{8,10}$/.test(value);

    /**
     * Parse iso date to string in format YYYY-mm-dd.
     * @param dtstr iso date.
     * @returns {string|null}
     */
    static parseIsoDate = (dtstr) => {
        var dt = dtstr.split(/[: T-]/).map(parseFloat);
        var year = dt[0];
        var month = dt[1];
        var day = dt[2];
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        if (dt.length > 0) {
            return year+'-'+month+'-'+day;
        }
        return null;
    };

    /**
     * Parse iso date into date time string.
     * @param dtstr iso date.
     * @returns {string|null}
     */
    static parseIsoDatetime = (dtstr) => {
        var dt = dtstr.split(/[: T-]/).map(parseFloat);
        var year = dt[0];
        var month = dt[1];
        var day = dt[2];
        var hour = dt[3];
        var min = dt[4];
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }
        if (hour < 10) {
            hour = '0' + hour;
        }
        if (min < 10) {
            min = '0' + min;
        }
        if (dt.length > 0) {
            return year+'-'+month+'-'+day+' '+hour+':'+min;
        }
        return null;
    };

    /**
     * Formats pickup delivery time.
     * Removes the seconds from end of it.
     * @param timeToFormat time to format.
     * @returns {string} formatted string.
     */
    static formatPickupDeliveryTime = (timeToFormat) => {
        var time = timeToFormat.split(':');
        return time[0]+':'+time[1];
    };

    /**
     * Formats addons of a dish.
     * Adds comma between them.
     * @param dish the dish.
     * @returns {string} the formatted addons.
     */
    static formatAddonsOfDish = (dish) => {
        let dishesArr = [];
        dish.addons.map(addon => {
            return (
                dishesArr.push(addon.dishAddonName)
            );
        });
        return dishesArr.join(', ');
    };

}

export default Utility;
