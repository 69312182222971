import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import RestaurantCard from './RestaurantCard';
import { connect } from 'react-redux';
import { fetchRestaurantsByAddress } from '../actions';

/**
 * Restaurant list component
 */
class RestaurantList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        await this.props.fetchRestaurantsByAddress(this.props.restaurants.address);
    }

    /**
    * create and align cards filtered
    * @return list of cards
    */
    restaurantCardAlignedList = () => {
        let availableRestaurants = this.props.restaurants.restaurantsByAddress;
        let colors = ['(240 ,179 ,54 , 0.8)', '(91, 127, 27 , 0.8)'];
        let textColors = ['#414042', '#FFFFFF'];

        let resList = [];
        let colorIndex = 0;
        let textColorIndex = 0;
        for (let i = 0; i < availableRestaurants.length; i += 2) {
            let children = [];
            for (let j = i; j < i + 2 && j < availableRestaurants.length; j++) {
                children.push(<Col md={6}><RestaurantCard colorInfo={colors[colorIndex]} textColorInfo={textColors[textColorIndex]} resource={availableRestaurants[j]} /></Col>);
            }
            resList.push(<Row>{children}</Row>)
            colorIndex = (colorIndex + 1) % colors.length;
            textColorIndex = (textColorIndex + 1) % textColors.length;
        }
        return resList;
    }

    render() {
        return (
            <Container fluid className='pageContainer'>
                <Row style={{paddingLeft:'15px', paddingRight:'15px'}}>
                    <Col md={8} className="restaurantListCol">
                        {this.restaurantCardAlignedList()}
                    </Col>
                    <Col md={4}>
                        <Container className='yellowPlaceholder'></Container>
                    </Col>
                </Row>
            </Container>
        );
    }
}

/**,
 * Maps state to props.
 * @param state the state.
 * @returns {{restaurants: *}}
 */
const mapStateToProps = (state) => {
    return {
        restaurants: state.restaurants
    };
};

// export default RestaurantList;
export default connect(mapStateToProps, {fetchRestaurantsByAddress})(RestaurantList);
