import {
    FETCH_RESTAURANT
} from '../actions/ActionTypes';

/**
 * Restaurant details reducer.
 * @param state
 * @param action action.
 */
export default (state = {}, action) => {
  if (action.type === FETCH_RESTAURANT) {
      return action.payload;
  } else {
      return state;
  }
};