import {
    SHOW_MODAL_CART,
    HIDE_MODAL_CART,
    CHANGE_QUANTITY,
    CHANGE_CHECK,
    CALCULATE_TOTAL_PRICE,
    SET_DISH,
    SET_CART_INDEX
} from '../actions/ActionTypes';

/**
 * Initial state.
 * @type {{cartModalIsOpen: boolean}}
 */
const initialState = {
    cartModalIsOpen: false,
    quantity: 1,
    checkedItems: new Array(),
    totalPrice: 0,
    dish: null,
    cartIndex: 0
};

/**
 * Modal reducer.
 * @param state state.
 * @param action action.
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL_CART:
            return {
                cartModalIsOpen: action.payload,
                quantity: state.quantity,
                checkedItems: state.checkedItems,
                totalPrice: state.totalPrice,
                dish: state.dish,
                cartIndex: state.cartIndex
            };
        case HIDE_MODAL_CART:
            return {
                cartModalIsOpen: action.payload,
                quantity: state.quantity,
                checkedItems: state.checkedItems,
                totalPrice: state.totalPrice,
                dish: state.dish,
                cartIndex: state.cartIndex

            };
        case CHANGE_QUANTITY:
            return {
                quantity: action.payload,
                cartModalIsOpen: state.cartModalIsOpen,
                checkedItems: state.checkedItems,
                totalPrice: state.totalPrice,
                dish: state.dish,
                cartIndex: state.cartIndex
            };
        case CHANGE_CHECK:
            return {
                quantity: state.quantity,
                cartModalIsOpen: state.cartModalIsOpen,
                checkedItems: Array.from(action.payload),
                totalPrice: state.totalPrice,
                dish: state.dish,
                cartIndex: state.cartIndex
            };
        case CALCULATE_TOTAL_PRICE:
            return {
                quantity: state.quantity,
                cartModalIsOpen: state.cartModalIsOpen,
                checkedItems: state.checkedItems,
                totalPrice: action.payload,
                dish: state.dish,
                cartIndex: state.cartIndex
            };
        case SET_DISH:
            return {
                quantity: state.quantity,
                cartModalIsOpen: state.cartModalIsOpen,
                checkedItems: state.checkedItems,
                totalPrice: state.totalPrice,
                dish: action.payload,
                cartIndex: state.cartIndex
            };
        case SET_CART_INDEX:
            return {
                quantity: state.quantity,
                cartModalIsOpen: state.cartModalIsOpen,
                checkedItems: state.checkedItems,
                totalPrice: state.totalPrice,
                dish: state.dish,
                cartIndex: action.payload
            };    
        default:
            return state
    }
}