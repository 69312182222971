import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import VvFoodApi from '../api/VvFoodApi';
import Alert from 'react-bootstrap/Alert';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setEmail, setRequiredEmail, setPassword, setRequiredPassword, setConfirmPassword, setRequiredConfirmPassword, setErrorList, sendResetPasswordRequest } from "../actions";


class NewPassword extends React.Component {

    /**
    * Validate email method
    * @param {*} email email to validate
    */
    validateEmail = email => {
        return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
    }

    /**
     * Reset password function
     */
    resetPassword = async () => {
        var errors = [];
        const token = this.props.match.params.token;
        if (this.props.passwordReset.email === undefined || this.props.passwordReset.email === "") {
            errors.push("please verify your email");
            this.props.setRequiredEmail( true );
        }
        else {
            if (!this.validateEmail(this.props.passwordReset.email)) {
                errors.push("please verify your email");
                this.props.setRequiredEmail( true );
            }
        }
        if (this.props.passwordReset.password === undefined || this.props.passwordReset.password === "") {
            errors.push("please verify your password");
            this.props.setRequiredPassword( true );
        }
        else {
            if (this.props.passwordReset.password.length < 6) {
                errors.push("please verify your password");
                this.props.setRequiredPassword( true );
            }
            else {
                if (this.props.passwordReset.confirmPassword === undefined || this.props.passwordReset.confirmPassword === "" || this.props.passwordReset.confirmPassword !== this.props.passwordReset.password) {
                    errors.push("Confirm password is wrong");
                    this.props.setRequiredConfirmPassword( true );
                }
            }
        }
    
        if (errors.length) {
            this.props.setErrorList( errors );
        }
        else {
            var baseJson = {
                'email': this.props.passwordReset.email,
                'password': this.props.passwordReset.password,
                'token' : token
            }
            await this.props.sendResetPasswordRequest(baseJson);
            if (this.props.passwordReset.requestStatus > 0) {
                this.props.history.push('/new_password_success');
            }
            else {
                return null;
            }
        }
    
    }
    render() {
        return (
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Row className="justify-content-center" style={{ textAlign: 'center', marginTop: 103 }}>
                            <h4 style={{ textAlign: 'center', color: '#516E33', fontFamily: "MontserratMedium" }}>
                                <b style={{ textAlign: 'center' }}>
                                    Reset the password
                                </b>
                            </h4>
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-center" style={{ color: '#B2D37B', paddingLeft: 5, paddingRight: 5 }}>
                    <Col md={8} lg={4} sm={8} style={{ backgroundColor: '#B2D37B', paddingTop: 20, paddingBottom: 20, height: '100%' }}>
                        <div id="innerDiv" style={{ border: '3px solid white', height: '100%', width: '100%' }}>
                            <Form style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 5 }}>
                                <Form.Row style={{ paddingTop: 10 }}>
                                    <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail">
                                        <Form.Label style={{ color: 'white' }}>
                                            <h6 className="boldFirstLetter" style={{ fontFamily: "MontserratMedium" }}>e-mail</h6>
                                        </Form.Label>
                                        {this.props.passwordReset.requiredEmail ?
                                            <Form.Control type="email" placeholder="e-mail" onChange={(e) => this.props.setEmail( e.target.value )} style={{ border: '1px solid', borderColor: 'red', marginTop: -8 }} />
                                            : <Form.Control type="email" placeholder="e-mail" onChange={(e) => this.props.setEmail( e.target.value )} style={{ marginTop: -8 }} />
                                            //: <Form.Control type="email" placeholder="e-mail" onChange={(e) => setEmail(e.target.value)} style={{ marginTop: -8 }} />
                                        }
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail">
                                        <Form.Label style={{ color: 'white' }}>
                                            <h6 className="boldFirstLetter" style={{ fontFamily: "MontserratMedium" }}>new password</h6>
                                        </Form.Label>
                                        {this.props.passwordReset.requiredPassword ?
                                            <Form.Control type="password" placeholder="new password" onChange={(e) => this.props.setPassword( e.target.value )} style={{ border: '1px solid', borderColor: 'red', marginTop: -8 }} />
                                            : <Form.Control type="password" placeholder="new password" onChange={(e) => this.props.setPassword( e.target.value )} style={{ marginTop: -8 }} />
                                            //: <Form.Control type="password" placeholder="new password" onChange={(e) => this.props.setpassword(e.target.value)} style={{ marginTop: -8 }} />
                                        }
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail">
                                        <Form.Label style={{ color: 'white' }}>
                                            <h6 className="boldFirstLetter" style={{ fontFamily: "MontserratMedium" }}>confirm new password</h6>
                                        </Form.Label>
                                        {this.props.passwordReset.requiredConfirmPassword ?
                                            <Form.Control type="password" placeholder="confirm new password" onChange={(e) => this.props.setConfirmPassword( e.target.value )} style={{ border: '1px solid', borderColor: 'red', marginTop: -8 }} />
                                            : <Form.Control type="password" placeholder="confirm new password" onChange={(e) => this.props.setConfirmPassword( e.target.value )} style={{ marginTop: -8 }} />
                                            //: <Form.Control type="password" placeholder="confirm new password" onChange={(e) => setConfirmPassword(e.target.value)} style={{ marginTop: -8 }} />
                                        }    
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
                {this.props.passwordReset.errorList.length ?
                    <Row className="justify-content-center" style={{ textAlign: 'center', marginTop: '30px' }}>
                        <Col md={4} lg={4} sm={12}>
                            <Alert variant='danger'>
                                <ul>
                                    {this.props.passwordReset.errorList.map((error) =>
                                        <li style={{ textAlign: 'left' }}>{error}</li>
                                    )}
                                </ul>
                            </Alert>
                        </Col>
                    </Row>
                    : null
                }
                <Row className="justify-content-center">
                    <Button variant="primary" type="submit" style={{ backgroundColor: '#E57A2A', marginTop: 20, fontFamily: "MontserratMedium", height: 50, width: 300 }} onClick={this.resetPassword}>
                        Reset password
                    </Button>
                </Row>
            </Container>
        )
    }    
}

/**,
 * Maps state to props.
 * @param state the state.
 * @returns {{passwordReset: *}}
 */
const mapStateToProps = (state) => {
    return {
        passwordReset: state.passwordReset
    };
};

export default connect(mapStateToProps, { setEmail, setRequiredEmail, setPassword, setRequiredPassword, setConfirmPassword, setRequiredConfirmPassword, setErrorList, sendResetPasswordRequest })(NewPassword);