import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { setGoToCheckout } from "../actions";
import "./Login.css";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import {ReactComponent as Checked} from '../assets/image/checked.svg';
import {ReactComponent as NotChecked} from '../assets/image/not-checked.svg';
import { useTranslation } from 'react-i18next';
import AuthService from "../services/AuthService";
import VvFoodApi from "../api/VvFoodApi";
/**
 * Registration component
 */
const Registration = (props) => {

    /**
     * Form fields
     */
    const [name, setName] = useState(undefined);
    const [surname, setSurname] = useState(undefined);
    const [phone, setPhone] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [city, setCity] = useState(undefined);
    const [street, setStreet] = useState(undefined);
    const [house, setHouse] = useState(undefined);
    const [postal, setPostal] = useState("");
    const [password, setPassword] = useState(undefined);
    const [confirmPassword, setConfirmPassword] = useState(undefined);
    const [companyForm, setCompanyForm] = useState(false);
    const [agree, setAgree] = useState(false);
    const [companyName, setCompanyName] = useState(undefined);
    const [vat, setVat] = useState(undefined);
    const [legalAddress, setLegalAddress] = useState(undefined);

    /**
     * Required value for form fields
     */
    const [requiredName, setRequiredName] = useState(false);
    const [requiredSurname, setRequiredSurname] = useState(false);
    const [requiredPhone, setRequiredPhone] = useState(false);
    const [requiredEmail, setRequiredEmail] = useState(false);
    const [requiredCity, setRequiredCity] = useState(false);
    const [requiredStreet, setRequiredStreet] = useState(false);
    const [requiredHouse, setRequiredHouse] = useState(false);
    const [requiredPostal, setRequiredPostal] = useState(false);
    const [requiredPassword, setRequiredPassword] = useState(false);
    const [requiredConfirmPassword, setRequiredConfirmPassword] = useState(false);
    const [requiredCompanyName, setRequiredCompanyName] = useState(false);
    const [requiredVatNumber, setRequiredVatNumber] = useState(false);
    const [requiredLegalAddress, setRequiredLegalAddress] = useState(false);
    const [errorList, setErrorList] = useState([])

    const history = useHistory();

    /**
     * Validate email method
     * @param {*} email email to validate
     */
    const validateEmail = email => {
        return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
    }

    const cheangePostalCode = (e) => {
        if (e.target.value.length <= 5) {
            setPostal(e.target.value);
        }
    }
    const { t, i18n } = useTranslation();
    /**
     * Validate form method
     */
    const validateForm = async () => {
        var errors = []
        setRequiredName(false);
        setRequiredSurname(false);
        setRequiredPhone(false);
        setRequiredEmail(false);
        setRequiredCity(false);
        setRequiredStreet(false);
        setRequiredHouse(false);
        setRequiredPostal(false);
        setRequiredPassword(false);
        setRequiredConfirmPassword(false);
        setRequiredCompanyName(false);
        setRequiredVatNumber(false);
        setRequiredLegalAddress(false);
        if (name === undefined || name === "") {
            errors.push(t('register:errorName'));
            setRequiredName(true);
        } else {
            if (/\d/.test(name)) {
                errors.push(t('register:errorName'));
                setRequiredPhone(true);
            }
        }
        if (surname === undefined || surname === "") {
            errors.push(t('register:errorSurname'));
            setRequiredSurname(true);
        } else {
            if (/\d/.test(surname)) {
                errors.push(t('register:errorSurname'));
                setRequiredPhone(true);
            }
        }
        if (phone === undefined || phone === "") {
            errors.push(t('register:errorNumber'));
            setRequiredPhone(true);
        }
        else {
            if (!/^\d{10}$/.test(phone)) {
                errors.push(t('register:errorNumber'));
                setRequiredPhone(true);
            }
        }
        if (email === undefined || email === "") {
            errors.push(t('register:errorEmail'));
            setRequiredEmail(true);
        }
        else {
            if (!validateEmail(email)) {
                errors.push(t('register:errorEmail'));
                setRequiredEmail(true);
            }
        }
        if (city === undefined || city === "") {
            errors.push(t('register:errorCity'));
            setRequiredCity(true);
        }
        else {
            if (city.length < 2 || !/^[a-zA-Z\s*]+$/.test(city)) {
                errors.push(t('register:errorCity'));
                setRequiredCity(true);
            }
        }
        if (street === undefined || street === "") {
            errors.push(t('register:errorStreet'));
            setRequiredStreet(true);
        }
        else {
            if (street.length < 2 || !/^[a-zA-Z\s*]+$/.test(street)) {
                errors.push(t('register:errorStreet'));
                setRequiredStreet(true);
            }
        }
        if (house === undefined || house === "") {
            errors.push(t('register:errorHouseNumber'));
            setRequiredHouse(true);
        }
        else {
            if (!/^\d+/.test(house)) {
                errors.push(t('register:errorHouseNumber'));
                setRequiredHouse(true);
            }
        }
        if (postal === undefined || postal === "") {
            errors.push(t('register:postalCodeError'));
            setRequiredPostal(true);
        }
        else {
            if (postal.length !== 5 || !/^\d+$/.test(postal)) {
                errors.push(t('register:postalCodeError'));
                setRequiredPostal(true);
            }
        }
        if (password === undefined || password === "") {
            errors.push(t('register:passwordError'));
            setRequiredPassword(true);
        }
        else {
            if (password.length < 6) {
                errors.push(t('register:passwordError'));
                setRequiredPassword(true);
            }
        }
        if (confirmPassword === undefined || confirmPassword === "" || confirmPassword !== password) {
            errors.push(t('register:confirmPasswordError'));
            setRequiredConfirmPassword(true);
        }
        if (companyForm) {
            if (companyName === undefined || companyName === "") {
                errors.push(t('register:companyNameError'));
                setRequiredCompanyName(true);
            }
            if (vat === undefined || vat === "") {
                errors.push(t('register:vatError'));
                setRequiredVatNumber(true);
            }
            else {
                if (!/^\d{11}$/.test(vat)) {
                    errors.push(t('register:vatError'));
                    setRequiredVatNumber(true);
                }
            }
            if (legalAddress === undefined || legalAddress === "") {
                errors.push(t('register:legalAddressError'));
                setRequiredLegalAddress(true);
            }
        }
        if (errors.length) {
            setErrorList(errors);
        }
        else {
            var registrationErr = false;
            var baseJson = {
                'name': name,
                'last_name': surname,
                'password': password,
                'email': email,
                'phone_number': phone,
                'address': city + ',' + street + ',' + house + ',' + postal,
                'is_company': companyForm
            };
            if (companyForm) {
                baseJson['company_name'] = companyName;
                baseJson['company_code'] = vat;
                baseJson['company_address'] = legalAddress;
            }
            await VvFoodApi.post(
                '/users',
                baseJson
            ).catch((error) => {
                errors.push(t('register:existingEmailError'));
                setErrorList(errors);
                registrationErr = true;
            });
            if (registrationErr) {
                return;
            }
            if (props.goToCheckoutModal.toCheckout) {
                await AuthService.login(email, password).then(
                    () => {
                        props.setGoToCheckout(false);
                        const isIE = !!document.documentMode;
                        if (!isIE) {
                            window.location.href = '/checkout';
                        } else {
                            window.location.href = '/';
                        }
                    }
                );
            } else {
                history.push('/login');
            }
        }

    };

    /**
     * Return component method
     */
    return (
        <Container fluid>
            <Row>
                <Col sm={12}>
                    <Row className="justify-content-center" style={{ textAlign: 'center', marginTop: '70px', fontSizez: '35px' }}>
                        <h4 style={{ textAlign: 'center' }}>
                            <Translation ns="register">
                                {t =>
                                    <b style={{ textAlign: 'center', color: '#565751', fontFamily: 'MontserratRegular' }}>
                                        {t("registerTitle")}
                                    </b>
                                }
                            </Translation>
                        </h4>
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ color: '#B2D37B' }}>
                <Col xl={7} md={9} lg={9} sm={12} style={{ paddingTop: 40, height: '100%' }}>
                    <div id="innerDiv" style={{ height: '100%', width: '100%' }}>
                        <Form >
                            <Form.Row style={{ paddingTop: 10 }}>
                                <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail" style={{ marginBottom: '30px', paddingLeft: '10px', paddingRight: '10px', border: requiredName ? '2px solid red' : '2px solid #92c143' }}>
                                    <Translation ns="register">
                                        {t =>
                                            <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                                {t("name")}
                                            </Form.Label>
                                        }
                                    </Translation>
                                    <Form.Control className="loginInput" value={name} type="text" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setName(e.target.value.replace(/[0-9]/g, ''))} />
                                </Form.Group>
                                <Form.Group as={Col} sm={12} md={12} controlId="formGridPassword" style={{ marginBottom: '30px', paddingLeft: '10px', paddingRight: '10px', border: requiredSurname ? '2px solid red' : '2px solid #92c143' }}>
                                    <Translation ns="register">
                                        {t =>
                                            <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                                {t("surname")}
                                            </Form.Label>
                                        }
                                    </Translation>
                                    <Form.Control type="text" className="loginInput" value={surname} style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setSurname(e.target.value.replace(/[0-9]/g, ''))} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row >
                                <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail" style={{ marginBottom: '30px', paddingLeft: '10px', paddingRight: '10px', border: requiredPhone ? '2px solid red' : '2px solid #92c143' }}>
                                <Translation ns="register">
                                    {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                        {t("phone")}
                                    </Form.Label> }
                                    </Translation>
                                    <Form.Control type="text" value={phone} style={{ border: '0px solid', padding: '0px', boxShadow: 'none' }} onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))} />
                                </Form.Group>
                                <Form.Group as={Col} sm={12} md={12} controlId="formGridPassword" style={{ marginBottom: '30px', paddingLeft: '10px', paddingRight: '10px', border: requiredEmail ? '2px solid red' : '2px solid #92c143' }}>
                                <Translation ns="register">
                                    {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                        {t("email")}
                                    </Form.Label> }
                                    </Translation>
                                    <Form.Control type="email" className="loginInput" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail" style={{ marginBottom: '30px', paddingLeft: '10px', paddingRight: '10px', border: requiredCity ? '2px solid red' : '2px solid #92c143' }}>
                                <Translation ns="register">
                                    {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                        {t("city")}
                                    </Form.Label>}
                                    </Translation>
                                    <Form.Control type="text" className="loginInput" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setCity(e.target.value)} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} md={12} controlId="formGridPassword" style={{ marginBottom: '30px', paddingLeft: '10px', paddingRight: '10px', border: requiredStreet ? '2px solid red' : '2px solid #92c143' }}>
                                    <Translation ns="register">
                                    {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                        {t("street")}
                                    </Form.Label> }
                                    </Translation>
                                    <Form.Control type="text" className="loginInput" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setStreet(e.target.value)} />
                                </Form.Group>
                            </Form.Row>

                            <Form.Row style={{ justifyContent: 'space-between' }}>
                                <span className="formPostal">
                                    <Form.Group controlId="formGridEmail" style={{ marginBottom: '30px', paddingLeft: '10px', paddingRight: '10px', border: requiredHouse ? '2px solid red' : '2px solid #92c143' }}>
                                    <Translation ns="register">
                                        {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                            {t("houseNumber")}
                                        </Form.Label> }
                                        </Translation>
                                        <Form.Control type="text" className="loginInput" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setHouse(e.target.value)} />
                                    </Form.Group>
                                </span>
                                <span className="formPostal">
                                    <Form.Group controlId="formGridPassword" style={{ marginBottom: '30px', paddingLeft: '10px', paddingRight: '10px', border: requiredPostal ? '2px solid red' : '2px solid #92c143' }}>
                                    <Translation ns="register">
                                        {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                        {t("postalCode")}
                                        </Form.Label> }
                                    </Translation>
                                        <Form.Control type="text" className="loginInput" value={postal} style={{ border: '0px solid', padding: '0px' }} onChange={(e) => cheangePostalCode(e)} />
                                    </Form.Group>
                                </span>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail" style={{ marginBottom: '30px', paddingLeft: '10px', paddingRight: '10px', border: requiredPassword ? '2px solid red' : '2px solid #92c143' }}>
                                    <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                        Password
                                    </Form.Label>
                                    <Form.Control type="password" className="loginInput" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>

                                <Form.Group as={Col} sm={12} md={12} controlId="formGridPassword" style={{ paddingLeft: '10px', paddingRight: '10px', border: requiredConfirmPassword ? '2px solid red' : '2px solid #92c143' }}>
                                <Translation ns="register">
                                    {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                    {t("confirmPassword")}
                                    </Form.Label> }
                                </Translation>
                                    <Form.Control type="password" className="loginInput" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setConfirmPassword(e.target.value)} />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop:'5px', backgroundColor: '#e3e3e2', color: '#B2D37B', paddingTop: '15px', marginBottom: '30px', paddingBottom: '30px' }}>
                <Col xl={7} md={9} lg={9} sm={12}>
                    {companyForm ?
                    <Form.Row >
                        <span onClick={() => setCompanyForm(!companyForm)} style={{ cursor: 'pointer', marginLeft:'-10px' }}>
                            <Checked/>
                        </span>
                        <Translation ns="register">
                        {t => <span style={{ color: '#707168', fontFamily: 'MontserratLight', paddingTop:'10px'}}>
                            {t("invoice")}
                        </span> }
                        </Translation>
                    </Form.Row> :
                    <Form.Row >
                        <span onClick={() => setCompanyForm(!companyForm)} style={{ cursor: 'pointer', paddingTop:'5px', marginLeft:'-10px' }}>
                            <NotChecked/>
                        </span>
                        <Translation ns="register">
                        {t => <span style={{ color: '#707168', fontFamily: 'MontserratLight', paddingTop:'10px'}}>
                            {t("invoice")}
                        </span> }
                        </Translation>
                    </Form.Row>
                    }
                    {companyForm ?
                        <div id="innerDiv" style={{ height: '100%', width: '100%', paddingTop: '30px' }}>
                            <Form >
                                <Form.Row style={{ paddingTop: 15 }}>
                                    <Form.Group as={Col} sm={12} md={12} controlId="formGridEmail" style={{ marginBottom: '30px', backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px', border: requiredCompanyName ? '2px solid red' : '2px solid #92c143' }}>
                                        <Translation ns="register">
                                        {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                            {t("companyName")}
                                        </Form.Label> }
                                        </Translation>
                                        <Form.Control type="text" className="loginInput" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setCompanyName(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={12} md={12} controlId="formGridPassword" style={{ marginBottom: '30px', backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px', border: requiredVatNumber ? '2px solid red' : '2px solid #92c143' }}>
                                        <div>
                                            <Translation ns="register">
                                            {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                                {t("vatNumber")}
                                            </Form.Label> }
                                            </Translation>
                                        </div>
                                        <Form.Control type="text" className="loginInput" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setVat(e.target.value)} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} md={12} lg={12} controlId="formGridEmail" style={{ backgroundColor: 'white', paddingLeft: '10px', paddingRight: '10px', border: requiredLegalAddress ? '2px solid red' : '2px solid #92c143' }}>
                                    <Translation ns="register">
                                        {t => <Form.Label style={{ color: '#757671', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>
                                            {t("legalAddress")}
                                        </Form.Label> }
                                        </Translation>
                                        <Form.Control type="text" className="loginInput" style={{ border: '0px solid', padding: '0px' }} onChange={(e) => setLegalAddress(e.target.value)} />
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div>
                        : null
                    }
                </Col>
            </Row>
            {errorList.length ?
                <Row className="justify-content-center" style={{ textAlign: 'center', marginTop: '30px' , marginBottom: '30px', fontFamily: 'MontserratLight', fontWeight:'bold' }}>
                    <Col xl={7} md={9} lg={9} sm={12}>
                                {errorList.map((error) =>
                                    <div style={{ textAlign: 'left', color:'red' }}>{"-" + error}</div>
                                )}
                    </Col>
                </Row>
                : null
            }
            <Row className="justify-content-center">
                <Col xl={7} md={9} lg={9} sm={12}>
                    <Translation ns="login">
                        {t => <Button onClick={validateForm} className="loginButton" variant="primary" type="submit" style={{ height:'65px', border: '0px', width: '100%', borderRadius: "0px", backgroundColor: "#92c143", color: 'white', fontFamily: 'MontserratMedium', fontSize: '30px' }} >
                            {t("register")}
                        </Button>
                        }
                    </Translation>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '30px', marginBottom: '30px' }}>
                <Translation ns="login">
                    {t =>
                        <Col xl={7} md={9} lg={9} sm={12}>
                            <div style={{ fontFamily: 'MontserratRegular', color: 'black' }}>
                                {t("byLogging")}<Link to="/terms-and-conditions" style={{ color: '#6a832c', textDecoration: 'underline' }}>{t("terms")}</Link>
                            </div>
                            <div style={{ fontFamily: 'MontserratRegular', color: 'black' }}>
                                {t("readInf")} <Link to="/privacy" style={{ color: '#6a832c', textDecoration: 'underline' }}>{t("privacy")}</Link> {t("and")} <Link to="/cookies-policy" style={{ color: '#6a832c', textDecoration: 'underline' }}>{t("cookie")}</Link>
                            </div>
                        </Col>
                    }
                </Translation>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '30px', marginBottom: '130px' }}>
                <Col xl={7} md={9} lg={9} sm={12}>
                    <Translation ns="register">
                        {t => <div style={{ fontFamily: 'MontserratRegular', color: 'black' }}>
                            {t("alreadyAccount")}<Link to='login' style={{ color: 'red' }}> {t("signIn")}</Link>
                        </div> }
                    </Translation>
                </Col>
            </Row>
        </Container>
    )
}

/**,
 * Maps state to props.
 * @param state the state.
 */
const mapStateToProps = (state) => {
    return {
        goToCheckoutModal: state.goToCheckoutModal,
    };
};

export default connect(mapStateToProps, {
    setGoToCheckout,
})(Registration);
