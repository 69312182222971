import { SELECT_FILTER } from '../actions/ActionTypes';

/**
 * Initial state.
 */
const initialState = {
    freeDelivery: false,
    homeDelivery: false,
    localPickup: false,
    openedNow:false
};

/**
 * Filter reducer.
 */
export default (state = initialState, action) => {
    switch (action.type) {
        case SELECT_FILTER:
            return {
                freeDelivery: action.payload.freeDelivery,
                homeDelivery: action.payload.homeDelivery,
                localPickup: action.payload.localPickup,
                openedNow: action.payload.openedNow
            };
        default:
            return state
    }
};