let distinguishCityAndStreetFromSearchString;

/**
 * Method distinguishes street and city from provided search string by using google geocode api.
 */
export default distinguishCityAndStreetFromSearchString = async (
  searchString,
) => {
  let geocodeApiUrl =
    'https://maps.googleapis.com/maps/api/geocode/json?key=' +
    process.env.REACT_APP_GOOGLE_API_KEY +
    '&address=' +
    encodeURIComponent(searchString);
  let addressElements = new Map();
  try {
    let result = await fetch(geocodeApiUrl);
    let json = await result.json();
    let addressComponents = json.results[0].address_components;

    var cityLevel = 0

    addressComponents.forEach((item) => {
      if (item.types.includes('route')) {
        addressElements.set('street', item.short_name.toString());
        return;
      }
      if (item.types.includes('locality')) {
        if (cityLevel < 1) {
          addressElements.set('city', item.short_name.toString());
          cityLevel = 1
        }
      }
      if (item.types.includes('administrative_area_level_2')) {
        if (cityLevel < 2) {
          addressElements.set('city', item.short_name.toString());
          cityLevel = 2
        }
      }
      if (item.types.includes('administrative_area_level_3')) {
        if (cityLevel < 3) {
          addressElements.set('city', item.short_name.toString());
          cityLevel = 3
        }
      }
    });
    return addressElements;
  } catch (err) {
    console.log(err);
  }
};
