import React, { useState, useEffect, useRef } from "react";
import Config from '../utility/Config';
import {
    setDeliveryAvailable,
    setDeliveryAddress
} from "../actions";
import { connect } from 'react-redux';

let autoComplete;

/**
 * load autocomplete script
 * @param {*} url 
 * @param {*} callback 
 */
const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

/**
 * handle the autocomplete script
 * @param {*} updateQuery 
 * @param {*} autoCompleteRef 
 */
function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current
  );
  autoComplete.setFields(["address_components", "formatted_address"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
  
}

/**
 * handle option select
 * @param {*} updateQuery 
 */
async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  await updateQuery(query);
}

/**
 * Custom autocomplete component
 * @param {*} props 
 */
function SearchLocationInput(props) {
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${Config.GOOGLE_API_KEY}&libraries=places`,
      () => handleScriptLoad(props.setStateCallback, autoCompleteRef)
    );
    props.setStateCallback(props.cart.deliveryAddress)
  }, []);

  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          setTimeout(() => {
            props.searchFunction();
        }, 0);  
      }
  }

  return (
    <div className="search-location-input" id="autocompleteInput" style={props.containerStyle}>
      <input
        style={props.inputStyle}
        ref={autoCompleteRef}
        onChange={event => {props.setStateCallback(event.target.value)}}
        placeholder={props.placeholder}
        value={props.cart.deliveryAddress}
        onKeyUp={handleKeyDown}
      />
    </div>
  );
}

/**,
 * Maps state to props.
 * @param state the state.
 * @returns {{cart: *, selectedDish: *}}
 */
const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        restaurants: state.restaurants,
        selectedDish: state.selectedDish,
        goToCheckoutModal: state.goToCheckoutModal,
        restaurantClosedModal: state.restaurantClosedModal,
    };
};
export default connect(mapStateToProps, {setDeliveryAvailable, setDeliveryAddress })(SearchLocationInput);