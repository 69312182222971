import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import { Textfit } from 'react-textfit';
import Utility from '../utility'
import clockIcon from './../assets/image/clockIcon.png'
import { Link } from 'react-router-dom';
import { withTranslation, Translation } from 'react-i18next';
import {connect} from "react-redux";
import {setCart} from "../actions";

/**
 * Restaurant card component
 */
class RestaurantCard extends React.Component {

    constructor(props) {
        super();
        this.state = { restaurant: props.resource, colorInfo: props.colorInfo, textColorInfo: props.textColorInfo}
    }

    restaurantAddress = () => {
        return (
            <div className="address">
                <p style={{fontFamily: "MontserratMedium", fontSize: 16, padding: 2, marginBottom: 7 }}>{this.props.resource.address}</p>
            </div>
        )
    }

    restaurantHours = () => {
        let delivery = this.props.resource.delivery
        let status = this.props.resource.status

        if (!status){
            return (
                <div className="workHours">
                    <Translation ns="restaurant.card">
                        { t => <div className="closed">{t('closed.today')}</div> }
                    </Translation>
                </div>
            )
        }

        let dayOfTheWeek = Utility.getDayNumOfWeek();
        let whToday = this.props.resource.workingHours[dayOfTheWeek]
        let dhToday = this.props.resource.deliveryHours[dayOfTheWeek]

        if (whToday.startTime == null && whToday.endTime == null){
            return (
                <div className="workHours">
                    <Translation ns="restaurant.card">
                        { t => <div className="hours">{t('closed.today')}</div> }
                    </Translation>
                </div>
            )
        }

        return (
            <div className="workHours">
                <Translation ns="restaurant.card">
                    { t => <div className="hoursType">{t('open')}</div> }
                </Translation>
                <div className="hours">{ whToday.startTime.substring(0, 5) } - { whToday.endTime.substring(0, 5) }</div>
                <Translation ns="restaurant.card">
                    { t => <div className="hoursType">{t('delivery')}</div> }
                </Translation>
                { !delivery || ( dhToday.startTime == null && dhToday.endTime == null) ?
                <Translation ns="restaurant.card">
                    { t => <div className="hours">{t('not.available')}</div> }
                </Translation> : <div className="hours">{ dhToday.startTime.substring(0, 5) } - { dhToday.endTime.substring(0, 5) }</div> }
            </div>
        )

    };

    /**
     * Removes all the cart content if the opened restaurant is different than inside cart info.
     * @returns {Promise<void>}
     */
    clearCart = async () => {
        if (this.props.selectedRestaurant.id !== null && this.props.selectedRestaurant.id !== this.props.resource.id) {
            var filtered = this.props.cart.cartContent.filter(function () {
                return false;
            });
            await this.props.setCart(filtered);
        }
    };

    render() {
      return (
          <div>
              <Link to = {"/restaurant/"+this.props.resource.urlSlug} onClick={this.clearCart}>
                <div className="card" style={{backgroundImage: `url("`+this.props.resource.imageUrl+`")`}}>
                    <div className="informationContainer">
                        <div className="information" style={{backgroundColor: 'rgba'+this.state.colorInfo, color: this.state.textColorInfo}}>
                            { this.restaurantAddress() }
                            <div className="clock">
                                <img src={clockIcon} alt="Clock" style={{width: 18, height: 18, marginLeft: 7}}/>
                            </div>
                            { this.restaurantHours() }

                        </div>
                    </div>
                </div>
              </Link>
            <div className="restaurantName">{this.props.resource.name}</div>
        </div>
      );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedRestaurant: state.selectedRestaurant,
        cart: state.cart,
    }
};

export default connect(mapStateToProps, {setCart})(RestaurantCard);
