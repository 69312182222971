import React from "react";
import i18next from "i18next";
import CustomerServiceEn from "./CustomerServiceEn";
import CustomerServiceIt from "./CustomerServiceIt";

const CustomerService = props => {
    switch(i18next.language) {
        case 'en':
            return <CustomerServiceEn/>;
        case 'it':
            return <CustomerServiceIt/>;
        default:
            return <CustomerServiceIt/>;
    }
}

export default CustomerService;