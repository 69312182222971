/**
 * @type {string} Action type set email.
 */
export const SET_EMAIL = 'SET_EMAIL';

/**
 * @type {string} Action type set required email.
 */
export const SET_REQUIRED_EMAIL = 'SET_REQUIRED_EMAIL';

/**
 * @type {string} Action type set password.
 */
export const SET_PASSWORD = 'SET_PASSWORD';

/**
 * @type {string} Action type set required password.
 */
export const SET_REQUIRED_PASSWORD = 'SET_REQUIRED_PASSWORD';

/**
 * @type {string} Action type set confirm password.
 */
export const SET_CONFIRM_PASSWORD = 'SET_CONFIRM_PASSWORD';

/**
 * @type {string} Action type set required confirm password.
 */
export const SET_REQUIRED_CONFIRM_PASSWORD = 'SET_REQUIRED_CONFIRM_PASSWORD';

/**
 * @type {array} Action type set error list.
 */
export const SET_ERROR_LIST = 'SET_ERROR_LIST';

/**
 * @type {array} Action type send reset password request.
 */
export const SEND_RESET_PASSWORD_REQUEST = 'SEND_RESET_PASSWORD_REQUEST';

/**
 * @type {string} Action type fetch all restaurants.
 */
export const FETCH_RESTAURANTS = 'FETCH_RESTAURANTS';

/**
 * @type {string} Action type fetch restaurants by address.
 */
export const FETCH_RESTAURANTS_BY_ADDRESS = 'FETCH_RESTAURANTS_BY_ADDRESS';

/**
 * @type {string} Action type set searched address.
 */
export const SET_ADDRESS = 'SET_ADDRESS';

/**
 * @type {string} Action type set searched name.
 */
export const SET_NAME = 'SET_NAME';

/**
 * @type {boolean} Action type for showing no restaurants nearby.
 */
export const SHOW_NO_RESTAURANTS_NEARBY = 'SHOW_NO_RESTAURANTS_NEARBY';

/**
 * @type {boolean} Action type for showing restaurants found.
 */
export const SHOW_RESTAURANTS_FOUND = 'SHOW_RESTAURANTS_FOUND';

/**
 * @type {string} Action type fetch all menus.
 */
export const FETCH_MENUS = 'FETCH_MENUS';

/**
 * @type {string} Action type fetch single restaurant.
 */
export const FETCH_RESTAURANT = 'FETCH_RESTAURANT';

/**
 * @type {int} Action type fetch nearest restaurant.
 */
export const FETCH_NEAREST_RESTAURANT = 'FETCH_NEAREST_RESTAURANT';

/**
 * @type {string} Action type fetch all tags.
 */
export const FETCH_TAGS = 'FETCH_TAGS';

/**
 * @type {string} Action type for add tag for filter.
 */
export const SELECT_TAG = 'SELECT_TAG';

/**
 * @type {string} Action type fetch single restaurant.
 */
export const FETCH_DISH = 'FETCH_DISH';

/**
 * @type {array} Action type fetch pick up times.
 */
export const FETCH_PICK_UP_TIMES = 'FETCH_PICK_UP_TIMES';

/**
 * @type {array} Action type fetch delivery times.
 */
export const FETCH_DELIVERY_TIMES = 'FETCH_DELIVERY_TIMES';

/**
 * @type {string} Action type to set pick up hour.
 */
export const SET_PICK_UP_HOUR = 'SET_PICK_UP_HOUR';

/**
 * @type {string} Action type to set pick up minute.
 */
export const SET_PICK_UP_MINUTE = 'SET_PICK_UP_MINUTE';

/**
 * @type {string} Action type to set delivery hour.
 */
export const SET_DELIVERY_HOUR = 'SET_DELIVERY_HOUR';

/**
 * @type {string} Action type to set delivery minute.
 */
export const SET_DELIVERY_MINUTE = 'SET_DELIVERY_MINUTE';

/**
 * @type {string} Action type for keeping id of the selected restaurant.
 */
export const SELECT_RESTAURANT = 'SELECT_RESTAURANT';

/**
 * @type {string} Action type for keeping id of the selected dish.
 */
export const SELECT_DISH = 'SELECT_DISH';

/**
 * @type {string} Action type for showing modal.
 */
export const SHOW_MODAL = 'SHOW_MODAL';

/**
 * @type {string} Action type for hiding modal.
 */
export const HIDE_MODAL = 'HIDE_MODAL';

/**
 * @type {string} Action type for showing modal.
 */
export const SHOW_MODAL_CART = 'SHOW_MODAL_CART';

/**
 * @type {string} Action type for hiding modal.
 */
export const HIDE_MODAL_CART = 'HIDE_MODAL_CART';

/**
 * @type {string} Action type for setting cart.
 */
export const SET_CART = 'SET_CART';

/**
 * @type {string} Action type for setting cart index.
 */
export const SET_CART_INDEX = 'SET_CART_INDEX';

/**
 * @type {string} Action type for setting dish.
 */
export const SET_DISH = 'SET_DISH';

/**
 * @type {string} Action type for change quantity.
 */
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';

/**
 * @type {string} Action type for calculating total price.
 */
export const CALCULATE_TOTAL_PRICE = 'CALCULATE_TOTAL_PRICE';

/**
 * @type {string} Action type for change checked checkboxes.
 */
export const CHANGE_CHECK = 'CHANGE_CHECK';

/**
 * @type {string} Action type for user login.
 */
export const USER_LOGIN = 'USER_LOGIN';

/**
 * @type {string} Action type for user load data.
 */
export const USER_LOAD = 'USER_LOAD'

/**
 * @type {string} Action type for setting closed modal visibility true or false.
 */
export const SET_CLOSED_MODAL_VISIBILITY = 'SET_CLOSED_MODAL_VISIBILITY';

/**
 * @type {string} Action type for minimum order error modal visibility.
 */
export const SET_MIN_ORDER_MODAL_VISIBILITY = 'SET_MIN_ORDER_MODAL_VISIBILITY';

/**
 * @type {string} Action type for add for filter.
 */
export const SELECT_FILTER = 'SELECT_FILTER';

/**
 * @type {string} Action type for select order type.
 */
export const SELECT_ORDER_TYPE = 'SELECT_ORDER_TYPE';

/**
 * @type {string} Action type for set if delivery is opened or not.
 */
export const SET_DELIVERY_OPEN = 'SET_DELIVERY_OPEN';

/**
 * @type {string} Action type for set the go to checkout modal visibility.
 */
export const SET_GO_TO_CHECKOUT_MODAL_VISIBILITY = 'SET_GO_TO_CHECKOUT_MODAL_VISIBILITY';

/**
 * @type {string} Action type for set the discound field visibility.
 */
export const ENABLE_DISCOUNT = 'ENABLE_DISCOUNT';

/**
 * @type {string} Action type for set the credit card payment.
 */
export const SET_PAYMENT_CREDIT_CARD = 'SET_PAYMENT_CREDIT_CARD';

/**
 * @type {string} Action type for set the restaurant of the cart.
 */
export const SELECT_CART_RESTAURANT = 'SELECT_CART_RESTAURANT';

/**
 * @type {string} Action type for set "toCheckout" value.
 */
export const SET_TO_CHECKOUT = 'SET_TO_CHECKOUT';

/**
 * @type {string} Action type for set "note" value.
 */
export const SET_NOTE = 'SET_NOTE';

/**
 * @type {string} Action type for set "delivery price" value.
 */
export const SET_DELIVERY_PRICE = 'SET_DELIVERY_PRICE';

/**
 * @type {string} Action type order submit.
 */
export const SUBMIT_ORDER = 'SUBMIT_ORDER';

/**
 * @type {string} Action type order submit error.
 */
export const SUBMIT_ERROR = 'SUBMIT_ERROR';

/**
 * @type {string} Action type for set restaurant id in checkout.
 */
export const SET_RESTAURANT_ID = 'SET_RESTAURANT_ID';

/**
 * @type {string} Action type for error during payment process.
 */
export const SET_CARD_ERROR = 'SET_CARD_ERROR';

/**
 * @type {string} Action type for delivery address.
 */
export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';

/**
 * @type {string} Action type for delivery available.
 */
export const SET_DELIVERY_AVAILABLE = 'SET_DELIVERY_AVAILABLE';

/**
 * @type {string} Action type for disocunt value.
 */
export const SET_DISCOUNT = 'SET_DISCOUNT';

/**
 * @type {string} Action type for select tag for menu filtering.
 */
export const SELECT_MENU_TAG = 'SELECT_MENU_TAG';

/**
 * @type {string} Action type for select tag for show user modal.
 */
export const SHOW_USER_MODAL = 'SHOW_USER_MODAL';
