import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import icon from '../assets/image/icon.png';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';

/**
 * New password success page.
 */
class NewPasswordSuccess extends React.Component {


    componentDidMount() {
        this.id = setTimeout(() => this.props.history.push('/login'), 10000)
    }

    componentWillUnmount() {
        clearTimeout(this.id)
    }

    render() {
        return <Container fluid>
            <Row className="justify-content-center" style={{ marginTop: 120 }}>
                <img src={icon} alt={"logo"}></img>
            </Row>
            <Row>
                <Col sm={12}>
                    <Row className="justify-content-center" style={{ textAlign: 'center', marginTop: 35 }}>
                        <Translation ns="successPage">
                            {t =>
                                <div style={{ textAlign: 'center', fontFamily: "MontserratMedium" }}>
                                    <p style={{ color: '#516E33' }}>
                                    {t('changeText1')}<br></br>
                                    {t('changeText2')}
                                </p>
                                    <Link style={{ color: 'rgb(86, 87, 81)' }} to="/login">{t('loginButton')}</Link>
                                </div>
                            }
                        </Translation>
                    </Row>
                </Col>
            </Row>
        </Container>
    }
}

export default NewPasswordSuccess;