import React, { useState } from "react";
import RestaurantHeader from "./RestaurantHeader";
import RestaurantMenu from "./RestaurantMenu";
import Cart from "./Cart";
import VvFoodApi from "../api/VvFoodApi";
import { Container, Row, Col } from "react-bootstrap";
import { setClosedModalVisibility, setGoToCheckoutModalVisibility, setCartRestaurantSlug, setDeliveryPrice} from "../actions";
import { connect } from "react-redux";
import Utility from "../utility";
import RestaurantClosedModal from "./RestaurantClosedModal";
import GoToCheckoutModal from "./GoToCheckoutModal";

/**
 * Restaurant page.
 */
class Restaurant extends React.Component {
  /**
   * Constructor
   * @param {*} props to set.
   */
  constructor(props) {
    super(props);
    this.state = { restaurant: null };
  }

  /**
   * Sets visibility of restaurant closed modal on page load.
   */
  isRestaurantClosedToday() {
    if (
      this.state.restaurant.workingHours != null &&
      this.state.restaurant.workingHours.length > 0
    ) {
      const todayWorkingHours = this.state.restaurant.workingHours[
        Utility.getDayNumOfWeek()
      ];
      if (todayWorkingHours.startTime == null) {
        this.props.setClosedModalVisibility(true);
      } else {
        let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
        let startTimeRegExTimeArr = regExTime.exec(todayWorkingHours.startTime);
        let startTimeHr = startTimeRegExTimeArr[1] * 3600 * 1000;
        let startTimeMin = startTimeRegExTimeArr[2] * 60 * 1000;
        let startTimeSec = startTimeRegExTimeArr[3] * 1000;
        let startTimeMs = startTimeHr + startTimeMin + startTimeSec;

        let endTimeRegExTimeArr = regExTime.exec(todayWorkingHours.endTime);
        let endTimeHr = endTimeRegExTimeArr[1] * 3600 * 1000;
        let endTimeMin = endTimeRegExTimeArr[2] * 60 * 1000;
        let endTimeSec = endTimeRegExTimeArr[3] * 1000;
        let endTimeMs = endTimeHr + endTimeMin + endTimeSec;

        const today = new Date();
        const nowHr = today.getHours() * 3600 * 1000;
        const nowMin = today.getMinutes() * 60 * 1000;
        const nowSec = today.getSeconds() * 1000;
        const nowMs = nowHr + nowMin + nowSec;

        this.props.setClosedModalVisibility(
          !(startTimeMs < nowMs || nowMs > endTimeMs)
        );
      }
    }
  }

  componentDidMount = async () => {
    const { match } = this.props;
    const id = match.params.id;
    var restaurant;
    if (/^\d+$/.test(id)) {
      restaurant = await VvFoodApi.get("admin/restaurants/" + id).catch(
        (err) => {
          return null;
        }
      );
    } else {
      restaurant = await VvFoodApi.get("restaurants/slugs/" + id).catch(
        (err) => {
          return null;
        }
      );
    }
    if (restaurant === null) {
      this.props.setCartRestaurantSlug(null)
      this.props.history.push("/");
    } else {
      this.props.setCartRestaurantSlug(id)
      this.props.setDeliveryPrice(restaurant.data.deliveryCost)
      this.setState({ restaurant: restaurant.data });
      this.isRestaurantClosedToday();
    }
  };

  render() {
    return this.state.restaurant ? (
      <div>
        <RestaurantHeader restaurant={this.state.restaurant} />
        <Container>
          <Row style={{ justifyContent: "center" }}>
            <Col lg={9} md={12} style={{ marginBottom: 80 }} fluid className={'pr-lg-0'}>
              <RestaurantMenu id={this.state.restaurant.id} />
              <RestaurantClosedModal />
              <GoToCheckoutModal />
            </Col>
            <Col lg={3} md={6} fluid className={'pl-md-0 pt-2 pt-md-0'}>
              <Cart
                  id={this.state.restaurant.id}
                  restaurant={this.state.restaurant}
              />
            </Col>
          </Row>
        </Container>
      </div>
    ) : null;
  }
}

/**
 * Maps state into props.
 * @param state current state.
 * @returns {{restaurantClosedModal: *}}
 */
const mapStateToProps = (state) => {
  return {
    restaurantClosedModal: state.restaurantClosedModal,
    goToCheckoutModal: state.goToCheckoutModal,
    cart: state.cart,
  };
};

export default connect(mapStateToProps, {
  setCartRestaurantSlug,
  setClosedModalVisibility,
  setGoToCheckoutModalVisibility,
  setDeliveryPrice
})(Restaurant);
