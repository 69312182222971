import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import VWhite from '../assets/image/vv-white.png'
import VTLogo from '../assets/image/vtlogo.png'
import { withRouter } from 'react-router';
import './Home.css'
import { withTranslation, Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

class Footer extends React.Component {

    render() {
        return (
            <Row style={{ backgroundColor: '#92c143', margin: 0 }} >
                <Container id='footer'>
                    <Row className="justify-content-center padding-footer" style={{ backgroundColor: '#92c143', paddingTop: 50, paddingBottom: 50, textAlign: 'left' }} id='contacts'>
                        <Col md={10} xl={11} style={{ paddingTop: 20 }}>
                            <Row className="justify-content-center">
                                <Col lg={12} xs={12}>
                                    <Row style={{ paddingBottom: 50 }}>
                                        <Col lg={10} xs={12} className="footerElement">
                                            <Translation ns="footer">
                                                {t =>
                                                    <Row style={{ lineHeight: 1 }}>
                                                        <Col md={4} className='menu-padding'>
                                                            <p style={{ color: 'white', fontFamily: 'MontserratMedium', marginBottom: '0.85rem', fontSize: 18 }}>{t("order")}</p>
                                                            <p className='underlinedElement' onClick={() => window.location.href = "/how-does-it-work"} style={{ cursor: 'pointer', color: 'white', fontFamily: 'MontserratRegular', marginBottom: '0.85rem' }}>{t("howDoesItWork")}</p>
                                                            <p className='underlinedElement' onClick={() => window.location.href = "/customer-service"} style={{ cursor: 'pointer', color: 'white', fontFamily: 'MontserratRegular', marginBottom: '0.85rem' }}>{t("customerService")}</p>
                                                        </Col>
                                                        <Col md={4} className='menu-padding'>
                                                            <p style={{ color: 'white', fontFamily: 'MontserratMedium', marginBottom: '0.85rem', fontSize: 18 }}>{t("about")}</p>
                                                            <p className='underlinedElement' onClick={() => window.location.href = "/contact"} style={{ cursor: 'pointer', color: 'white', fontFamily: 'MontserratRegular', marginBottom: '0.85rem' }}>{t("contact")}</p>
                                                        </Col>
                                                        <Col md={4} className='menu-padding'>
                                                            <p style={{ color: 'white', fontFamily: 'MontserratMedium', marginBottom: '0.85rem', fontSize: 18 }}>{t("EnterInVvfood")}</p>
                                                            <p className='underlinedElement' onClick={() => window.location.href = "/become-partners"} style={{ cursor: 'pointer', color: 'white', fontFamily: 'MontserratRegular', marginBottom: '0.85rem' }}>{t("partner")}</p>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Translation>
                                        </Col>
                                        <Col lg={2} xs={12} align={'bottom'} className='footerElement dash-border align-items-end supported' style={{ position: 'relative', paddingLeft: 13, paddingRight: 0 }}>
                                            <span style={{ paddingTop: 10, fontSize: 10, color: '#4f6d37' }}>Supported by </span>
                                            <Image src={VTLogo} style={{ height: 40 }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={10} xs={12}>
                                            <Row>
                                                <Col xs={12} lg={1} className="footerElement" style={{ paddingRight: 0 }}>
                                                    <Image src={VWhite} className="vvimage"></Image>
                                                </Col>
                                                <Col xs={12} lg={3} className="footerElement underlinedElement terms" style={{ paddingLeft: 0, paddingTop: 5 }}>
                                                    <Translation ns="footer">
                                                        {t =>
                                                            <a style={{ color: 'white', fontFamily: 'MontserratRegular', fontSize: 15, textDecoration: 'none' }}
                                                               href="/terms-and-conditions">
                                                                {t("terms")}
                                                            </a>
                                                        }
                                                    </Translation>
                                                </Col>
                                                <Col xs={12} lg={2} className="privacyColumn footerElement privacy" style={{ paddingTop: 5, paddingLeft: 0, paddingRight: 0 }}>
                                                    <Translation ns="footer">
                                                        {t =>
                                                            <a href='/privacy' style={{ color: 'white', fontFamily: 'MontserratRegular', fontSize: 15 }} >{t("privacy")}</a>
                                                        }
                                                    </Translation>
                                                </Col>
                                                <Col xs={12} lg={2} className="footerElement" style={{ paddingTop: 5, paddingRight: 0 }}>
                                                    <span href='#' style={{ color: 'white', fontFamily: 'MontserratRegular', fontSize: 15 }} >2017 Vvfood</span>
                                                </Col>
                                                <Col xs={12} lg={3} className="footerElement" style={{ paddingTop: 5 }}>
                                                    <Translation ns="footer">
                                                        {t =>
                                                            <span href='#' style={{ color: 'white', fontFamily: 'MontserratRegular', fontSize: 15 }} >{t("vat")} 02596530226</span>
                                                        }
                                                    </Translation>
                                                </Col>
                                                <Col lg={2} xs={12} className="footerElement" style={{ display: 'flex' }}></Col>
                                                <Col lg={2} xs={12} align={'bottom'} className='footerElement dash-border align-items-end supported-resp' style={{ position: 'relative', paddingLeft: 13, paddingRight: 0 }}>
                                                    <span style={{ paddingTop: 10, fontSize: 10, color: '#4f6d37' }}>Supported by </span>
                                                    <Image src={VTLogo} style={{ height: 40 }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={2} xs={12}></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Row>
        )
    }
}

export default Footer;
