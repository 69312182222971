import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import { Translation } from "react-i18next";
import '../../Style.css'

function PrivacyEn(props) {
    return <Translation ns='privacy'>
        {t =>
            <Container fluid>
                    <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                        <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                            <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold' }}>PRIVACY OF PERSONAL DATA</p>
                            <div className="privacySection" >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>{t("informativeTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span>{t("informative")}</span>
                                </p>
                            </div>
                            <div  >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>1. {t("dataControlTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span>{t("dataControl")}</span>
                                </p>
                            </div>
                            <div  >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>2. {t("dataNatureTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span> {t("dataNature")} </span>
                                </p>
                            </div>
                            <div  >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>3. {t("dataPurposeTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span>{t("dataPurposeFirst")}</span>
                                </p>
                                <p style={{lineHeight:'20px'}}>
                                    <span>{t("dataPurposeFirstListElement")}
                                        <br></br> {t("dataPurposeSecondListElement")}
                                        <br></br> {t("dataPurposeThirdListElement")}
                                        <br></br> {t("dataPurposeFourListElement")}
                                    </span>
                                </p>
                            </div>
                            <p  style={{lineHeight:'20px'}}>
                                <span>{t("dataPurposeSecond")}</span>
                            </p>
                            <div  >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>4. {t("methodTreatmentTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span>{t("methodTreatment")}</span>
                                </p>
                            </div>
                            <div >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>5. {t("communicationTitle")}</p>
                                <p style={{lineHeight:'20px'}}>
                                    <span>{t("communication")}</span>
                                </p>
                            </div>
                            <div  >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>6. {t("socialTitle")}</p>
                                <p style={{wordBreak:'break-all',lineHeight:'20px'}} >
                                    <span>{t("social")}
                                        <br></br>{t("facebook")}
                                        <br></br>{t("instagram")}
                                    </span>
                                </p>
                            </div>
                            <div  >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>7. {t("yourRightsTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span>{t("yourRightsFirst")}
                                        <br></br> <br></br>{t("yourRightsSecond")}
                                        <br></br> <br></br>{t("yourRightsFirstListElement")}
                                        <br></br>{t("yourRightsSecondListElement")}
                                        <br></br> <br></br>{t("yourRightsThird")}
                                        <br></br> <br></br>{t("yourRightsThirdListElement")};
                                        <br></br>{t("yourRightsFourListElement")}
                                    </span>
                                </p>
                            </div>
                            <div  >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>8. {t("linkWebsiteTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span>{t("linkWebsite")}</span>
                                </p>
                            </div>
                            <div  >
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>9. {t("contactsTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span>{t("contactsFirst")}
                                        <br></br> <br></br>{t("contactsSecond")}
                                        <br></br>{t("contactsThird")}</span>
                                </p>
                            </div>
                            <div>
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>10. {t("lawTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span>{t("law")}</span>
                                </p>
                            </div>
                            <div style={{ paddingBottom: "120px" }}>
                                <p style={{ fontFamily:'Montserrat-SemiBold' }}>11. {t("changesTitle")}</p>
                                <p  style={{lineHeight:'20px'}}>
                                    <span>{t("changesFirst")}
                                        <br></br> <br></br>{t("changesSecond")}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
        }
    </Translation>;
}

export default PrivacyEn;