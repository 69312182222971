import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "./Login.css";
import { Translation } from "react-i18next";
import AuthService from "../services/AuthService";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setGoToCheckout } from "../actions";
import { useHistory } from "react-router-dom";


/**
 * Login form
 */
function Login(props) {
    const [email, setEmail] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [errorUp, setErrorUp] = useState(false);
    const [errorDown, setErrorDown] = useState(false);
    const [errorText, setErrorText] = useState("");

    const history = useHistory();

    /**
     * Function for manage the submit of the form.
     */
    async function handleSubmit() {
        if (email === undefined && password === undefined) {
            setErrorDown(true);
        }
        if (email !== undefined && password === undefined) {
            if (!validateEmail(email)) {
                setErrorDown(true);
            } else {
                setErrorDown(true);
            }
        }
        if (email !== undefined && password !== undefined) {
            setErrorDown(false);
            if (!validateEmail(email)) {
                setErrorDown(true);
            } else {
                await AuthService.login(email, password).then(
                    () => {
                        if (props.goToCheckoutModal.toCheckout) {
                            props.setGoToCheckout(false);
                            const isIE = !!document.documentMode;
                            if (!isIE) {
                                window.location.href = '/checkout';
                            } else {
                                window.location.href = '/';
                            }
                        } else {
                            window.location.href = '/profile';
                        }
                    })
                    .catch(error => {
                        setErrorDown(true);
                    })
            }
        }
    }

    /**
     * Function for email validation.
     * @param {*} email
     */
    const validateEmail = email => {
        return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(email);
    }

    /**
     * Function for manage enter key press in the form.
     * @param {*} event
     */
    function handleKeyPress(event) {
        if (event.key === "Enter") {
            handleSubmit()
        }
    }

    /**
     * Function for manage email change.
     * @param {*} event
     */
    function handleEmail(event) {
        setEmail(event.target.value);
    }

    /**
     * Function for manage password change.
     * @param {*} event
     */
    function handlePassword(event) {
        setPassword(event.target.value);
    }

    return (
        <Container fluid>
            <Row>
                <Col sm={12}>
                    <Row className="justify-content-center" style={{ textAlign: 'center', marginTop: '50px', fontSizez: '35px' }}>
                        <Translation ns="login">
                            {t =>
                                <h4 style={{ textAlign: 'center' }}>
                                    <b style={{ textAlign: 'center', color: '#565751', fontFamily: 'MontserratMedium' }}>
                                        {t('signIn')}
                                    </b>
                                </h4>
                            }
                        </Translation>
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={7} md={9} lg={9} sm={12} style={{ paddingTop: '20px' }}>
                    <Form style={{ border: '3px solid', borderColor: 'white' }} onKeyPress={handleKeyPress}>
                        <Translation ns="login">
                            {t => <Form.Group controlId="formBasicEmail" style={{ paddingLeft: '10px', paddingRight: '10px', border: errorDown ? '2px solid red' : '2px solid #92c143' }}>
                                <Form.Label style={{ color: '#575b84k', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }} >{t("email")}</Form.Label>
                                <Form.Control className="loginInput" type="email" onChange={handleEmail} style={{ border: '0px solid', padding: '0px' }} />
                            </Form.Group>
                            }
                        </Translation>
                        <Translation ns="login">
                            {t => <Form.Group controlId="formBasicPassword" style={{ paddingLeft: '10px', paddingRight: '10px', border: errorDown ? '2px solid red' : '2px solid #92c143' }}>
                                <Form.Label style={{ color: '#575b84', textAlign: 'left', fontFamily: 'MontserratLight', paddingTop: '5px' }}>{t("password")}</Form.Label>
                                <Form.Control className="loginInput" type="password" onChange={handlePassword} style={{ border: '0px solid', padding: '0px' }} />
                            </Form.Group>
                            }
                        </Translation>
                    </Form>
                </Col>
            </Row>
            {errorDown === true ?
                <Row className="justify-content-center" style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Col xl={7} md={9} lg={9} sm={12}>
                        <Translation ns="login">
                            {t => <div style={{ textAlign: 'left', fontFamily: 'MontserratLight', fontWeight: 'bold', color: 'red' }}>{t('emailError')}</div>
                            }
                        </Translation>
                        <Translation ns="login">
                            {t => <div style={{ textAlign: 'left', fontFamily: 'MontserratLight', fontWeight: 'bold', color: 'red' }}>{t('passwordError')}</div>
                            }
                        </Translation>
                    </Col>
                </Row> :
                null
            }
            <Row className="justify-content-center">
                <Col xl={7} md={9} lg={9} sm={12}>
                    <Row>
                        <Col>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Translation ns="login">
                                {t => <Link to="/password_email" style={{ color: '#6c6d68', fontFamily: 'MontserratLight', textDecoration: 'underline' }}>
                                    <a>{t("forgotPassword")}</a>
                                </Link>
                                }
                            </Translation>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={7} md={9} lg={9} sm={12}>
                    <Translation ns="login">
                        {t => <Button className="loginButton" variant="primary" type="submit" style={{ border: '0px', width: '100%', borderRadius: "0px", backgroundColor: "#92c143", color: 'white', fontFamily: 'MontserratMedium', fontSize: '25px', marginTop: '20px', marginBottom: '20px' }} onClick={handleSubmit}>
                            {t("login")}
                        </Button>
                        }
                    </Translation>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col xl={7} md={9} lg={9} sm={12}>
                    <Link to="/register">
                        <Translation ns="login">
                            {t => <Button className="loginButton" variant="primary" type="submit" style={{ border: '0px', width: '100%', borderRadius: "0px", backgroundColor: "#f7e0a9", color: '#557627', fontFamily: 'MontserratMedium', fontSize: '25px' }} >
                                {t("register")}
                            </Button>
                            }
                        </Translation>
                    </Link>
                </Col>
            </Row>
            <Row className="justify-content-center" style={{ marginTop: '30px', marginBottom: '130px' }}>
                <Translation ns="login">
                    {t =>
                        <Col xl={7} md={9} lg={9} sm={12}>
                            <div style={{ fontFamily: 'MontserratLight', color: 'black', fontWeight: 'bold' }}>
                                {t("byLogging")}<Link to="/terms-and-conditions" style={{ color: '#6a832c', textDecoration: 'underline' }}>{t("terms")}</Link>
                            </div>
                            <div style={{ fontFamily: 'MontserratLight', color: 'black', fontWeight: 'bold' }}>
                                {t("readInf")} <Link to="/privacy" style={{ color: '#6a832c', textDecoration: 'underline' }}>{t("privacy")}</Link> {t("and")} <Link to="/cookies-policy" style={{ color: '#6a832c', textDecoration: 'underline' }}>{t("cookie")}</Link>
                            </div>
                        </Col>
                    }
                </Translation>
            </Row>
        </Container>
    );
}

/**,
 * Maps state to props.
 * @param state the state.
 */
const mapStateToProps = (state) => {
    return {
        goToCheckoutModal: state.goToCheckoutModal,
    };
};

export default connect(mapStateToProps, {
    setGoToCheckout,
})(Login);
