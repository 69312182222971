import React from "react";
import {Container} from "react-bootstrap";

const BecomePartnersIt = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold', textTransform: 'uppercase' }}>UN NUOVO STILE DI RISTORAZIONE</p>
                    <div className="privacySection" >
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>COS’E’ VVFOOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Vvfood è il portale innovativo che raccoglie i migliori ristoranti che vogliono gestire
                            direttamente il servizio di consegna per offrire un servizoo migliore alla propria
                            clientela ed avere un sostanziale risparmi sui costi di consegna. Se hai un ristorante e
                            sei interessato scrivici all’indirizzo&nbsp;
                            <a className="link" href="mailto:info@vvfood.it">info@vvfood.it</a> ti contatteremo il
                            prima possibile.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>QUALI SONO I VANTAGGI OFFERTI DA VVFFOD</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            <ol>
                                <li>Possibilità di personalizzare la propria offerta in modo semplice ed intuitivo</li>
                                <li>Risparmiare considerevolmente sul servizio delivery grazie all bassa commissione sul venduto……la più bassa sul mercato del food delivery</li>
                                <li>Nessuna fee di ingresso e nessun piano di abbonamento</li>
                                <li>Comunicare la mission del ristorante con foto, video e molto altro ancora...</li>
                                <li>Utilizzare i servizi offerti da Vvfood che hanno l’obiettivo di far crescere la tua azienda</li>
                                <li>Entrare a far parte della rete di ristoranti di qualità che si rivolgono ad un pubblico sempre più ampio e sempre più diversificato</li>
                            </ol>
                        </p>
                    </div>
                    <div style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e' }}>BASSO COSTO ALTO PROFITTO</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Vvfood a differenza dei competitor del food delivery, non ha l’obiettivo di guadagnare in modo speculativo sul food-cost,. La nostra mission è quella di creare un spazio virtuale di condivisione in cui il ristoratore può parlare si se e dei propri piatti, monitorare direttamente la consegna erogando in prima persona il trasporto e le modalità dello stesso, definire i tempi di produzione.
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default BecomePartnersIt;