import React from "react";
import {Container} from "react-bootstrap";

const CustomerServiceIt = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold', textTransform: 'uppercase' }}>SERVIZIO CLIENTI</p>
                    <div className="privacySection" style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold', fontSize: '18px', color: '#6a833e', textAlign: 'center' }}>Hai bisogno di assistenza per il tuo ordine? Siamo qui per te!</p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Contatta direttamente il ristorante in cui hai effettuato l’ordine, i riferimenti li trovi nella sezione “info ristorante” che trovi in alto a sinistra nella pagina ristorante.
                        </p>
                        <p style={{fontSize: '14px', lineHeight:'16px'}}>
                            Per garantire sempre la massima qualità del servizio e la soddisfazione degli utenti puoi
                            segnalarci problematiche e suggerimenti inviando una mail a:&nbsp;
                            <a className="link"
                               href="mailto:assistenza.vvfood@valuetech.it">
                                assistenza.vvfood@valuetech.it
                            </a>
                        </p>
                        <p style={{fontSize: '14px', lineHeight:'16px'}}>
                            Nel caso in cui si siano verificati dei disservizi o dei problemi durante il processo di
                            acquisto potete inviarci una breve descrizione dell’accaduto alla mail&nbsp;
                            <a className="link"
                               href="mailto:assistenza@vvfood.it">
                                assistenza@vvfood.it
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default CustomerServiceIt;