import {
    FETCH_MENUS,
    SELECT_MENU_TAG
} from '../actions/ActionTypes';

/**
 * RestaurantMenuReducer initial state.
 */
const initialState = {
    tags: [],
    menus:[]
}

/**
 * Restaurant menus reducer.
 * @param state
 * @param action action.
 */
export default (state = initialState, action) => {
  if (action.type === FETCH_MENUS) {
      return {
          ...state,
          menus: action.payload
      }
  } else if (action.type === SELECT_MENU_TAG) {
        return {
            menus: action.payload.menus,
            tags: action.payload.tags
        }
  }
  else {
      return state;
  }
};