import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import { Translation } from "react-i18next";
import '../../Style.css'

function PrivacyIt(props) {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold' }}>Privacy policy</p>
                    <div className="privacySection" >
                        <p style={{ fontFamily:'Montserrat-SemiBold' }}>Introduzione</p>
                        <p  style={{lineHeight:'20px'}}>
                            VvFood ha l’ambizione di essere la food community più attenta alla qualità dei prodotti,
                            alla attenzione per ed al radicamento nei territori, alla sostenibilità ambientale,
                            culturale ed ecologica dei prodotti commercializzati. Ispirati da questi valori non
                            possiamo non preoccuparci di tutelare efficacemente la privacy e la riservatezza di tutti
                            i membri della nostra community. Ciò significa che proteggeremo le informazioni personali
                            di tutti i visitatori che accedono ai nostri siti web o servizi attraverso qualsiasi app,
                            piattaforma o dispositivo mobile (collettivamente, i “Servizi”).
                            La presente Privacy Policy (“Privacy Policy”) spiega come VvFood raccoglie, condivide e
                            utilizza le tue informazioni personali. Troverai anche informazioni su come puoi
                            esercitare i tuoi diritti privacy. Utilizzando i nostri Servizi accetti che VvFood
                            utilizzi le tue informazioni personali come descritto in questa Privacy Policy. I termini
                            “noi”, “ci” o “nostro/i” si intendono riferiti a VvFood S.r.l. e qualsiasi termine non
                            definito nella presente Privacy Policy è definito nei nostri&nbsp;
                            <a className="link" href="/terms-and-conditions">Termini e Condizioni</a>.
                        </p>
                    </div>
                    <div>
                        <p style={{ fontFamily:'Montserrat-SemiBold' }}>Riassunto</p>
                        <p  style={{lineHeight:'20px'}}>
                            Abbiamo riassunto qui di seguito i punti principali della nostra Privacy Policy. Se desideri ulteriori informazioni su una sezione particolare, clicca sui link Per saperne di più su uno specifico argomento consulta direttamente la sezione relativa.
                            <ol>
                                <li>
                                    Le
                                    informazioni che raccogliamo da te e le ragioni<br/>
                                    Ecco
                                    le categorie generali delle informazioni personali che potremmo
                                    raccogliere su di te e le relative ragioni:
                                    <ol>
                                        <li>
                                            Informazioni
                                            che fornisci volontariamente:
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <ol>
                                <ol>
                                    <ul>
                                        <li>
                                            Informazioni
                                            di registrazione relative all’apertura di un account VvFood,
                                            così da:
                                        </li>
                                    </ul>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li>
                                                generare
                                                il tuo account al fine di consentirti di effettuare Ordini
                                                secondo i nostri&nbsp;
                                                <a className="link"
                                                   href="/terms-and-conditions">
                                                    Termini e Condizioni
                                                </a>;
                                            </li>
                                            <li>
                                                identificarti
                                                quando accedi al tuo account;
                                            </li>
                                            <li>
                                                contattarti
                                                per ricevere opinioni sui nostri Servizi; e
                                            </li>
                                            <li>
                                                avvisarti
                                                delle modifiche o aggiornamenti dei nostri Servizi.
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ul>
                                        <li>
                                            Informazioni
                                            su transazioni quando effettui un Ordine presso una Ditta
                                            venditrice al fine di:
                                        </li>
                                    </ul>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li value="1">
                                                elaborare
                                                il tuo Ordine ed emettere relativa fattura (anche se VvFood non
                                                memorizza mai informazioni relative alla tua carta di credito nei
                                                propri sistemi);
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li>
                                                comunicare
                                                il tuo Ordine alla Ditta venditrice;
                                            </li>
                                                <li>
                                                    aggiornarti
                                                    sullo status del tuo Ordine;
                                                </li>
                                                <li>
                                                    rispondere
                                                    alle richieste di informazioni in merito al tuo Ordine e
                                                    risolvere problemi;
                                                </li>
                                                <li>
                                                    svolgere
                                                    analisi e ricerche per sviluppare e migliorare i nostri Servizi;
                                                    e
                                                </li>
                                                <li>
                                                    proteggere
                                                    te e i Servizi cercando di rilevare e impedire frodi o altri atti
                                                    in violazione dei nostri&nbsp;
                                                    <a className="link" href="/terms-and-conditions">
                                                        Termini e Condizioni
                                                    </a> o politiche relative ai Servizi.
                                                </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ul>
                                        <li>
                                            Informazioni
                                            riguardanti le tue preferenze marketing, così
                                            da:
                                        </li>
                                    </ul>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li value="1">
                                                aggiornarti
                                                in relazione ai nostri prodotti o servizi.
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ul>
                                        <li>
                                            Commenti
                                            alle tue opinioni in merito ai nostri Servizi per:
                                        </li>
                                    </ul>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li value="1">
                                                rispondere
                                                alle tue richieste di informazioni;
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li>
                                                pubblicare
                                                recensioni che ci invii sulle
                                                nostre Ditte
                                                venditrici e servizi; e
                                            </li>
                                            <li>
                                                condurre
                                                analisi e ricerche per migliorare e sviluppare i nostri Servizi.
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <li value="2">
                                        Informazioni
                                        che raccogliamo automaticamente
                                    </li>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ul>
                                        <li>
                                            Informazioni
                                            sulle attività,
                                            al fine di:
                                        </li>
                                    </ul>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li>
                                                offrirti
                                                un servizio migliore;
                                            </li>
                                            <li>
                                                garantirti
                                                l’accesso
                                                alla cronologia personale degli Ordini e alle tue preferenze
                                            </li>
                                            <li>
                                                fornire
                                                altri servizi su tua richiesta.
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ul>
                                        <li>
                                            Cookie
                                            e tecnologie simili per:
                                        </li>
                                    </ul>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li value="1">
                                                misurare
                                                e analizzare l'utilizzo e l'efficacia dei nostri Servizi;
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li>
                                                personalizzare
                                                e ottimizzare il targeting della pubblicità
                                                per i nostri Servizi su altri siti web e piattaforme
                                            </li>
                                            <li>
                                                fornire
                                                servizi di localizzazione se scegli di condividere la tua
                                                posizione.
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <li value="3">
                                        Informazioni
                                        che otteniamo da fonti terze
                                    </li>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ul>
                                        <li>
                                            Relazioni
                                            analitiche e sondaggi per ricerche di mercato per:
                                        </li>
                                    </ul>
                                </ol>
                            </ol>
                            <ol>
                                <ol>
                                    <ol>
                                        <ol>
                                            <li>
                                                Misurare
                                                il rendimento di campagne marketing in relazione ai nostri
                                                Servizi
                                            </li>
                                            <li>
                                                comprendere
                                                al meglio le tue preferenze così
                                                da personalizzare le nostre campagne marketing e i nostri
                                                Servizi.
                                            </li>
                                        </ol>
                                    </ol>
                                </ol>
                            </ol>
                            <ol>
                                <li value="2">
                                    Elaboriamo
                                    le informazioni che fornisci volontariamente, quelle che raccogliamo
                                    automaticamente e le informazioni che otteniamo da fonti terze per
                                    migliorare costantemente i nostri Servizi. Grazie al loro utilizzo,
                                    riusciamo a semplificare la ricerca e la scelta del cibo che
                                    desideri e del luogo dal quale acquistare, a prescindere dal
                                    dispositivo che scegli di utilizzare o dalla parte del mondo in cui
                                    ti trovi.
                                </li>
                            </ol>
                            <ol start="3">
                                <li>
                                    Con
                                    chi condividiamo le informazioni personali?<br/>
                                    Potremmo
                                    condividere le tue informazioni personali con i seguenti destinatari
                                    (e, quando le condividiamo, per la loro protezione, lo facciamo nel
                                    rispetto degli standard di sicurezza adeguati e delle tutele
                                    contrattuali in essere faremo in modo di avere la sicurezza
                                    appropriata e le tutele contrattuali in essere per proteggerle):
                                    <ol>
                                        <li>
                                            Le
                                            Ditte venditrici presso i quali hai effettuato un Ordine per
                                            l'elaborazione e la consegna;
                                        </li>
                                        <li>
                                            Società
                                            VvFood del nostro gruppo;
                                        </li>
                                        <li>
                                            Terzi
                                            che sostengono i nostri Servizi ( a titolo esemplificativo, partner
                                            di logistica che effettuano la consegna del tuo ordine per conto
                                            della Ditta venditrice per il marketing o per le promozioni dei
                                            settori
                                            alimentari, delle bevande e dell'intrattenimento, sulla base delle
                                            tue preferenze);
                                        </li>
                                        <li>
                                            Qualsiasi
                                            organo di pubblica sicurezza o di regolamentazione, ente
                                            governativo, autorità
                                            giudiziaria o altro terzo, se riteniamo che sia necessaria una
                                            divulgazione secondo leggi o regolamenti applicabili;
                                        </li>
                                        <li>
                                            Nuovi
                                            azionisti o entità
                                            riorganizzate in caso di ristrutturazione aziendale, cessione,
                                            acquisto o joint venture che riguardano la nostra società.
                                        </li>
                                        <li>
                                            Qualsiasi
                                            altra persona, a condizione che abbia espresso il tuo consenso. Non
                                            venderemo, distribuiremo o altrimenti condivideremo mai i tuoi dati
                                            personali, senza il tuo consenso.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <br/>


                            <ol start="4">
                                <li>
                                    Basi
                                    legali per l'elaborazione delle informazioni personali<br/>
                                    VvFood
                                    si assicurerà sempre di avere una base giuridica per raccogliere e
                                    utilizzare le tue informazioni personali. La base giuridica nel
                                    rispetto della quale operiamo cambia a seconda del tipo di
                                    informazioni e del contesto nel quale le raccogliamo.<br/>
                                    La
                                    ragione principale della raccolta e utilizzo delle tue informazioni
                                    personali trova fondamento nell’esecuzione del contratto stipulato
                                    con te (ovvero, per assicurarci che tu riceva il tuo Ordine quando e
                                    dove lo vuoi), ma ci riserviamo il diritto di elaborarle laddove
                                    tale scelta sia sorretta da un nostro interesse commerciale
                                    legittimo .
                                </li>
                                <li>
                                    Trasferimenti
                                    di dati internazionali<br/>
                                    Potremmo
                                    trasferire le tue informazioni personali in Paesi al di fuori di
                                    quello in cui sei residente, in altri Paesi in cui VvFood o i nostri
                                    fornitori di servizio svolgono attività. Le leggi sulla protezione
                                    dei dati di questi Paesi potrebbero differire dalle leggi del tuo
                                    Paese, ma VvFood si impegnerà nel porre in essere e garantire
                                    tutele adeguate per proteggere le tue informazioni personali in in
                                    conformità con la presente Privacy Policy.
                                </li>
                                <li>
                                    Sicurezza<br/>
                                    VvFood
                                    attribuisce grande importanza alla protezione delle tue informazioni
                                    da e contro accessi non autorizzati e trattamenti illeciti, perdita
                                    accidentale, distruzione e danni. Adottiamo a tal proposito misure
                                    tecniche e organizzative adeguate per tutelare dette informazioni.
                                </li>
                                <li>
                                    Conservazione
                                    dei dati<br/>
                                    VvFood
                                    conserverà le tue informazioni personali per un lasso temporale
                                    necessario al perseguimento delle finalità descritte nella presente
                                    Privacy Policy. Potremmo anche conservare alcuni elementi di
                                    informazioni personali per un certo periodo, successivo alla
                                    cancellazione o disattivazione del tuo account per operazioni
                                    legittime, quali il mantenimento dei conti e per rispettare i nostri
                                    obblighi legali. Ogni volta che conserviamo tue informazioni, lo
                                    facciamo nel rispetto delle leggi applicabili.
                                </li>
                                <li>
                                    I
                                    diritti di protezione e di oblio dei tuoi dati<br/>
                                    Puoi
                                    accedere al tuo account in qualsiasi momento per analizzare e
                                    aggiornare le tue informazioni personali. Puoi anche contattarci per
                                    chiederci di cancellare le tue informazioni personali, limitarne
                                    l'elaborazione o richiedere che siano trasmesse a terzi. Hai anche
                                    il diritto di annullare la ricezione delle comunicazioni marketing
                                    che ti inviamo usando la funzionalità annulla in ogni comunicazione
                                    marketing che ricevi o modificando il tuo profilo o comunicandoci la
                                    tua scelta.
                                </li>
                                <li>
                                    Link
                                    a siti di terzi<br/>
                                    I
                                    nostri siti web possono, di volta in volta, contenere link di siti
                                    web terzi (per esempio, quelli dei nostri partner, inserzionisti e
                                    affiliati). Se segui questi link, sappi che VvFood non è
                                    responsabile per il loro operato di questi soggetti terzi, i quali
                                    hanno proprie privacy policy.
                                </li>
                                <li>
                                    Aggiornamenti
                                    della presente Privacy Policy.<br/>
                                    Potremmo
                                    aggiornare la presente Privacy Policy di volta in volta in risposta
                                    ai continui sviluppi giuridici, tecnici o commerciali. Ti
                                    incoraggiamo ad analizzare periodicamente questa pagina per essere
                                    aggiornato in relazione alle nostre pratiche di riservatezza.
                                </li>
                                <li>
                                    Come
                                    contattarci<br/>
                                    Se
                                    hai domande o preoccupazioni su questa Privacy Policy, puoi
                                    contattare il nostro Data Protection Officer.
                                </li>
                            </ol>
                        </p>
                    </div>
                    <div style={{ paddingBottom: "120px" }}>
                        <p style={{ fontFamily:'Montserrat-SemiBold' }}>Altre informazioni</p>
                        <p  style={{lineHeight:'20px'}}>
                            <ol>
                                <li>
                                    Informazioni
                                    che raccogliamo da te e ragioni<br/>
                                    Le
                                    informazioni personali che potremmo raccogliere su di te rientrano
                                    generalmente nelle seguenti categorie:
                                    <ol>
                                        <li>
                                            Informazioni
                                            che fornisci volontariamente.
                                            Alcune parti dei nostri Servizi potrebbero anche chiederti di
                                            fornire informazioni personali in modo volontario.<br/>
                                            Per
                                            esempio:
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <ul>
                                <ul>
                                    <ul>
                                        <li>
                                            Informazioni
                                            di registrazione:&nbsp;Quando
                                            crei un account VvFood, ti registri o riempi moduli rispetto ai
                                            Servizi, raccogliamo informazioni su di te, inclusi nome,
                                            indirizzo, numero di telefono, indirizzo email e la password che
                                            crei.
                                        </li>
                                        <li>
                                            Informazioni
                                            su transazioni:&nbsp;Raccogliamo
                                            informazioni relative ai tuoi Ordini, incluse le informazioni di
                                            pagamento (per esempio, il numero della tua carta di credito)
                                            usando i servizi sicuri dei nostri elaboratori di pagamento. Le
                                            operazioni di pagamento sono appaltate ai nostri elaboratori di
                                            pagamento e non memorizziamo le informazioni relative alla tua
                                            carta di credito nei nostri sistemi. Raccogliamo anche dettagli
                                            sulla consegna (per esempio il tuo indirizzo fisico) per
                                            completare ogni Ordine.
                                        </li>
                                        <li>
                                            Informazioni
                                            riguardanti le tue preferenze marketing:&nbsp;Raccogliamo
                                            informazioni sulle tue preferenze per ricevere informazioni
                                            marketing ogni volta che ti iscrivi o annulli l'iscrizione.
                                        </li>
                                        <li>
                                            Commenti:&nbsp;Quando
                                            posti messaggi e recensioni dei Servizi o ci contatti, per esempio
                                            con una domanda, un problema o un commento, raccogliamo
                                            informazioni su di te, inclusi il tuo nome e il contenuto della
                                            tua richiesta di informazioni. Se contatti i nostri team di
                                            supporto alla clientela, registreremo e conserveremo la
                                            registrazione della tua conversazione a scopi qualitativi
                                            formativi e per aiutarti a risolvere le tue richieste di
                                            informazione.
                                        </li>
                                        <li>
                                            Categorie
                                            particolari di dati personali:&nbsp;Raccogliamo
                                            le informazioni che fornisci quando ci contatti (ad esempio
                                            tramite il nostro call center o utilizzando i nostri moduli
                                            online). Queste informazioni possono includere categorie
                                            particolari di dati personali, come informazioni relative alla
                                            salute (allergie o esigenze alimentari) o informazioni che
                                            rivelino le tue convinzioni religiose (ad esempio, se puoi
                                            mangiare solo cibo preparato secondo i dettami della legge
                                            islamica). Noi non richiediamo queste informazioni e ti chiediamo
                                            di condividerle solo con la Ditta venditrice. Tuttavia, vi sono
                                            alcune situazioni in cui è comunque possibile che tu fornisca
                                            questo tipo di informazioni (ad esempio se si presenta un
                                            reclamo), e in quelle circostanze possiamo raccoglierle soltanto
                                            con il tuo consenso.
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                            <ol>
                                <ol>
                                    <li value="2">
                                        Informazioni
                                        che raccogliamo automaticamente.
                                        Quando accedi ai nostri Servizi, potremmo raccogliere alcune
                                        informazioni direttamente dal tuo dispositivo. In alcuni Paesi,
                                        inclusi Paesi in Europa, queste informazioni potrebbero essere
                                        considerate informazioni personali secondo leggi applicabili sulla
                                        protezione dei dati.<br/>
                                        Per
                                        esempio:
                                    </li>
                                </ol>
                            </ol>
                            <ul>
                                <ul>
                                    <ul>
                                        <li>
                                            Informazioni
                                            sulle attività:&nbsp;Raccogliamo
                                            informazioni sul tuo utilizzo dei Servizi e informazioni su di te
                                            dal contenuto che crei e dalle notifiche o dai messaggi che posti
                                            o invii o che emergono dalle tue ricerche che guardi o con cui
                                            interagisci.
                                        </li>
                                        <li>
                                            Cookie
                                            e tecnologie simili: Utilizziamo cookie e tecnologia di
                                            tracciamento simile per raccogliere e utilizzare informazioni
                                            personali su di te (a titolo esemplificativo il tuo indirizzo
                                            Internet Protocol (IP), l'ID del tuo dispositivo, il tuo tipo di
                                            browser quando, con quale frequenza e per quanto tempo interagisci
                                            con i Servizi), anche al fine di facilitare la pubblicità
                                            in relazione ai rispettivi interessi.
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                            <ol>
                                <ol>
                                    <li>
                                        Per
                                        ulteriori informazioni sui tipi di cookie e tecnologie simili che
                                        utilizziamo, le ragioni e le modalità
                                        di controllo delle stesse tecnologie, vedi la nostra&nbsp;
                                        <a className="link"href="/cookies-policy">Cookie Policy</a>.
                                    </li>
                                    <li>
                                        Informazioni
                                        che otteniamo da fonti terze. Di volta in volta, potremmo ricevere
                                        informazioni personali da fonti terze, inclusi network
                                        pubblicitari, ma solo se abbiamo verificato che i terzi hanno
                                        ricevuto il tuo consenso per elaborare le informazioni o hanno
                                        altrimenti l’autorizzazione
                                        legale o devono legalmente condividere le tue informazioni
                                        personali con noi. Queste informazioni potrebbero includere il tuo
                                        probabile gruppo demografico.<br/>
                                        Per
                                        esempio:
                                    </li>
                                </ol>
                            </ol>
                            <ul>
                                <ul>
                                    <ul>
                                        <li>
                                            Relazioni
                                            analitiche e sondaggi per ricerche di mercato:&nbsp;Raccogliamo
                                            informazioni da terzi affiliati sul modo in cui rispondi e
                                            interagisci con le nostre campagne marketing al fine di
                                            personalizzare il nostro marketing e i Servizi.
                                        </li>
                                        <li>
                                            Potremmo
                                            anche ricevere informazioni aggregate in forma di segmenti del
                                            pubblico da fonti terze per visualizzare pubblicità
                                            mirata su digital properties gestite da Ditte quali Facebook e
                                            Google.
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                            <ol>
                                <li value="2">
                                    Il
                                    nostro utilizzo delle informazioni raccolte<br/>
                                    Esempi
                                    di come potremmo usare queste informazioni includono:
                                </li>
                            </ol>
                            <ol>
                                <ol>
                                    <li>
                                        Informazioni
                                        che fornisci volontariamente
                                    </li>
                                </ol>
                            </ol>
                            <ul>
                                <ul>
                                    <ul>
                                        <li>
                                            Informazioni
                                            di registrazione.
                                            Le informazioni di registrazione che fornisci quando crei un
                                            account VvFood ci permettono di garantirti e darti accesso ai
                                            Servizi secondo i nostri&nbsp;
                                            <a className="link" href="/terms-and-conditions">Termini e Condizioni</a>.
                                            Usiamo queste informazioni anche per autenticare il tuo accesso ai
                                            Servizi, per contattarti e conoscere le tue opinioni e per
                                            avvisarti di modifiche o aggiornamenti importanti relativi ai
                                            nostri Servizi.
                                        </li>
                                        <li>
                                            Informazioni
                                            su transazioni.
                                            Le tue informazioni su transazioni (come gli articoli alimentari
                                            che aggiungi al tuo cestino) ci permettono di elaborare il tuo
                                            Ordine e di inviarti una fattura accurata. Aiuta anche noi e
                                            qualsiasi Ditta venditrice presso la quale hai ordinato se
                                            necessario.<br/>
                                            VvFood
                                            potrebbe contattarti per email, telefono o SMS per aggiornamenti
                                            su status o altre informazioni o per risolvere problemi in
                                            relazione al tuo conto o Ordine. Una Ditta venditrice potrebbe
                                            contattarti per telefono o SMS per aggiornamenti su status o altre
                                            informazioni relative al tuo Ordine.<br/>
                                            Alcune
                                            Ditte venditrici presenti nella nostra piattaforma si avvalgono di
                                            nostre società o terzi per la consegna; queste società
                                            potrebbero usare le tue informazioni per fornirti aggiornamenti di
                                            status sulla consegna del tuo Ordine.<br/>
                                            Analizzeremo
                                            anche informazioni sulle transazioni completate e gli Ordini
                                            consegnati con successo sulla base del confronto di dati o
                                            dell'analisi statistica, così da amministrare, supportare,
                                            migliorare e sviluppare la nostra Azienda e i nostri Servizi. Le
                                            nostre analisi ci aiutano anche a rilevare e a impedire frodi o
                                            altre attività illegali, o atti vietati dai nostri&nbsp;
                                            <a className="link" href="/terms-and-conditions">Termini e Condizioni</a> o
                                            da politiche applicabili ai Servizi.
                                        </li>
                                        <li>
                                            Informazioni
                                            riguardanti le tue preferenze marketing.
                                            VvFood può effettuare comunicazioni marketing, se permesso dalla
                                            legge, per posta, email, telefono, strumenti mobile (ovvero, SMS,
                                            messaggi in-app e notifiche push) sui nostri prodotti o
                                            Servizi.<br/>
                                            Se
                                            hai prestato il tuo consenso, potremmo condividere le tue
                                            informazioni con terzi (per esempio, altre società) in altri
                                            settori (quali alimentare, di bevande e di intrattenimento), in
                                            modo che possano avvisarti su beni o servizi che potrebbero essere
                                            di tuo interesse.<br/>
                                            Se
                                            non vuoi che utilizziamo i tuoi dati in questo modo o se in
                                            generale cambi opinione sul ricevimento di comunicazione
                                            marketing, puoi annullare l'abbonamento in qualsiasi momento
                                            usando la funzionalità di annullamento nelle comunicazioni che
                                            ricevi o modificando il tuo profilo o comunicandoci la tua scelta.
                                            Puoi anche effettuare l'opt-out dalle notifiche push a livello di
                                            dispositivo mobile (accedendo alla sezione “Account”
                                            all’interno delle app iOS e Android). Se scegli di annullare il
                                            ricevimento di comunicazioni marketing, terremo una registrazione
                                            delle tue preferenze, in modo da non disturbarti con attività di
                                            marketing indesiderate in futuro.
                                        </li>
                                        <li>
                                            Commenti
                                            e recensioni Se ci fornisci i tuoi commenti, li useremo per
                                            rispondere alle tue domande o alle richieste di informazioni, per
                                            fornire supporto alla clientela e risolvere eventuali problemi
                                            relativi ai tuoi Ordini o ai Servizi. Pubblicheremo le recensioni
                                            che ci invierai in relazione ai Ristoranti presenti nella nostra
                                            piattaforma e sui nostri Servizi in generale e analizzeremo anche
                                            i tuoi commenti per sviluppare i nostri Servizi e migliorare la
                                            tua esperienza.
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                            <ol>
                                <ol>
                                    <li value="2">
                                        Informazioni
                                        che raccogliamo automaticamente Quando accedi ai nostri Servizi,
                                        potremmo raccogliere alcune informazioni direttamente dal tuo
                                        dispositivo, quali l'indirizzo IP, l'indirizzo MAC o l'ID. In
                                        alcuni Paesi, come in Europa, queste informazioni potrebbero essere
                                        considerate informazioni personali secondo leggi applicabili alla
                                        protezione dei dati.
                                    </li>
                                </ol>
                            </ol>
                            <ul>
                                <ul>
                                    <ul>
                                        <li>
                                            Informazioni
                                            sulle attività.
                                            Analizziamo le tue abitudini di acquisto e come l’interazione
                                            con i nostri Servizi, così da fornirti suggerimenti su piatti di
                                            nuovi ristoranti che pensiamo potresti voler provare. Se scegli di
                                            condividere la tua posizione con noi, potremmo usare queste
                                            informazioni per mostrarti i contenuti nelle tue vicinanze; queste
                                            informazioni contribuiranno anche a rendere efficace e rapida la
                                            consegna del tuo Ordine. Possiamo anche fornirti contenuto
                                            personalizzato e pubblicità basata sulle tue interazioni
                                            precedenti con i nostri Servizi e in virtù delle nostre
                                            conoscenze in merito alle tue preferenze, che traiamo dal tuo
                                            utilizzo e dalla storia degli acquisti relativi ai nostri
                                            Servizi.<br/>
                                            Ti
                                            permettiamo di accedere alla cronologia dei tuoi Ordini, in modo
                                            che tu possa facilmente riordinare presso le tue Ditte venditrici
                                            preferite. Potremmo anche raccogliere informazioni sulla tua
                                            interazione con le nostre email e altre forme di comunicazione.
                                        </li>
                                        <li>
                                            Cookie
                                            e tecnologie simili.
                                            Lavoriamo con affiliati pubblicitari che effettuano inserzioni
                                            pubblicitarie sui nostri Servizi sui siti e servizi di terzi.
                                            Alcuni di questi network utilizzano cookie che ci permettono di
                                            visualizzare pubblicità personalizzate rispetto ai tuoi
                                            interessi, sulla base dell'attività di navigazione su
                                            internet.<br/>
                                            Come
                                            molti siti web e servizi online, utilizziamo cookie e altre
                                            tecnologie per registrare la tua interazione con i nostri Servizi.
                                            I cookie ci aiutano anche a gestire una gamma di funzioni e
                                            contenuto, oltre a memorizzare le ricerche e ripresentare le tue
                                            informazioni al momento dell'Ordine. Per ulteriori informazioni
                                            sui tipi di cookie e tecnologie simili che utilizziamo, le ragioni
                                            , e rispettive modalità di controllo vedi la nostra&nbsp;
                                            <a className="link" href="/cookies-policy">Cookie Policy</a>.
                                        </li>
                                        <li>
                                            Informazioni
                                            che otteniamo da fonti terze:
                                        </li>
                                        <li>
                                            Relazioni
                                            analitiche e sondaggi per ricerche di mercato.
                                            Analizziamo informazioni che raccogliamo dai nostri fornitori di
                                            servizi terzi (quali Google, Facebook, Amazon e altre affiliate)
                                            per capire come interagisci con i nostri Servizi e le nostre
                                            inserzioni (per esempio, se hai cliccato su una delle nostre
                                            inserzioni). Ciò ci aiuta a comprendere le tue preferenze e a
                                            consigliarti i Servizi che risultano compatibili con i tuoi
                                            interessi.
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                            <br/>


                            <ol start="3">
                                <li>
                                    Con
                                    chi condividiamo le informazioni personali?<br/>
                                    Nella
                                    consegna dei Servizi, a seconda delle circostanze, potremmo
                                    condividere le tue informazioni personali con le seguenti entità:
                                    <ol>
                                        <li>
                                            Ditte
                                            venditrici presso le quali effettui Ordini, in modo che la Ditta
                                            venditrice possa elaborarli ed effettuare le consegne.
                                        </li>
                                        <li>
                                            Società
                                            nel gruppo di società VvFood (ovvero le nostre controllate e
                                            affiliate , la nostra holding e sue controllate, affiliate o
                                            collegate): queste società agiscono per noi ed elaborano le tue
                                            informazioni personali peri fini descritti nella presente Privacy
                                            Policy.
                                        </li>
                                        <li>
                                            Terzi
                                            che svolgono varie attività
                                            per la promozione e il supporto dei nostri Servizi. Questi
                                            comprendono agenti di supporto alla clientela offshore, fornitori
                                            di assistenza e hosting di siti web e app, fornitori di servizi
                                            marketing, partner eCRM, che gestiscono le nostre email marketing e
                                            le notifiche push, società che ti inviano messaggi quando l'Ordine
                                            è in arrivo, società di consegna che ti consegnano l'Ordine,
                                            società di ricerca di mercato e fornitori di elaborazione dei
                                            pagamenti che elaborano le transazioni delle carte di pagamento; i
                                            sopramenzionati terzi potrebbero trovarsi all'interno o all'esterno
                                            del tuo Paese di residenza.
                                        </li>
                                        <li>
                                            Come
                                            indicato nella sezione 2 di cui sopra, potremmo anche condividere
                                            le informazioni con terzi (a titolo esemplificativo nei settori
                                            alimentari , delle bevande e dell'intrattenimento) in modo che
                                            possano contattarti per informarti su beni o servizi che potrebbero
                                            essere di tuo interesse. Condivideremo le tue informazioni con
                                            questi terzi solo se avrai prestato il relativo consenso. Se non
                                            vuoi che utilizziamo le tue informazioni personali in questo modo o
                                            se in generale cambi opinione sul ricevimento di comunicazioni
                                            marketing, puoi annullare l'iscrizione in qualsiasi momento usando
                                            la funzionalità
                                            di annullamento delle comunicazioni che ricevi o modificando il tuo
                                            profilo o comunicandoci la tua scelta. Se scegli di annullare il
                                            ricevimento di comunicazioni marketing, terremo una registrazione
                                            delle tue preferenze, in modo che non ti disturberemo con attività
                                            di marketing indesiderato in futuro.
                                        </li>
                                        <li>
                                            Con
                                            riferimento a qualsiasi asset della nostra società,
                                            qualora sottoscrivessimo una joint venture, acquistassimo un'altra
                                            Ditta o in caso di vendita o di fusione con un'altra entità
                                            commerciale, le tue informazioni potrebbero essere divulgate o
                                            trasferite alla società target, o ai nuovi partner commerciali o
                                            azionisti o i loro agenti e consulenti. In queste circostanze,
                                            informeremo sempre le entità rilevanti che dovranno trattare le
                                            tue informazioni personali solamente per le finalità divulgate in
                                            questa Privacy Policy.
                                        </li>
                                        <li>
                                            Qualsiasi
                                            organo di pubblica sicurezza o di regolamentazione, ente
                                            governativo, autorità
                                            giudiziaria o altro terzo, se riteniamo che sia necessaria una
                                            divulgazione (i) secondo leggi o regolamenti applicabili, (ii) per
                                            esercitare stabilire o difendere i nostri diritti legali, o (iii)
                                            per proteggere i tuoi interessi vitali o quelli di altre persone.
                                        </li>
                                        <li>
                                            Qualsiasi
                                            altra persona, a condizione che abbia prestato il tuo consenso alla
                                            divulgazione. Non venderemo, distribuiremo a qualsiasi titolo le
                                            tue informazioni personali, salvo previo tuo consenso o laddove lo
                                            richieda la legge.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                            <br/>


                            <ol start="4">
                                <li>
                                    Basi
                                    giuridiche per l'elaborazione delle informazioni personali<br/>
                                    La
                                    nostra base giuridica per la raccolta e l'utilizzo delle tue
                                    informazioni personali come sopra descritte dipenderà dal contesto
                                    specifico nel quale le raccogliamo.<br/>
                                    La
                                    ragione principale per la quale raccogliamo e utilizziamo le tue
                                    informazioni personali risiede nell’esecuzione del contratto
                                    stipulato con te (ovvero, vogliamo assicurarci che riceverai il tuo
                                    Ordine quando e dove lo desideri). Tuttavia, utilizzeremo anche le
                                    tue informazioni personali allorquando tale attività rientri nel
                                    nostro interesse commerciale legittimo (esclusivamente quando i
                                    nostri interessi non confliggono con gli interessi di protezione dei
                                    tuoi dati o con i tuoi diritti legali e con le libertà
                                    fondamentali). In alcuni casi, potremmo avere un obbligo legale di
                                    raccogliere le tue informazioni personali (per esempio nel caso di
                                    un procedimento legale) o potremmo dover elaborarle o condividerle
                                    con altri soggetti per &quot;proteggere i tuoi interessi vitali”
                                    (&quot;salvare la tua vita&quot;) o quelli di un'altra persona (per
                                    esempio se la vita di un'altra persona è in pericolo).<br/>
                                    Se
                                    ti chiediamo di fornire informazioni personali per rispettare un
                                    requisito legale o per eseguire un contratto con te, lo preciseremo
                                    al momento opportuno e ti avviseremo se fornire tue informazioni
                                    personali sia obbligatorio o meno (ed illustreremo anche le
                                    possibili conseguenze nel caso in cui tu non ce le
                                    fornisca).<br/>
                                    Analogamente,
                                    raccogliamo ed utilizziamo le tue informazioni personali basandoci
                                    sui nostri legittimi interessi (o su quelli di terzi). Tali
                                    legittimi interessi includono a) la possibilità di commercializzare
                                    e proporti offerte che pensiamo potrebbero interessarti; b)
                                    analizzare e comprendere come i nostri servizi vengono utilizzati al
                                    fine di migliorarli; c) la prevenzione di frodi o altri reati; o d)
                                    mantenere sicuri i nostri sistemi.<br/>
                                    Se
                                    hai domande o necessiti di ulteriori informazioni sulla base
                                    giuridica in virtù della quale raccogliamo e utilizziamo le tue
                                    informazioni personali, contattaci usando i dettagli di contatto
                                    forniti sotto al titolo &quot;Come contattarci”.<br/>
                                    <br/>


                                    <li>
                                        Trasferimenti
                                        di dati internazionali<br/>
                                        Potremmo
                                        trasferire le tue informazioni personali in Paesi diversi dal Paese
                                        in cui sei residente. I server del sito web VvFood operano
                                        principalmente in Italia; tuttavia, altre società del nostro gruppo
                                        e fornitori di servizi terzi operano in diversi Paesi inclusi
                                        Australia, Brasile, Bulgaria, Canada, Colombia, Danimarca, Francia,
                                        Irlanda, Mauritius, Messico, Norvegia, Nuova Zelanda, Filippine,
                                        Spagna, Svizzera, Ucraina e Regno Unito. Ciò significa che quando
                                        raccogliamo le tue informazioni personali, possiamo trattarle in uno
                                        o più di questi Paesi.<br/>
                                        Allorquando
                                        le leggi sulla protezione dei dati di questi Paesi differiscano
                                        dalle leggi del tuo Paese, VvFood farà in modo di garantire tutele
                                        appropriate per proteggere le tue informazioni personali in quei
                                        Paesi in conformità con la presente Privacy Policy. Alcune delle
                                        tutele che adottiamo, ove appropriato, includono: l'utilizzo di
                                        clausole contrattuali standard approvate dalla Commissione Europea
                                        con i nostri fornitori, contratti di trasferimento infra-gruppo (in
                                        modo che possiamo trasferire in sicurezza i tuoi dati tra le società
                                        del gruppo VvFood in tutto il mondo) e la stipula di contratti con
                                        società certificate Privacy Shield negli Stati Uniti.<br/>
                                        <br/>


                                        <li>
                                            Sicurezza<br/>
                                            Attribuiamo
                                            grande importanza alla tenuta in sicurezza delle tue informazioni
                                            personali. Adottiamo quindi misure tecniche e organizzative
                                            appropriate / tecnologia standard del settore per proteggere i dati
                                            dall'accesso non autorizzato e da trattamenti illeciti, perdita
                                            accidentale, distruzione e danni.<br/>
                                            Le
                                            misure di sicurezza da noi utilizzate sono progettate per fornire un
                                            livello di protezione di sicurezza adeguato al rischio nel
                                            trattamento delle tue informazioni personali. Se hai scelto una
                                            password che ti permette di accedere ad alcune parti dei Servizi,
                                            sei responsabile di tenerla riservata. Ti consigliamo di non
                                            condividere la password con nessuno e di utilizzare una password
                                            unica per i nostri Servizi. Non saremo responsabili per transazioni
                                            non autorizzate effettuate usando il tuo nome e la tua password.<br/>
                                            <br/>


                                            <li>
                                                Conservazione
                                                dei dati<br/>
                                                VvFood
                                                conserverà le tue informazioni personali laddove vi sia un
                                                interesse commerciale legittimo (per esempio, quando hai un conto
                                                con noi o per permetterci di rispettare i nostri obblighi legali,
                                                fiscali o contabili).<br/>
                                                Se
                                                ti opponi al nostro trattamento di alcune categorie delle tue
                                                informazioni personali (anche in relazione al ricevimento di
                                                comunicazioni marketing da parte nostra), conserveremo una
                                                registrazione della tua opposizione al trattamento delle tue
                                                informazioni, nel rispetto delle tue volontà.<br/>
                                                Distruggeremo
                                                o renderemo permanentemente anonime le tue informazioni personali al
                                                punto da non doverle o volerle più elaborare per le nostre
                                                necessità commerciali legittime o per ragioni legali. Se ciò non è
                                                possibile (per esempio perché
                                                le
                                                tue informazioni personali sono state memorizzate in archivi di
                                                backup), le memorizzeremo in modo sicuro e le isoleremo da ulteriori
                                                elaborazioni finché
                                                non
                                                sarà possibile cancellarle.<br/>
                                                <br/>


                                                <li>
                                                    I
                                                    diritti di protezione dei tuoi dati<br/>
                                                    VvFood
                                                    rispetta i diritti sulla riservatezza e la protezione dei dati.
                                                    Segue un riepilogo dei tuoi diritti rispetto alle tue informazioni
                                                    personali conservate da VvFood :
                                                    <ol>
                                                        <li>
                                                            VvFood
                                                            ti fornisce gli strumenti per accedere, analizzare o aggiornare le
                                                            tue informazioni personali in qualsiasi momento attraverso il tuo
                                                            account. Se desideri richiedere la cancellazione delle tue
                                                            informazioni personali, puoi farlo contattandoci usando i dettagli
                                                            di contatto forniti sotto al titolo
                                                            “Come contattarci”.
                                                        </li>
                                                        <li>
                                                            Puoi
                                                            opporti all'elaborazione delle tue informazioni personali,
                                                            chiederci di limitare l'elaborazione delle tue informazioni
                                                            personali o richiedere che siano trasferite a terzi. Anche in
                                                            questo caso , puoi esercitare i diritti di cui sopra contattandoci
                                                            ed usando i dettagli di contatto forniti sotto al titolo“Come
                                                            contattarci”.
                                                        </li>
                                                        <li>
                                                            Hai
                                                            il diritto di scegliere di non ricevere più
                                                            comunicazioni marketing che ti inviamo in qualsiasi momento. Puoi
                                                            esercitare questo diritto usando la funzionalità di annullamento
                                                            dell'iscrizione per il ricevimento delle comunicazioni o
                                                            modificando il tuo profilo.
                                                        </li>
                                                        <li>
                                                            Analogamente,
                                                            se raccogliamo ed elaboriamo le tue informazioni personali sulla
                                                            base del tuo consenso, puoi revocare il tuo consenso in qualsiasi
                                                            momento. La revoca del tuo consenso non inficia la legalità
                                                            di trattamento che abbiamo condotto prima della tua revoca, né
                                                            inficia
                                                            il trattamento delle tue informazioni personali condotte in
                                                            relazione ad altre basi legittime di trattamento diverse dal
                                                            consenso.
                                                        </li>
                                                        <li>
                                                            Hai
                                                            il diritto di reclamare presso un'autorità
                                                            di sorveglianza sulla protezione dei dati in merito alla raccolta e
                                                            all'utilizzo delle tue informazioni personali.
                                                        </li>
                                                    </ol>
                                                </li>
                                            </li>
                                        </li>
                                    </li>
                                </li>
                            </ol>
                            <ol>
                                <li value="11">
                                    Rispondiamo
                                    a tutte le richieste che riceviamo dalle persone fisiche che
                                    intendono esercitare i propri diritti sui dati in conformità alle
                                    leggi sulla protezione dei dati applicabili
                                </li>
                            </ol>
                            <ol start="12">
                                <li>
                                    Link
                                    a siti di terzi<br/>
                                    Il
                                    nostro sito web può, di volta in volta, contenere link per e da
                                    siti web di nostri partner, inserzionisti e affiliate o di un
                                    indeterminato numero di siti web che potrebbero offrire informazioni
                                    utili ai nostri visitatori. Ti forniamo questi link solo per
                                    comodità e l'inclusione di link non implica l'avallo del sito web
                                    rilevante da parte di VvFood. Se accedi al link o a uno o più siti
                                    web, nota che le società di riferimento hanno proprie privacy
                                    policy e che non saremo responsabili per le loro policy o per i siti
                                    web linkati. Verifica queste policy prima di inviare informazioni
                                    personali a detti siti web.
                                </li>
                                <li>
                                    Aggiornamenti
                                    a questa Privacy Policy<br/>
                                    Potremmo
                                    aggiornare questa Privacy Policy di volta in volta in risposta ai
                                    continui sviluppi giuridici, tecnici o commerciali. Quando
                                    aggiorniamo la nostra Privacy Policy, adottiamo misure appropriate
                                    per informarti, in linea con l'importanza delle modifiche da noi
                                    svolte. Otterremo il tuo consenso verso ogni modifica sostanziale
                                    della Privacy Policy se richiesto da leggi applicabili sulla
                                    protezione dei dati .<br/>
                                    Ti
                                    incoraggiamo ad analizzare periodicamente questa pagina per restare
                                    aggiornato sulle ultime informazioni relative alle nostre pratiche
                                    di riservatezza.
                                </li>
                                <li>Come
                                    contattarci<br/>
                                    Se
                                    hai domande o preoccupazioni su questa Privacy Policy, puoi
                                    contattare il nostro Data Protection Officer:<br/>
                                    Per
                                    email a:&nbsp;
                                    <a className="link" href="mailto:privacy@vvfood.it">privacy@vvfood.it</a><br/>
                                    Scrivendoci
                                    a:<br/>
                                    VvFood
                                    S.r.l. - Data Protection Officer, via Gocciadoro n. 4, 38122 Trento
                                    (TN), ITALIA.<br/>
                                    Il
                                    titolare del trattamento dati delle tue informazioni personali è
                                    l'entità
                                    VvFood S.r.l.
                                </li>
                            </ol>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default PrivacyIt;