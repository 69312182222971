import {
    SHOW_USER_MODAL,

} from '../actions/ActionTypes';

/**
 * Initial state.
 * @type {{isVisible: boolean}}
 */
const initialState = {
    isVisible: false,
};

/**
 * Restaurant closed modal reducer.
 * @param state state.
 * @param action action.
 * @returns {{isVisible: *}|{isVisible: boolean}}
 */
export default (state = initialState, action) => {
    if (action.type === SHOW_USER_MODAL) {
        return {
            isVisible: action.payload,
        };
    } else {
        return state;
    }
}
