import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

/**
 * Translations file.
 */
const enResource = require('./en');
const itResource = require('./it');

const resources = Object.assign(enResource, itResource);

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'it',

        keySeparator: true, // if we use keys in form messages.something or not

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
