import React from "react";
import {Container} from "react-bootstrap";

const CustomerServiceEn = props => {
    return (
        <Container fluid>
            <div className="privacyBody" style={{ fontFamily: 'MontserratRegular' }}>
                <div style={{ textAlign: "justify", textJustify: "inter-word" }}  >
                    <p className="privacyTitle" style={{ textAlign: "center", marginTop: "70px", color: '#6a833e', fontWeight: 'bold', textTransform: 'uppercase' }}>CUSTOMER SERVICE</p>
                    <div className="privacySection" style={{ paddingBottom: "120px" }}>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            If you need some help with your order please contact the restaurant, Vvfood is not responsible for it. Restaurant contact details can be found on the restaurant page.
                        </p>
                        <p  style={{fontSize: '14px', lineHeight:'16px'}}>
                            Our customer care team is on hand to support our customers with any outstanding queries or
                            complaints. For any objection or suggestion please write us&nbsp;
                            <a className="link"
                               href="mailto:assistenza.vvfood@valuetech.it">
                                assistenza.vvfood@valuetech.it
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default CustomerServiceEn;