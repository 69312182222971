import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import SearchLocationInput from './SearchLocationInput'
import {
    setCart,
    setCartIndex,
    displayModalCart,
    hideModalCart,
    fetchMenus,
    fetchDish,
    fetchPickUpTimes,
    setPickUpHour,
    setPickUpMinute,
    selectDish,
    setQuantity,
    setChecked,
    setTotalPrice,
    validateMinOrder,
    setMinOrderErrModalVisibility,
    fetchDeliveryTimes,
    selectOrderType,
    setDeliveryMinute,
    setDeliveryHour,
    setDeliveryOpened,
    setGoToCheckoutModalVisibility,
    setCartRestaurantSlug,
    setCartNote,
    setRestaurantId,
    setDeliveryAvailable,
    setDeliveryAddress
} from "../actions";
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import './Cart.css';
import { Translation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AuthService from "../services/AuthService";
import ShoppingCartIcon from '../assets/image/shopping-cart.svg';
import {ReactComponent as EditItemIcon} from '../assets/image/cart-item-edit.svg';
import {ReactComponent as RemoveItemIcon} from '../assets/image/cart-item-remove.svg';
import {withRouter} from 'react-router';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import Geocode from '../utility/GoogleGeocode';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

/**
 * Tooltip button for edit remove dish button on cart.
 * @param isEdit button for edit functionality or not.
 * @param text the tooltip text
 * @returns {*}
 * @constructor
 */
const EditRemoveDish = ({ isEdit = false, text }) => (
    <OverlayTrigger
        placement="top"
        overlay={
            <Tooltip className={'cart-dish-custom-tooltip'} style={{ fontFamily: "MontserratRegular", fontWeight: 'bold' }}>{text}</Tooltip>
        }
    >
        {isEdit ? <EditItemIcon /> : <RemoveItemIcon />}
    </OverlayTrigger>
);

/**
 * Cart component
 */
class Cart extends React.Component {
    constructor() {
        super();
        this.state = { showedDeliveryMessage: false, fieldOpen: false, value:'', hasStreetError: false }
        this.noteInput = React.createRef();
        this.focusNote = this.focusNote.bind(this);
    }

    componentDidMount = async () => {
        await this.props.fetchPickUpTimes(this.props.id);
        if (this.props.cart.pickUpTimes.size > 0) {
            const firstHour = [...this.props.cart.pickUpTimes.keys()][0];
            const firstMinute = this.props.cart.pickUpTimes.get(firstHour)[0];
            this.props.setPickUpHour(firstHour);
            this.props.setPickUpMinute(firstMinute);
        }
        await this.props.fetchDeliveryTimes(this.props.id);
        if (this.props.cart.deliveryTimes.size > 0) {
            const firstHour = [...this.props.cart.deliveryTimes.keys()][0];
            const firstMinute = this.props.cart.deliveryTimes.get(firstHour)[0];
            this.props.setDeliveryHour(firstHour);
            this.props.setDeliveryMinute(firstMinute);
        }
        if (this.props.restaurants.address && this.props.restaurants.address.trim() !== "") {
            this.props.setDeliveryAddress(this.props.restaurants.address)
        }
        this.deliveryClosed();
    };

    /**
     * method to open cart modal
     * @param id
     * @param quantity
     * @param addons
     * @param index
     */
    openCartModal = (id, quantity = -1, addons = new Array(), index = -1) => {
        this.props.selectDish(id);
        this.props.fetchDish(id);
        if (quantity !== -1)
            this.props.setQuantity(quantity);
        if (addons.length > 0)
            this.props.setChecked(addons);
        this.props.setCartIndex(index);
        this.props.displayModalCart();
        if (index !== -1) {
            this.props.setTotalPrice(this.props.cart.cartContent[index].modal.totalPrice);
        }
    };

    /**
     * method to calculate sum of total dish prices
     * @param {*} cart
     */
    calculateSum = (cart) => {
        var totalSum = 0;
        cart.forEach((v, k) => {
            totalSum += parseFloat(v.modal.totalPrice);
        })
        return totalSum;
    }

    /**
     * method to calculate sum of total dish prices
     * @param {*} cart
     */
    calculateSumWithDelivery = (cart) => {
        var totalSum = 0;
        cart.forEach((v, k) => {
            totalSum += parseFloat(v.modal.totalPrice);
        });
        totalSum += parseFloat(this.props.cart.deliveryPrice);
        return totalSum;
    };


    /**
     * method to set pick up hours
     * @param {*} e
     */
    setHours(e) {
        this.props.setPickUpHour(e.target.value);
        const hoursFirstMin = this.props.cart.pickUpTimes.get(e.target.value)[0];
        this.props.setPickUpMinute(hoursFirstMin);
    }

    /**
     * method to set pick up minutes
     * @param {*} e
     */
    setMinutes(e) {
        this.props.setPickUpMinute(e.target.value);
    }

    /**
     * method to set delivery hours
     * @param {*} e
     */
    setDeliveryHours(e) {
        this.props.setDeliveryHour(e.target.value);
        const hoursFirstMin = this.props.cart.deliveryTimes.get(e.target.value)[0];
        this.props.setDeliveryMinute(hoursFirstMin);
    }

    /**
     * method to set delivery minutes
     * @param {*} e
     */
    setDeliveryMinutes(e) {
        this.props.setDeliveryMinute(e.target.value);
    }

    /**
     * method to remove dish from cart
     * @param {*} i
     */
    removeFromCart = (i) => {
        var filtered = this.props.cart.cartContent.filter(function (value, index, arr) { return index !== i; });
        this.props.setCart(filtered);
    };

    proceedWithOrder = async () => {
        var baseJson = {
            orderDeliveryStatus: this.props.cart.orderType === 'delivery' ? 1 : 0,
            orderPrice: this.calculateSum(this.props.cart.cartContent).toFixed(2),
            restaurantId: this.props.restaurant.id
        };
        if (this.props.cart.cartContent.length <= 0 || !this.canProceed()) {
            return;
        }
        await this.props.validateMinOrder(baseJson);

        if (this.props.cart.orderType !== 'pick-up') {
            await this.checkDeliveryAvailability(true);
            var hasStreet = await this.checkStreetInAddress();

            if (!this.props.cart.deliveryAvailable || !hasStreet) {
                this.setState({ showedDeliveryMessage: true })
                if (!hasStreet) {
                    this.setState({ hasStreetError: true })
                }
                setTimeout(() => {
                    this.setState({ showedDeliveryMessage: false })
                    this.setState({ hasStreetError: false })
                }, 5000);
                return;
            }
        }

        await this.props.setRestaurantId(this.props.id)
        if (!this.props.cart.isMinOrderErrVisible) {
            if (AuthService.getCurrentUserId() == null) {
                await this.props.setGoToCheckoutModalVisibility(true);
            } else {
                this.props.history.push('/checkout')
            }
        }
    };

    /**
     * Method for check if delivery is already avaiable
     */
    deliveryClosed() {
        var d = new Date();
        var n = d.getDay() - 1;
        var x = n === -1 ? 6 : n;
        var delivery = this.props.restaurant.deliveryHours[x];
        var time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        if (time < delivery.startTime) {
            this.props.setDeliveryOpened(true)
        } else {
            this.props.setDeliveryOpened(false)
        }
    }

    /**
     * check if in the address there is the street
     */
    async checkStreetInAddress() {
        var hasStreet = true;
        var comp = await Geocode(this.props.cart.deliveryAddress)
        if (comp instanceof Map) {
            if (!comp.has('street')) {
                hasStreet = false;
            }
        }
        return hasStreet;
    }

    async checkDeliveryAvailability(submit = false) {
        if (this.props.cart.deliveryAddress != null) {
            await this.props.setDeliveryAvailable(this.props.cart.deliveryAddress, this.props.id)
        }

        var hasStreet = await this.checkStreetInAddress()

        if (submit) {
            if (!this.state.fieldOpen) {
                this.setState({fieldOpen: true})
                return;
            }
        }

        if (!hasStreet || this.props.cart.deliveryAddress == null || !this.state.showedDeliveryMessage) {
            this.setState({ showedDeliveryMessage: true })
            if (!hasStreet) {
                this.setState({ hasStreetError: true })
            }
            setTimeout(() => {
                this.setState({ showedDeliveryMessage: false })
                this.setState({ hasStreetError: false })
            }, 5000);
        }
    }

    canProceed() {
        if (this.props.cart.orderType !== 'pick-up') {
            if (this.props.cart.deliveryTimes.size <= 0) {
                return false
            }
        } else {
            if (this.props.cart.pickUpTimes.size <= 0) {
                return false
            }
        }

        return true
    }

    /**
     * Focuses the cursor on note input on the cart.
     */
    focusNote() {
        this.noteInput.current.focus();
    }

    render() {
        return (
            <div className="sticky" style={{top: 136}}>
                <Modal
                    size='xl'
                    show={this.props.cart.isMinOrderErrVisible}
                    onHide={() => this.props.setMinOrderErrModalVisibility(false)} //TODO create new action create for setting this modals visibility
                    dialogClassName={'cart-modal'}>
                    <FontAwesomeIcon onClick={() => this.props.setMinOrderErrModalVisibility(false)} icon={faWindowClose} size="md" color="grey" style={{ position: 'absolute', left: 3, top: 3, zIndex: 1 }} />
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <Translation ns="restaurant">
                                        {t => <p>
                                            {t('min.order.error')} {this.props.restaurant.minDeliveryOrder} €
                                        </p>}
                                    </Translation>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                </Modal>
                <div style={{ backgroundColor: '#557627' }}>
                    <div style={{ textAlign: 'center', color: 'white', paddingTop: '25px', paddingBottom: '5px' }}>
                        <Image src={ShoppingCartIcon} style={{ marginBottom: '10px', height: '20px' }} />
                        <Translation ns="cart">
                            {t => <p style={{ fontSize: 16, display: 'inline', marginLeft: '5px', fontFamily: 'MontserratMedium' }}>{t('yourCart')}</p>}
                        </Translation>
                    </div>
                    <div style={{ textAlign: 'center', color: 'white', paddingBottom: '15px' }}>
                        <Translation ns="cart">
                            {t => <p style={{ fontSize: 14, display: 'inline', marginLeft: '5px',  fontFamily: 'MontserratMedium' }}>{t('orderType')}</p>}
                        </Translation>
                    </div>
                    {this.props.cart.orderType !== 'pick-up' ?
                        this.props.cart.deliveryTimes.size > 0 ?
                            <div style={{ textAlign: 'center' }}>
                                <Translation ns="cart">
                                    {t => <Button size="sm" className={'delivery-pick-up-btn'} style={{borderRadius: "0px", width: '40%', height: '70%', color: '#92C143', backgroundColor: 'white', borderColor: 'white', fontFamily: 'MontserratMedium', marginRight: '20px', marginBottom: '10px', paddingTop: '0px', paddingBottom: '0px' }} onClick={() => this.props.selectOrderType('delivery')}>{t('delivery')}</Button>}
                                </Translation>
                                <Translation ns="cart">
                                    {t => <Button size="sm" className={'delivery-pick-up-btn'} style={{borderRadius: "0px", width: '40%', height: '70%', backgroundColor: '#92C143', color: 'white', borderColor: 'white', fontFamily: 'MontserratMedium', marginBottom: '10px', paddingTop: '0px', paddingBottom: '0px' }} onClick={() => this.props.selectOrderType('pick-up')}>{t('pickup')}</Button>}
                                </Translation>
                                {this.props.cart.deliveryOpen ?
                                    <Translation ns="cart">
                                        {t => <div className={'pick-up-closed'} > {t('deliveryNotOpened')} </div>}
                                    </Translation>
                                    : null
                                }
                            </div>
                            :
                            <div style={{ textAlign: 'center' }}>
                                <Translation ns="cart">
                                    {t => <Button size="sm" className={'delivery-pick-up-btn'} style={{ fontWeight: 'bold', borderRadius: "0px", width: '40%', height: '70%', color: '#92C143', backgroundColor: 'white', borderColor: 'white', fontFamily: 'MontserratMedium', marginRight: '20px', marginBottom: '10px', paddingTop: '0px', paddingBottom: '0px' }} onClick={() => this.props.selectOrderType('delivery')}>{t('delivery')}</Button>}
                                </Translation>
                                <Translation ns="cart">
                                    {t => <Button size="sm" className={'delivery-pick-up-btn'} style={{ fontWeight: 'bold', borderRadius: "0px", width: '40%', height: '70%', backgroundColor: '#92C143', color: 'white', borderColor: 'white', fontFamily: 'MontserratMedium', marginBottom: '10px', paddingTop: '0px', paddingBottom: '0px' }} onClick={() => this.props.selectOrderType('pick-up')}>{t('pickup')}</Button>}
                                </Translation>
                                <Translation ns="cart">
                                    {t => <div className={'pick-up-closed'} >{t('deliveryClosed')}</div>}
                                </Translation>
                            </div>
                        :
                        this.props.cart.pickUpTimes.size > 0 ?
                            <div style={{ textAlign: 'center' }}>
                                <Translation ns="cart">
                                    {t => <Button size="sm" className={'delivery-pick-up-btn'} style={{ fontWeight: 'bold', borderRadius: "0px", width: '40%', height: '70%', backgroundColor: '#92C143', color: 'white', borderColor: 'white', fontFamily: 'MontserratMedium', marginRight: '20px', marginBottom: '10px', paddingTop: '0px', paddingBottom: '0px' }} onClick={() => this.props.selectOrderType('delivery')}>{t('delivery')}</Button>}
                                </Translation>
                                <Translation ns="cart">
                                    {t => <Button size="sm" className={'delivery-pick-up-btn'} style={{ fontWeight: 'bold', borderRadius: "0px", width: '40%', height: '70%', backgroundColor: 'white', borderColor: 'white', fontFamily: 'MontserratMedium', color: '#424142', marginBottom: '10px', paddingTop: '0px', paddingBottom: '0px' }} onClick={() => this.props.selectOrderType('pick-up')}>{t('pickup')}</Button>}
                                </Translation>
                            </div> :
                            <div style={{ textAlign: 'center' }}>
                                <Translation ns="cart">
                                    {t => <Button size="sm" className={'delivery-pick-up-btn'} style={{ fontWeight: 'bold', borderRadius: "0px", width: '40%', height: '70%', backgroundColor: '#92C143', color: 'white', borderColor: 'white', fontFamily: 'MontserratMedium', marginRight: '20px', marginBottom: '10px', paddingTop: '0px', paddingBottom: '0px' }} onClick={() => this.props.selectOrderType('delivery')}>{t('delivery')}</Button>}
                                </Translation>
                                <Translation ns="cart">
                                    {t => <Button size="sm" className={'delivery-pick-up-btn'} style={{ fontWeight: 'bold', borderRadius: "0px", width: '40%', height: '70%', backgroundColor: 'white', borderColor: 'white', fontFamily: 'MontserratMedium', color: '#424142', marginBottom: '10px', paddingTop: '0px', paddingBottom: '0px' }} onClick={() => this.props.selectOrderType('pick-up')}>{t('pickup')}</Button>}
                                </Translation>
                                <Translation ns="cart">
                                    {t => <div className={'pick-up-closed'} >{t('pickupClosed')}</div>}
                                </Translation>

                            </div>
                    }
                </div>
                { this.props.cart.orderType !== 'pick-up' ?
                    <div style={{ padding: '20px', backgroundColor: 'white', border: '3px solid #557627', display: 'flex', position: 'relative', marginBottom: '-3px'}}>
                        <Translation ns="cart">
                            {t => <div style={{ fontFamily: 'MontserratMedium', textAlign: 'left', color: '#4f6d37', fontSize: 15, width: '50%', paddingTop: '5px', display: 'flex', alignItems: 'center' }}>{t('address')}</div>}
                        </Translation>
                        <div className={this.state.fieldOpen ? 'address-search-opened' : 'address-search-closed'}>
                            <Translation ns="cart">
                                {t => this.state.fieldOpen ?
                                <div className={this.state.fieldOpen ? 'autocomplete-field-opened' : 'autocomplete-field-closed'} onClick={() => this.setState({fieldOpen: true})}>
                                    <SearchLocationInput searchFunction={() => this.checkDeliveryAvailability()} inputStyle={{ fontFamily: 'MontserratMedium', height: '28px', fontWeight: 'bold', outline: 'none', borderRadius: '0px', fontSize: '15px', width: '100%', border: 0, padding: 5, paddingLeft: 15, paddingRight: 15, backgroundColor: '#92C143', color: 'white' }} setStateCallback={this.props.setDeliveryAddress} defaultValue={this.props.cart.deliveryAddress} value={this.props.cart.deliveryAddress}  placeholder={t('addressPlaceholder')}/>
                                </div> :
                                <div className={this.state.fieldOpen ? 'autocomplete-field-opened' : 'autocomplete-field-closed'} onClick={() => this.setState({fieldOpen: true})}>
                                    <div className="search-location-input_disabled" id="autocompleteInput">
                                        <input
                                            style={{ fontFamily: 'MontserratMedium', height: '28px', fontWeight: 'bold', outline: 'none', borderRadius: 0, borderTopLeftRadius: 3, borderBottomLeftRadius: 3, fontSize: '15px', width: '100%', border: 0, padding: 5, paddingLeft: 15, paddingRight: 15, backgroundColor: '#92C143', color: 'white' }}
                                            placeholder={t('addressPlaceholder')}
                                            value={this.props.cart.deliveryAddress}
                                        />
                                    </div>
                                </div>
                                }
                            </Translation>
                            <div className={this.state.fieldOpen ? 'autocomplete-button-field-opened' : 'autocomplete-button-field-closed'} style={{fontSize: 15, backgroundColor: '#92C143', height: '28px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 5, color: 'white',  borderTopRightRadius: 3, borderBottomRightRadius: 3}}>
                                <FontAwesomeIcon style={{cursor:'pointer'}} icon={faSearch} onClick={() => this.checkDeliveryAvailability()}/>
                                {this.state.fieldOpen ? <FontAwesomeIcon style={{marginLeft: '5px', cursor:'pointer'}} icon={faTimes} onClick={() => this.setState({fieldOpen: false})}/> : null}
                            </div>
                        </div>
                </div>
                : null}
                {this.props.cart.orderType === 'pick-up' ?
                    <div style={{ padding: '20px', backgroundColor: 'white', border: '3px solid #557627', margin: '1px 0', display: 'flex', textAlign: 'right', alignItems: 'center' }}>
                        <Translation ns="cart">
                            {t => <div style={{ fontFamily: 'MontserratMedium', textAlign: 'left', color: '#4f6d37', fontSize: 15, width: '50%', paddingTop: '5px' }}>{t('pickupTime')}</div>}
                        </Translation>
                        <div style={{ width: '50%' }}>
                            <div className={'pick-up-time-container'}>
                                {this.props.cart.pickUpTimes === undefined ? null :
                                    this.props.cart.pickUpTimes.size > 0 ?
                                        <select className={'pick-up-time-select'} value={this.props.cart.selHour} onChange={(e) => this.setHours(e)}>
                                            {[...this.props.cart.pickUpTimes.keys()].map(hour => {
                                                return <option style={{ fontWeight: 'bold' }}>{hour}</option>
                                            })}
                                        </select> : <select className={'pick-up-time-select'} disabled><option>-</option></select>
                                }
                            </div>
                            <div className={'pick-up-time-container'}>
                                {this.props.cart.pickUpTimes === undefined ? null :
                                    this.props.cart.pickUpTimes.has(this.props.cart.selHour) ?
                                        <select className={'pick-up-time-select'} value={this.props.cart.selMinute} onChange={(e) => this.setMinutes(e)}>
                                            {this.props.cart.pickUpTimes.get(this.props.cart.selHour).map(minute => {
                                                return <option style={{ fontWeight: 'bold' }}>{minute}</option>
                                            })}
                                        </select> : <select className={'pick-up-time-select'} disabled><option>-</option></select>
                                }
                            </div>
                        </div>
                    </div> :
                    <div style={{ padding: '20px', backgroundColor: 'white', border: '3px solid #557627', margin: '1px 0', display: 'flex', textAlign: 'right', alignItems: 'center' }}>
                        <Translation ns="cart">
                            {t => <div style={{ fontFamily: 'MontserratMedium', textAlign: 'left', color: '#4f6d37', fontSize: 15, width: '50%', paddingTop: '5px' }}>{t('deliveryTime')}</div>}
                        </Translation>
                        <div style={{ width: '50%' }}>
                            <div className={'pick-up-time-container'}>
                                {this.props.cart.deliveryTimes === undefined ? null :
                                    this.props.cart.deliveryTimes.size > 0 ?
                                        <select className={'pick-up-time-select'} value={this.props.cart.selDeliveryHour} onChange={(e) => this.setDeliveryHours(e)}>
                                            {[...this.props.cart.deliveryTimes.keys()].map(hour => {
                                                return <option style={{ fontWeight: 'bold' }}>{hour}</option>
                                            })}
                                        </select> : <select className={'pick-up-time-select'} disabled><option>-</option></select>
                                }
                            </div>
                            <div className={'pick-up-time-container'}>
                                {this.props.cart.deliveryTimes === undefined ? null :
                                    this.props.cart.deliveryTimes.has(this.props.cart.selDeliveryHour) ?
                                        <select className={'pick-up-time-select'} value={this.props.cart.selDeliveryMinute} onChange={(e) => this.setDeliveryMinutes(e)}>
                                            {this.props.cart.deliveryTimes.get(this.props.cart.selDeliveryHour).map(minute => {
                                                return <option style={{ fontWeight: 'bold' }}>{minute}</option>
                                            })}
                                        </select> : <select className={'pick-up-time-select'} disabled><option>-</option></select>
                                }
                            </div>
                        </div>
                    </div>
                }
                { this.state.showedDeliveryMessage && !this.state.hasStreetError ?
                    <div style={{ backgroundColor: '#557627'}}>
                        <Translation ns="cart">
                            {t => <div style={{fontFamily: 'MontserratMedium'}} className={'delivery-availability'}>&#9679; {this.props.cart.deliveryAddress == null || this.props.cart.deliveryAddress.trim() === '' ? t('addressNotification1') : this.props.cart.deliveryAvailable ? t('addressNotification2') : t('addressNotification3')}</div>}
                        </Translation>

                    </div>
                : null}
                { this.state.showedDeliveryMessage && this.state.hasStreetError ?
                    <div style={{ backgroundColor: '#557627'}}>
                        <Translation ns="cart">
                            {t => <div style={{fontFamily: 'MontserratMedium'}} className={'delivery-availability-no-street'}>&#9679; {t('addressNotification4')}</div>}
                        </Translation>

                    </div>
                : null}
                {this.props.cart.cartContent.map(content => {
                    return (
                        <div style={{ borderTop: '2 solid white', backgroundColor: '#557627', minHeight: 40, padding: 15, margin: "2px 1px 1px 0" }}>
                            <div style={{ display: 'inline-block', width: '82%', verticalAlign: 'middle', paddingLeft: 2, fontSize: 12 }}>
                                <div style={{ width: '50%', display: 'inline-block', marginRight: '2%', color: 'white', fontFamily: 'Montserrat-Bold' }}>
                                    {content.dish.dish.dishName}
                                </div>
                                <div style={{ width: '10%', display: 'inline-block', marginRight: '2%', color: 'white', fontFamily: 'Montserrat-Bold' }}>
                                    {"x" + content.modal.quantity}
                                </div>
                                <div style={{ width: '30%', display: 'inline-block', textAlign: 'center', color: 'white', fontFamily: 'Montserrat-Bold' }}>
                                    {parseFloat(content.modal.totalPrice).toFixed(2) + " €"}
                                </div>
                            </div>
                            <div onClick={() => this.openCartModal(content.dish.dish.id, content.modal.quantity, content.modal.checkedItems, this.props.cart.cartContent.indexOf(content))} style={{ display: 'inline-block', width: '5%', verticalAlign: 'middle', textAlign: 'center', marginBottom: '5px', marginRight: '10px', cursor:'pointer' }}>
                                <Translation ns="cart">
                                    {t => (
                                        <EditRemoveDish isEdit={true} text={t('edit.dish.tooltip')} />
                                    )}
                                </Translation>
                            </div>
                            <div className={'cart-dish-remove'}
                                onClick={() => confirmAlert({
                                    customUI: ({ onClose }) => {
                                        return (
                                            <Translation ns="cart">
                                                {t => (
                                                    <div>
                                                        <p style={{fontFamily: 'MontserratMedium', color: '#547200'}}>{t('remove.dish.dialog.msg').toUpperCase()}</p>
                                                        <div style={{float: 'right'}}>
                                                            <button
                                                                style={{fontFamily: 'Montserrat-Bold', backgroundColor: '#547200', color: '#fff', marginRight: 10, border: 0, padding: '5px 20px', outline: 0}}
                                                                onClick={onClose}>
                                                                {t('remove.dish.dialog.cancel').toUpperCase()}
                                                            </button>
                                                            <button
                                                                style={{fontFamily: 'Montserrat-Bold', backgroundColor: '#EFBE39', color: 'rgb(65, 64, 66)', border: 0, padding: '5px 20px', outline: 0}}
                                                                onClick={() => {
                                                                    this.removeFromCart(this.props.cart.cartContent.indexOf(content));
                                                                    onClose();
                                                                }}>
                                                                {t('remove.dish.dialog.delete').toUpperCase()}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Translation>
                                        );
                                    }})
                                }
                                style={{ display: 'inline-block', width: '5%', verticalAlign: 'middle', textAlign: 'center', marginBottom: '5px' , cursor:'pointer'}}>
                                <Translation ns="cart">
                                    {t => (
                                        <EditRemoveDish isEdit={false} text={t('remove.dish.tooltip')} />
                                    )}
                                </Translation>
                            </div>
                        </div>
                    )
                })}
                <div style={{ padding: 20, backgroundColor: '#92C143' }}>
                    <div style={{ borderColor: '#ffffff', borderWidth: 1, borderStyle: 'solid', backgroundColor: '#ffffff' }}>
                        <div>
                            <Translation ns="restaurant">
                                {t => <p style={{
                                    fontFamily: 'MontserratRegular',
                                    color: '#b9d186',
                                    width: '100%',
                                    fontSize: 14,
                                    paddingTop: 5,
                                    paddingLeft: 5,
                                    fontWeight: 'bold',
                                    cursor: 'text',
                                    margin: 0,
                                }}
                                onClick={this.focusNote}>
                                    {t('cart.note')}
                                </p>}
                            </Translation>
                        </div>
                        <div style={{ padding: '0px 5px' }}>
                            <textarea
                                maxLength={300}
                                ref={this.noteInput}
                                style={{
                                    color: '#b9d186',
                                    border: 0,
                                    width: '100%',
                                    outline: 0,
                                    fontSize: 14,
                                }}
                                onChange={(ev) => this.props.setCartNote(ev.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ border: 2, borderColor: 'white', backgroundColor: '#92C143', minHeight: 40, padding: 15, margin: "2px 1px 1px 0" }}>
                    <Translation ns="cart">
                        {t => <div style={{ fontFamily: 'MontserratRegular', fontSize: 11, marginBottom: 15, color: '#003EA3' }}>
                            {t('discount')}
                        </div>
                        }
                    </Translation>
                    <div style={{fontFamily: 'MontserratRegular', backgroundColor: 'white', color: '#557627', fontSize: 14, margin: 5, fontWeight: '500', padding: '2px 5px' }}>
                        <Translation ns="cart">
                            {t => <span style={{ fontWeight: 'bold' }}>{t('subtotal')}</span>}
                        </Translation>
                        <span style={{ display: 'inline', float: 'right' }}>{this.calculateSum(this.props.cart.cartContent).toFixed(2) + " €"}</span>
                    </div>
                    {this.props.cart.orderType !== 'pick-up' ?
                        <div style={{ fontFamily: 'MontserratRegular', backgroundColor: 'white', color: '#557627', fontSize: 14, margin: 5, fontWeight: '500', padding: '2px 5px' }}>
                            <Translation ns="cart">
                                {t => <span style={{ fontWeight: 'bold' }}>{t('deliveryCost')}</span>}
                            </Translation>
                            <span style={{ display: 'inline', float: 'right' }}>{this.props.restaurant.deliveryCost.toFixed(2) + " €"}</span>
                        </div> : null}
                    <div style={{ fontFamily: 'MontserratMedium', backgroundColor: 'white', color: '#DF3D2F', fontSize: 16, margin: 5, fontWeight: 'bold', padding: '2px 5px' }}>
                        <Translation ns="cart">
                            {t => <span style={{ fontWeight: 'bold' }}>{t('total')}</span>}
                        </Translation>
                        <span style={{ display: 'inline', float: 'right' }}>{this.props.cart.orderType === 'pick-up' ? this.calculateSum(this.props.cart.cartContent).toFixed(2) + " €" : this.calculateSumWithDelivery(this.props.cart.cartContent).toFixed(2) + " €"}</span>
                    </div>
                </div>
                <div>
                    <Translation ns="restaurant">
                        {t => <Button onClick={this.proceedWithOrder} style={{
                            fontFamily: 'MontserratRegular',
                            fontSize: 15,
                            width: '100%',
                            color: '#414042',
                            border: '0px',
                            backgroundColor: '#EFBE39',
                            borderRadius: 0,
                            marginTop: 1,
                            fontWeight: 'bold',
                            cursor: this.props.cart.cartContent.length <= 0 || !this.canProceed() ? 'not-allowed' : 'pointer'
                        }}>
                            {t('proceed.with.order')}
                        </Button>}
                    </Translation>
                </div>
            </div>
        )
    }
}

/**,
 * Maps state to props.
 * @param state the state.
 * @returns {{cart: *, selectedDish: *}}
 */
const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        selectedDish: state.selectedDish,
        goToCheckoutModal: state.goToCheckoutModal,
        restaurantClosedModal: state.restaurantClosedModal,
        restaurants: state.restaurants,
    };
};

export default withRouter(connect(mapStateToProps, {setDeliveryAvailable, setDeliveryAddress, setRestaurantId, setCartNote, setCartRestaurantSlug, setGoToCheckoutModalVisibility, setDeliveryOpened, setDeliveryMinute, setDeliveryHour, selectOrderType, fetchDeliveryTimes, setMinOrderErrModalVisibility, validateMinOrder, setCart, setCartIndex, displayModalCart, hideModalCart, fetchMenus, fetchDish, fetchPickUpTimes, setPickUpHour, setPickUpMinute, selectDish, setQuantity, setChecked, setTotalPrice })(Cart));
